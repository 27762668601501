import { IFile } from 'entities/File/sdk';

export const QUESTION_TYPES = {
  FREETEXT: 'freetext',
  SINGLE_SELECT: 'single_select',
  MULTIPLE_SELECT: 'multiple_select',
  BOOLEAN: 'boolean',
  GEOPOINT: 'geopoint',
  INTEGER: 'integer',
  DECIMAL: 'decimal',
  FILE_UPLOAD: 'file_upload',
};

export const QUESTION_FILE_TYPES = {
  IMAGE: 'image',
  PDF_FILE: 'pdf',
  EXCEL_SPREADSHEET: 'excel',
  WORD_DOCUMENT: 'word',
};

export interface IQuestion {
  uuid?: string;

  id?: number;
  title: string;
  type: string;
  required: boolean;
  hint?: string;

  // Number question specific fields
  range?: {
    lower?: string;
    upper?: string;
  };

  // Select question specific fields
  options?: {
    uuid?: string;
    value: string;
  }[];

  // File upload question specific fields
  file_type?: string;
  template_file?: IFile;
}
