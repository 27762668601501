import React, { useCallback } from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';

import { TASKS_LIST_URL } from 'config/urls';
import { Button, Typography } from 'components';
import { notifySuccess, notifyApiError } from 'utils/notifications';

import { completeTask } from 'entities/Task/sdk';
import TaskContentWrapper from 'entities/Task/components/ContentWrapper';

import { ISurveyQuestion } from 'entities/Survey/sdk';
import { QUESTION_TYPES } from 'entities/Question/sdk';
import {
  QUESTION_TYPE_TO_ANSWER,
  questionHasTextAnswer,
  isNumberQuestion,
  parseNumberQuestionRange,
} from 'entities/Question/utils';

import QuestionDetails from './QuestionDetails';

export interface IFormValues {
  answers: {
    id: number;
    has_change_requests: boolean;

    question: ISurveyQuestion;

    text_answer?: string;
    number_answer?: number;
    boolean_answer?: 'false' | 'true';
    choices_answer?: string[];
    geopoint_answer?: {
      longitude: number;
      latitude: number;
    };
    file_answer?: {
      file: {
        id: number;
        type: string;
      };
      feedback: string;
    };
  }[];
}

const REQUIRED_MESSAGE = 'This is a required question';

const validationSchema = yup.object({
  answers: yup.array().of(
    yup.object({
      text_answer: yup
        .string()
        .when('question', ({ type, required }, schema) => {
          if (questionHasTextAnswer(type) && required) {
            return schema.required(REQUIRED_MESSAGE);
          }
        }),
      boolean_answer: yup
        .string()
        // Radio button values are always stringified
        // so that's why we check for the "false" / "true" strings here instead of a boolean
        .oneOf(['false', 'true'])
        .when('question', ({ type, required }, schema) => {
          if (type === QUESTION_TYPES.BOOLEAN && required) {
            return schema.required(REQUIRED_MESSAGE);
          }
        }),
      number_answer: yup.number().when('question', (question, schema) => {
        const { type, required } = question;

        if (isNumberQuestion(type)) {
          if (required) {
            schema = schema.required(REQUIRED_MESSAGE);
          }

          if (type === QUESTION_TYPES.INTEGER) {
            schema = schema.integer('Please provide an integer');
          }

          const { min, max } = parseNumberQuestionRange(question);

          if (min) {
            schema = schema.min(min, `Value cannot be less than ${min}`);
          }
          if (max) {
            schema = schema.max(max, `Value cannot be more than ${max}`);
          }

          return schema;
        }
      }),
      choices_answer: yup
        .array()
        .of(yup.string())
        .when('question', ({ type, required }, schema) => {
          if (type === QUESTION_TYPES.MULTIPLE_SELECT && required) {
            return schema.min(1, REQUIRED_MESSAGE);
          }
        }),
      geopoint_answer: yup
        .object({
          longitude: yup.number(),
          latitude: yup.number(),
        })
        .when('question', ({ type, required }) => {
          // Note: We cannot use `schema` here as in the other validators,
          // because we want to attach validations on each field in the object
          if (type === QUESTION_TYPES.GEOPOINT && required) {
            return yup.object({
              longitude: yup
                .number()
                .required('Please provide a longitude coordinate'),
              latitude: yup
                .number()
                .required('Please provide a latitude coordinate'),
            });
          }
        }),
      file_answer: yup
        .object({
          file: yup.object({
            id: yup.number(),
            file_type: yup.string(),
          }),
          feedback: yup.string(),
        })
        .when('question', ({ type, required }) => {
          if (type === QUESTION_TYPES.FILE_UPLOAD && required) {
            return yup.object({
              file: yup.object({
                id: yup.number().required(),
                file_type: yup.string().required(),
              }),
              feedback: yup.string(),
            });
          }
        }),
    })
  ),
});

interface ISubmitAnswersForm {
  taskId: number;
  hasChangeRequests: boolean;
  questions: ISurveyQuestion[];
}

const SubmitAnswersForm: React.FC<ISubmitAnswersForm> = ({
  taskId,
  hasChangeRequests,
  questions,
}) => {
  const navigate = useNavigate();

  const handleCompleteTask = useCallback(
    async values => {
      try {
        await completeTask(taskId, values);
        notifySuccess('Task completed');
        navigate(TASKS_LIST_URL);
      } catch (e: any) {
        notifyApiError(e);
      }
    },
    [taskId, navigate]
  );

  const onSubmit = (values: IFormValues) => {
    const answersForApi = _(values.answers)
      .filter(({ question, ...answer }) => {
        // Submit only values that are actually changed.
        const { fieldName, defaultValue } =
          QUESTION_TYPE_TO_ANSWER[question.type];

        let initialAnswer: any = { [fieldName]: defaultValue };
        if (answer.id) {
          // If there is ID find the initial value provided by the BE.
          initialAnswer = _.find(formik.initialValues.answers, {
            id: answer.id,
          });
        }

        const currentValue = _.get(answer, fieldName);
        const initialValue = _.get(initialAnswer, fieldName);

        return !_.isEqual(currentValue, initialValue);
      })
      .map(({ question: { id: questionId }, ...answer }) => {
        if (answer.file_answer) {
          return {
            question: questionId,
            // We store file answer's ID and type, but we need to submit only the ID to the server.
            file_answer: {
              file: answer.file_answer.file.id,
              feedback: answer.file_answer.feedback,
            },
          };
        }

        return {
          question: questionId,
          ..._.omit(answer, 'has_change_requests', 'id'),
        };
      })
      .value();

    handleCompleteTask({ answers: answersForApi });
  };

  const formik = useFormik<IFormValues>({
    initialValues: {
      answers: _.map(questions, (question: ISurveyQuestion) => {
        const answer = _.head(question.answers);

        const { fieldName, defaultValue } =
          QUESTION_TYPE_TO_ANSWER[question.type];

        let answerValue = _.get(answer, fieldName, defaultValue);

        if (
          !_.isEmpty(answerValue) &&
          question.type === QUESTION_TYPES.INTEGER
        ) {
          answerValue = parseInt(answerValue);
        }

        return {
          id: answer?.id,
          has_change_requests: answer?.has_change_requests || false,
          question,
          [fieldName]: answerValue,
        };
      }),
    },
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <Typography variant="h6">Questions:</Typography>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <TaskContentWrapper>
            {_.map(formik.values.answers, (answer, index: number) => (
              <QuestionDetails
                key={index}
                index={index}
                answer={answer}
                // In the case of change requests we want to enable answering only for questions
                // where changes are requested.
                // We disable the input when:
                // - the task has requested changes
                // - the specific answer has no change requests.
                shouldDisableInput={
                  !answer.has_change_requests && hasChangeRequests
                }
              />
            ))}
          </TaskContentWrapper>

          <Button
            type="submit"
            variant="contained"
            disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            sx={{ marginTop: '10px' }}
          >
            Complete
          </Button>
        </form>
      </FormikProvider>
    </>
  );
};

export default SubmitAnswersForm;
