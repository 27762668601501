import React, { useMemo, useState, useEffect, useCallback } from 'react';

import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Typography, Protected, AddFab } from 'components';
import DataGrid, {
  transformToColumns,
  GridRowParams,
  GridColDef,
  GridActionsCellItem,
} from 'components/DataGrid';

import { buildDetailUrl } from 'utils/urls';
import { useDialogWithQueryParam } from 'utils/hooks';
import { EditRoundedIcon, ContentCopyRoundedIcon } from 'icons';
import { WORKFLOW_TEMPLATE_GROUP_DETAIL_URL } from 'config/urls';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import { useWorkflowTemplateGroups } from 'entities/WorkflowTemplateGroup/sdk';

import WorkflowTemplateGroupCreateDialog from 'entities/WorkflowTemplateGroup/components/CreateDialog';
import WorkflowTemplateGroupEditDialog from 'entities/WorkflowTemplateGroup/components/EditDialog';

const EDIT_QUERY_PARAM = 'edit';

const columnsFields: string[] = ['workflowTemplateGroup', 'actions'];

const WorkflowTemplateGroupsList: React.FC = () => {
  const navigate = useNavigate();

  const { workflowTemplateGroups, error, isLoading, refetch } =
    useWorkflowTemplateGroups();

  const {
    isOpen: isCreateDialogOpen,
    open: openCreateDialog,
    close: closeCreateDialog,
  } = useDialogWithQueryParam('create');

  // We use search params here because the workflow template group ID can be preselected from the URL params
  const [searchParams] = useSearchParams();

  const [editWorkflowTemplateGroupId, setEditWorkflowTemplateGroupId] =
    useState<string>(searchParams.get(EDIT_QUERY_PARAM));

  const {
    isOpen: isEditDialogOpen,
    open: openEditDialog,
    close: closeEditDialog,
  } = useDialogWithQueryParam(EDIT_QUERY_PARAM, editWorkflowTemplateGroupId);

  useEffect(() => {
    // Open/close the edit dialog based on the state variable, which can be either preset from the query params or manually set from the table edit handler.
    if (!_.isEmpty(editWorkflowTemplateGroupId)) {
      openEditDialog();
    } else {
      closeEditDialog();
    }
  }, [editWorkflowTemplateGroupId, openEditDialog, closeEditDialog]);

  const [
    duplicateWorkflowTemplateGroupId,
    setDuplicateWorkflowTemplateGroupId,
  ] = useState<string>();

  useEffect(() => {
    if (!_.isEmpty(duplicateWorkflowTemplateGroupId)) {
      openCreateDialog();
    }
  }, [duplicateWorkflowTemplateGroupId, openCreateDialog]);

  const handleCreateClose = useCallback(() => {
    setDuplicateWorkflowTemplateGroupId(null);
    closeCreateDialog();
  }, [closeCreateDialog]);

  const rows = useMemo(
    () =>
      _.map(workflowTemplateGroups, workflowTemplateGroup => {
        const { id, name } = workflowTemplateGroup;

        return {
          id,
          workflowTemplateGroup: name,
        };
      }),
    [workflowTemplateGroups]
  );

  const handleRowClick = useCallback(
    (params: GridRowParams) =>
      navigate(
        buildDetailUrl(WORKFLOW_TEMPLATE_GROUP_DETAIL_URL, params.row.id)
      ),
    [navigate]
  );

  const columns: GridColDef[] = useMemo(
    () =>
      transformToColumns(columnsFields).map((col: GridColDef) => {
        if (col.field === 'actions') {
          return {
            ...col,
            type: 'actions',
            headerName: '',
            maxWidth: 50,
            getActions: (params: GridRowParams) => [
              <GridActionsCellItem
                icon={<EditRoundedIcon />}
                onClick={() =>
                  setEditWorkflowTemplateGroupId(params.id.toString())
                }
                label="Edit"
                showInMenu
              />,
              <GridActionsCellItem
                icon={<ContentCopyRoundedIcon />}
                onClick={() =>
                  setDuplicateWorkflowTemplateGroupId(params.id.toString())
                }
                label="Duplicate"
                showInMenu
              />,
            ],
          };
        }

        return col;
      }),
    []
  );

  if (error) {
    return (
      <Typography variant="subtitle2">
        Failed to fetch workflow template groups.
      </Typography>
    );
  }

  return (
    <>
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows}
        onRowClick={handleRowClick}
      />

      {isEditDialogOpen && !_.isEmpty(editWorkflowTemplateGroupId) && (
        <WorkflowTemplateGroupEditDialog
          workflowTemplateGroupId={editWorkflowTemplateGroupId}
          handleClose={() => setEditWorkflowTemplateGroupId(null)}
          handleSuccess={refetch}
        />
      )}
      <Protected
        entity={PERMISSION_ENTITIES.TEMPLATES}
        permission={ENTITY_PERMISSIONS.ADD}
      >
        <AddFab onClick={openCreateDialog} />
        {isCreateDialogOpen && (
          <WorkflowTemplateGroupCreateDialog
            handleClose={handleCreateClose}
            handleSuccess={refetch}
            duplicateId={duplicateWorkflowTemplateGroupId}
          />
        )}
      </Protected>
    </>
  );
};

export default WorkflowTemplateGroupsList;
