import React from 'react';

import { styled } from '@mui/material/styles';

import { Paper, IconButton } from 'components';
import { AddRoundedIcon } from 'icons';
import { colors } from 'theme/palette';

import { ITaskTemplateListEntity } from 'entities/TaskTemplate/sdk';
import TaskTemplateTitle from 'entities/TaskTemplate/components/TaskTemplateTitle';

const StyledAvailableTaskTemplate = styled(Paper)(() => ({
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: colors.grey0,
}));

interface IAvailableTaskTemplate {
  taskTemplate: ITaskTemplateListEntity;
  handleAdd: () => void;
  modifiable: boolean;
}

const AvailableTaskTemplate: React.FC<IAvailableTaskTemplate> = ({
  taskTemplate,
  handleAdd,
  modifiable,
}) => (
  <StyledAvailableTaskTemplate>
    <TaskTemplateTitle taskTemplate={taskTemplate} />
    {modifiable && (
      <IconButton onClick={handleAdd}>
        <AddRoundedIcon />
      </IconButton>
    )}
  </StyledAvailableTaskTemplate>
);

export default AvailableTaskTemplate;
