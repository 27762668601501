import React from 'react';

import MuiTextField from '@mui/material/TextField';
import type { TextFieldProps } from '@mui/material/TextField';

export type { TextFieldProps };

const TextField: React.FC<TextFieldProps> = ({ children, ...props }) => (
  <MuiTextField size="small" {...props}>
    {children}
  </MuiTextField>
);

export default TextField;
