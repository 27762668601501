import React from 'react';

import { Field } from 'formik';

import { Box, FormTextField } from 'components';

interface ICreateChangeRequestForm {
  fieldName: string;
}

const CreateChangeRequestForm: React.FC<ICreateChangeRequestForm> = ({
  fieldName,
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
    <Field
      name={`${fieldName}.reason`}
      component={FormTextField}
      fullWidth
      label="Reason"
    />

    <Field
      name={`${fieldName}.comment`}
      component={FormTextField}
      fullWidth
      multiline
      minRows={3}
      label="Leave a comment"
    />
  </Box>
);

export default CreateChangeRequestForm;
