import React, { useMemo } from 'react';

import { Field } from 'formik';

import { Box, Divider, Typography, FormTextField } from 'components';

import { IQuestion, QUESTION_TYPES } from 'entities/Question/sdk';

interface INumberQuestionValidations {
  question: IQuestion;
  modifiable: boolean;
  index: number;
}

const NumberQuestionValidations: React.FC<INumberQuestionValidations> = ({
  question,
  modifiable,
  index,
}) => {
  const inputProps = useMemo(() => {
    if (question.type === QUESTION_TYPES.DECIMAL) {
      return {
        step: '0.1',
      };
    }

    return { step: '1' };
  }, [question.type]);

  return (
    <>
      <Divider>
        <Typography variant="subtitle1">Range limitations</Typography>
      </Divider>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '10px',
        }}
      >
        <Field
          name={`questions.${index}.min`}
          component={FormTextField}
          disabled={!modifiable}
          type="number"
          label="Min value (optional)"
          inputProps={inputProps}
        />
        <Field
          name={`questions.${index}.max`}
          component={FormTextField}
          disabled={!modifiable}
          type="number"
          label="Max value (optional)"
          inputProps={inputProps}
        />
      </Box>
    </>
  );
};

export default NumberQuestionValidations;
