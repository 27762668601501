import React from 'react';

import _ from 'lodash';
import { Field } from 'formik';

import { QUESTION_FILE_TYPES } from 'entities/Question/sdk';
import QuestionFileType from 'entities/Question/components/QuestionFileType';

import {
  Divider,
  Typography,
  Notes,
  MenuItem,
  FormTextField,
  FileUploadField,
} from 'components';

interface IFileUploadQuestionValidations {
  index: number;
}

const FileUploadQuestionValidations: React.FC<
  IFileUploadQuestionValidations
> = ({ index }) => {
  return (
    <>
      <div>
        <Divider>
          <Typography variant="subtitle1">Template file</Typography>
        </Divider>
        <Notes value="Upload an optional template file that the task executors should use as a guide when answering the question." />
      </div>
      <Field
        name={`questions.${index}.template_file`}
        component={FileUploadField}
      />

      <Field
        name={`questions.${index}.file_type`}
        component={FormTextField}
        select
        fullWidth
        required
        label="File type"
      >
        {_.map(QUESTION_FILE_TYPES, (value: string, key: string) => (
          <MenuItem key={key} value={value}>
            <QuestionFileType type={value} />
          </MenuItem>
        ))}
      </Field>
    </>
  );
};

export default FileUploadQuestionValidations;
