import _ from 'lodash';
import * as yup from 'yup';

// Extend the 'yup' module to include the custom `unique` array validator
declare module 'yup' {
  interface ArraySchema<T> {
    unique(message?: string): ArraySchema<T>;
  }
}

// Reference: https://github.com/jquense/yup#schematestname-string-message-string--function--any-test-function-schema
yup.addMethod(yup.array, 'unique', function (message) {
  return this.test('unique', message, function (list, { path }) {
    /*
     Custom uniqueness validator for array. Attaches validation errors to the last problematic item in the array.

     For example:

     [
       "test",
       "something",
       "unique",
       "something",  <-- Error will be attached here, since this is the last problematic "something"
       "test"        <-- Error will be attached here, since this is the last problematic "test"
     ]

     */

    // Get a list of duplicated items from the array
    const duplicatedItems = _.filter(list, (value, index, collection) =>
      _.includes(collection, value, index + 1)
    );

    // No duplicates -> valid.
    if (_.isEmpty(duplicatedItems)) {
      return true;
    }

    const errors = _.map(duplicatedItems, duplicatedItem => {
      // Find the last index so we can attach the error only to the last duplicated item (kindly borrowed from Google Forms :P)
      // Note: We do not need to check for invalid index here, since we're sure that these items are duplicated in the original array.
      const index = _.findLastIndex(list, item => item === duplicatedItem);

      // Instead of passing the error to the whole array, attach it to the last "problematic" item.
      return new yup.ValidationError(
        message,
        duplicatedItem,
        `${path}.${index}`
      );
    });

    // Attach errors to the fields accordingly
    return this.createError({ message: () => errors });
  });
});

export default yup;
