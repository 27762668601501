import React from 'react';

import { CheckCircleRoundedIcon, CancelRoundedIcon } from 'icons';

interface IBooleanIcon {
  value: boolean;
}

// Utility component for displaying either a green checkbox or a red X icon
// based on whether the provided value is true or false.
const BooleanIcon: React.FC<IBooleanIcon> = ({ value }) => (
  <>
    {value ? (
      <CheckCircleRoundedIcon color="success" />
    ) : (
      <CancelRoundedIcon color="error" />
    )}
  </>
);

export default BooleanIcon;
