import React, { useMemo, useCallback } from 'react';

import _ from 'lodash';
import { styled } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';

import {
  Paper,
  Divider,
  Typography,
  Box,
  AddButton,
  Protected,
} from 'components';
import DataGrid, {
  transformToColumns,
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
} from 'components/DataGrid';

import { CloseRoundedIcon } from 'icons';
import { COMPONENT_DETAIL_URL } from 'config/urls';

import { buildDetailUrl } from 'utils/urls';
import { notifyError } from 'utils/notifications';
import { useDialogWithQueryParam } from 'utils/hooks';

import { useSignedUser } from 'entities/Auth/sdk';
import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';
import { hasEntityPermission } from 'entities/EntityPermission/utils';
import {
  useWorkflowOfferedComponents,
  removeWorkflowComponent,
} from 'entities/Workflow/sdk';
import WorkflowAddComponentsDialog from 'entities/Workflow/components/AddComponentsDialog';

const StyledWrapper = styled(Paper)(() => ({
  padding: '15px',
  width: '100%',
}));

const columnsFields: string[] = [
  'model',
  'category',
  'manufacturer',
  'latestPrice',
];

const WorkflowComponents: React.FC = () => {
  const navigate = useNavigate();
  const { id: workflowId } = useParams();

  const { user } = useSignedUser();

  const { offeredComponents, isLoading, error, refetch } =
    useWorkflowOfferedComponents(_.toNumber(workflowId));

  const {
    isOpen: isAddComponentsDialogOpen,
    open: openAddComponentsDialog,
    close: closeAddComponentsDialog,
  } = useDialogWithQueryParam('add-components');

  const handleRemoveComponent = useCallback(
    async (componentId: string | number) => {
      await removeWorkflowComponent(
        _.toNumber(workflowId),
        _.toNumber(componentId)
      );
      notifyError('Component removed from workflow.');
      refetch();
    },
    [workflowId, refetch]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      ...transformToColumns(columnsFields),
      {
        field: 'actions',
        type: 'actions',
        hide: !hasEntityPermission(
          user,
          PERMISSION_ENTITIES.WORKFLOWS,
          ENTITY_PERMISSIONS.CHANGE
        ),
        getActions: (params: GridRowParams) => [
          <GridActionsCellItem
            icon={<CloseRoundedIcon />}
            onClick={() => handleRemoveComponent(params.id)}
            label="Delete"
          />,
        ],
      },
    ],
    [user, handleRemoveComponent]
  );

  const rows = useMemo(
    () =>
      _.map(offeredComponents, component => {
        const { id, model, category_name, manufacturer_name, latest_price } =
          component;

        const latestPrice = latest_price ? `$${latest_price.amount}` : 'N/A';

        return {
          id,
          model,
          category: category_name,
          manufacturer: manufacturer_name,
          latestPrice,
        };
      }),
    [offeredComponents]
  );

  const handleRowClick = useCallback(
    (params: GridRowParams) =>
      navigate(buildDetailUrl(COMPONENT_DETAIL_URL, params.id)),
    [navigate]
  );

  if (error) {
    return (
      <Typography variant="subtitle2">Failed to load components.</Typography>
    );
  }

  return (
    <StyledWrapper>
      <Divider>
        <Typography variant="h6">Components</Typography>
      </Divider>

      <DataGrid
        loading={isLoading}
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
      />

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <AddButton variant="outlined" onClick={openAddComponentsDialog}>
          Add components
        </AddButton>
        <Protected
          entity={PERMISSION_ENTITIES.WORKFLOWS}
          permission={ENTITY_PERMISSIONS.CHANGE}
        >
          {isAddComponentsDialogOpen && (
            <WorkflowAddComponentsDialog
              workflowId={workflowId}
              handleSuccess={refetch}
              handleClose={closeAddComponentsDialog}
            />
          )}
        </Protected>
      </Box>
    </StyledWrapper>
  );
};

export default WorkflowComponents;
