import { useFetch, post } from 'utils/sdk';
import { IRoleTemplate } from 'entities/WorkflowRole/sdk';

export const TASK_TEMPLATE_TYPES = {
  SURVEY: 'survey',
  ORDER: 'order',
};

export interface ITaskTemplateListEntity {
  id: number;
  name: string;
  type: string;
  executor_role: IRoleTemplate;
  reviewer_role: IRoleTemplate;
  notes: string;
  survey_template?: {
    id: number;
    name: string;
  };
}

export const useTaskTemplates = () => {
  const { data, error, isLoading, refetch, mutate } = useFetch<
    ITaskTemplateListEntity[]
  >('workflows/templates/tasks/');

  return { taskTemplates: data || [], error, isLoading, refetch, mutate };
};

export interface ITaskTemplateDetailEntity {
  id: number;
  name: string;
  type: string;
  executor_role: IRoleTemplate;
  reviewer_role: IRoleTemplate;
  notes: string;
  survey_template?: {
    id: number;
    name: string;
  };
  can_update: boolean;
}

export const useTaskTemplate = (id: string | number) => {
  const { data, error, isLoading, refetch } =
    useFetch<ITaskTemplateDetailEntity>(
      id ? `workflows/templates/tasks/${id}/` : null
    );

  return { taskTemplate: data, error, isLoading, refetch };
};

export interface ITaskTemplateCreatePayload {
  name: string;
  type: string;
  executor_role: number;
  reviewer_role: number;
  notes?: string;
  survey_template?: number;
}

export const createTaskTemplate = <T>(data: ITaskTemplateCreatePayload) =>
  post<T>('workflows/templates/tasks/create/', data);

export interface ITaskTemplateEditPayload {
  name?: string;
  type?: string;
  executor_role?: number;
  reviewer_role?: number;
  notes?: string;
  survey_template?: number;
}

export const updateTaskTemplate = <T>(
  id: string | number,
  data: ITaskTemplateEditPayload
) => post<T>(`workflows/templates/tasks/${id}/update/`, data);
