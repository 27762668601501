import React from 'react';

import { useParams } from 'react-router-dom';

import { Box, Typography, Protected, EditFab, Breadcrumbs } from 'components';

import { COMPONENTS_LIST_URL } from 'config/urls';
import { RESOURCES } from 'utils/constants';
import { useDialogWithQueryParam } from 'utils/hooks';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import { useComponentDetail } from 'entities/Component/sdk';
import ComponentEditDialog from 'entities/Component/components/EditDialog';
import PriceHistory from 'entities/Component/components/PriceHistory';

import GeneralInfo from './components/GeneralInfo';

const ComponentDetail: React.FC = () => {
  const { id: componentId } = useParams();

  const { component, isLoading, error, refetch } =
    useComponentDetail(componentId);

  const {
    isOpen: isEditDialogOpen,
    open: openEditDialog,
    close: closeEditDialog,
  } = useDialogWithQueryParam('edit');

  if (isLoading) {
    return <Typography variant="subtitle2">Loading component...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="subtitle2">Failed to fetch component.</Typography>
    );
  }

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          { name: RESOURCES.COMPONENTS, url: COMPONENTS_LIST_URL },
          { name: `${component.model} (${component.manufacturer.name})` },
        ]}
      />

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '10px',
          padding: '15px',
        }}
      >
        <GeneralInfo component={component} />
        <PriceHistory component={component} />
      </Box>

      <Protected
        entity={PERMISSION_ENTITIES.COMPONENTS}
        permission={ENTITY_PERMISSIONS.CHANGE}
      >
        <EditFab onClick={openEditDialog} />

        {isEditDialogOpen && (
          <ComponentEditDialog
            componentId={componentId}
            handleSuccess={refetch}
            handleClose={closeEditDialog}
          />
        )}
      </Protected>
    </>
  );
};

export default ComponentDetail;
