import React from 'react';

import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { colors } from 'theme/palette';
import {
  Typography,
  Divider,
  Paper,
  Box,
  Protected,
  EditFab,
  Breadcrumbs,
} from 'components';
import { RESOURCES } from 'utils/constants';
import { buildDetailUrl } from 'utils/urls';
import {
  SURVEY_TEMPLATES_LIST_URL,
  SURVEY_TEMPLATE_EDIT_URL,
} from 'config/urls';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';
import { useSurveyTemplate } from 'entities/SurveyTemplate/sdk';
import QuestionHeader from 'entities/Question/components/Header';

import QuestionDetails from './components/QuestionDetails';

const StyledQuestionsContainer = styled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 0',
  gap: '20px',
  backgroundColor: colors.grey0,
}));

const StyledQuestion = styled(Paper)(() => ({
  width: '50%',
  padding: '15px',
}));

const SurveyTemplateDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id: surveyTemplateId } = useParams();

  const { surveyTemplate, isLoading, error } =
    useSurveyTemplate(surveyTemplateId);

  if (isLoading) {
    return (
      <Typography variant="subtitle2">Loading survey template...</Typography>
    );
  }

  if (error) {
    return (
      <Typography variant="subtitle2">
        Failed to fetch survey template.
      </Typography>
    );
  }

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          { name: RESOURCES.SURVEY_TEMPLATES, url: SURVEY_TEMPLATES_LIST_URL },
          { name: surveyTemplate.name },
        ]}
      />

      <Divider>
        <Typography variant="h6">Questions</Typography>
      </Divider>

      <StyledQuestionsContainer>
        {_.map(surveyTemplate.questions, (question, index) => (
          <StyledQuestion key={question.id} variant="outlined">
            <QuestionHeader question={question} index={index} />

            <Box mt={1}>
              <QuestionDetails question={question} />
            </Box>
          </StyledQuestion>
        ))}
      </StyledQuestionsContainer>

      <Protected
        entity={PERMISSION_ENTITIES.TEMPLATES}
        permission={ENTITY_PERMISSIONS.CHANGE}
      >
        <EditFab
          onClick={() =>
            navigate(buildDetailUrl(SURVEY_TEMPLATE_EDIT_URL, surveyTemplateId))
          }
        />
      </Protected>
    </>
  );
};

export default SurveyTemplateDetail;
