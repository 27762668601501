import React from 'react';

import MuiDialogTitle from '@mui/material/DialogTitle';
import type { DialogTitleProps } from '@mui/material/DialogTitle';

import { IconButton } from 'components';
import { CloseRoundedIcon } from 'icons';

export interface IDialogTitle extends DialogTitleProps {
  onClose: () => void;
}

const DialogTitle: React.FC<IDialogTitle> = ({
  children,
  onClose,
  ...props
}) => (
  <MuiDialogTitle
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
    {...props}
  >
    {children}
    {onClose && (
      <IconButton aria-label="close" onClick={onClose}>
        <CloseRoundedIcon />
      </IconButton>
    )}
  </MuiDialogTitle>
);

export default DialogTitle;
