import React, { useState, useMemo, useCallback } from 'react';

import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';

import { TASKS_LIST_URL } from 'config/urls';
import {
  notifySuccess,
  notifyError,
  notifyApiError,
} from 'utils/notifications';

import { Typography, Box, Button, Divider } from 'components';

import OrderDetails from 'entities/Order/components/Details';

import {
  useChangeRequestsForOrder,
  requestChangesForOrder,
  IRequestChangesForOrderPayload,
} from 'entities/ChangeRequest/sdk';
import ChangeRequestDetails from 'entities/ChangeRequest/components/Details';
import CreateChangeRequestForm from 'entities/ChangeRequest/components/CreateForm';

import { ITaskDetailEntity, approveTask, rejectTask } from 'entities/Task/sdk';
import TaskContentWrapper from 'entities/Task/components/ContentWrapper';
import TaskItemWrapper from 'entities/Task/components/ItemWrapper';

interface IFormValues {
  changeRequest: {
    reason: string;
    comment: string;
  };
}

interface IReviewOrderForm {
  task: ITaskDetailEntity;
}

const ReviewOrderForm: React.FC<IReviewOrderForm> = ({ task }) => {
  const navigate = useNavigate();

  const orderId = useMemo(() => _.get(task, 'order_id'), [task]);

  const { changeRequests, refetch: refetchChangeRequests } =
    useChangeRequestsForOrder(orderId);

  const [isApprovingTask, setIsApprovingTask] = useState<boolean>(false);
  const [isRejectingTask, setIsRejectingTask] = useState<boolean>(false);

  const handleApproveTask = useCallback(async () => {
    setIsApprovingTask(true);

    try {
      await approveTask(task.id);
      notifySuccess('Task approved');
      setIsApprovingTask(false);
      navigate(TASKS_LIST_URL);
    } catch (e: any) {
      setIsApprovingTask(false);
      notifyApiError(e);
    }
  }, [task.id, navigate]);

  const handleRejectTask = useCallback(async () => {
    setIsRejectingTask(true);

    try {
      await rejectTask(task.id);
      notifyError('Task rejected');
      setIsRejectingTask(false);
      navigate(TASKS_LIST_URL);
    } catch (e: any) {
      setIsRejectingTask(false);
      notifyApiError(e);
    }
  }, [task.id, navigate]);

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      try {
        const {
          changeRequest: { reason, comment },
        } = values;

        let valuesToSubmit: IRequestChangesForOrderPayload = {
          reason,
        };

        if (!_.isEmpty(comment)) {
          valuesToSubmit = {
            ...valuesToSubmit,
            comments: [{ text: comment }],
          };
        }

        await requestChangesForOrder(orderId, valuesToSubmit);

        notifySuccess('Changes requested successfully');
        navigate(TASKS_LIST_URL);
      } catch (e: any) {
        notifyApiError(e);
      }
    },
    [orderId, navigate]
  );

  const formik = useFormik<IFormValues>({
    initialValues: {
      changeRequest: {
        reason: '',
        comment: '',
      },
    },
    onSubmit,
  });

  const isSubmitting = useMemo(
    () => isApprovingTask || isRejectingTask || formik.isSubmitting,
    [isApprovingTask, isRejectingTask, formik.isSubmitting]
  );

  const canRequestChanges = useMemo(
    () => !_.isEmpty(formik.values.changeRequest.reason),
    [formik.values.changeRequest.reason]
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <TaskContentWrapper>
        <TaskItemWrapper>
          <OrderDetails orderId={orderId} />

          <Box mt={1}>
            <Divider>
              <Typography variant="subtitle1">Change requests</Typography>
            </Divider>
          </Box>

          {_.map(changeRequests, (request, index: number) => (
            <ChangeRequestDetails
              key={index}
              request={request}
              refetchChangeRequests={refetchChangeRequests}
            />
          ))}

          {!_.isEmpty(changeRequests) && <Divider sx={{ margin: '10px 0' }} />}

          <FormikProvider value={formik}>
            <CreateChangeRequestForm fieldName="changeRequest" />
          </FormikProvider>
        </TaskItemWrapper>
      </TaskContentWrapper>

      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          marginTop: '10px',
        }}
      >
        <Button
          color="error"
          variant="contained"
          onClick={handleRejectTask}
          disabled={isSubmitting}
        >
          Reject
        </Button>

        <Button
          type="submit"
          color="warning"
          variant="contained"
          disabled={isSubmitting || !canRequestChanges}
        >
          Request changes
        </Button>

        <Button
          color="success"
          variant="contained"
          onClick={handleApproveTask}
          disabled={isSubmitting}
        >
          Approve
        </Button>
      </Box>
    </form>
  );
};

export default ReviewOrderForm;
