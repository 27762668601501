import React, { useMemo } from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { useFormik, Field, FormikProvider, FormikValues } from 'formik';

import { styled } from '@mui/material/styles';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormTextField,
  MenuItem,
  Button,
  Typography,
  Divider,
} from 'components';

import { notifySuccess, notifyApiError } from 'utils/notifications';

import {
  TASK_TEMPLATE_TYPES,
  ITaskTemplateCreatePayload,
  ITaskTemplateListEntity,
  createTaskTemplate,
  useTaskTemplate,
} from 'entities/TaskTemplate/sdk';
import {
  IRoleTemplate,
  useAvailableRoleTemplates,
} from 'entities/WorkflowRole/sdk';
import {
  ISurveyTemplateListEntity,
  useSurveyTemplates,
} from 'entities/SurveyTemplate/sdk';

const FORM_ID = 'taskTemplateForm';

const isSurveyTask = (type: string) => type === TASK_TEMPLATE_TYPES.SURVEY;

const StyledForm = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
}));

interface IFormValues extends FormikValues {
  name: string;
  type: string;
  notes: string;
  executor_role: number;
  reviewer_role: number;
  survey_template?: number;
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Please select a type'),
  notes: yup.string(),
  executor_role: yup.number().required('Please select a executor role'),
  reviewer_role: yup.number().required('Please select a reviewer role'),
  survey_template: yup.number().when('type', (type: string) => {
    if (isSurveyTask(type)) {
      return yup.number().required('Please select a survey template');
    }
  }),
});

interface ITaskTemplateCreateDialog {
  handleClose: () => void;
  handleSuccess: (taskTemplate: ITaskTemplateListEntity) => void;
  duplicateId?: string;
}

const TaskTemplateCreateDialog: React.FC<ITaskTemplateCreateDialog> = ({
  handleClose,
  handleSuccess,
  duplicateId = null,
}) => {
  const { taskTemplate: duplicateTaskTemplate } = useTaskTemplate(duplicateId);

  const { availableRoleTemplates, isLoading: isLoadingRoleTemplates } =
    useAvailableRoleTemplates();

  const { surveyTemplates, isLoading: isLoadingSurveyTemplates } =
    useSurveyTemplates();

  const onSubmit = async (values: IFormValues) => {
    try {
      let valuesToSubmit: ITaskTemplateCreatePayload = { ...values };
      if (!isSurveyTask(values.type)) {
        // @ts-ignore
        valuesToSubmit = _.omit(values, 'survey_template');
      }
      const taskTemplate = await createTaskTemplate<ITaskTemplateListEntity>(
        valuesToSubmit
      );
      handleSuccess(taskTemplate);
      handleClose();
      notifySuccess('Task template created successfully.');
    } catch (e: any) {
      notifyApiError(e);
    }
  };

  // @ts-ignore
  const initialValues: IFormValues = useMemo(() => {
    if (duplicateTaskTemplate) {
      return {
        name: `Duplicate of ${duplicateTaskTemplate.name}`,
        type: duplicateTaskTemplate.type,
        notes: duplicateTaskTemplate.notes,
        executor_role: duplicateTaskTemplate.executor_role.id,
        reviewer_role: duplicateTaskTemplate.reviewer_role.id,
        survey_template: duplicateTaskTemplate.survey_template?.id || '',
      };
    }

    return {
      name: '',
      type: '',
      notes: '',
      executor_role: '',
      reviewer_role: '',
      survey_template: '',
    };
  }, [duplicateTaskTemplate]);

  const formik = useFormik<IFormValues>({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Dialog disableBackdropClick onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Create task template</DialogTitle>
      <DialogContent>
        <form id={FORM_ID} onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <StyledForm>
              <Field
                name="name"
                component={FormTextField}
                required
                fullWidth
                autoFocus
                label="Name"
              />

              <Field
                name="type"
                component={FormTextField}
                select
                required
                fullWidth
                label="Type"
              >
                {_.values(TASK_TEMPLATE_TYPES).map((type: string) => (
                  <MenuItem key={type} value={type}>
                    {_.capitalize(type)}
                  </MenuItem>
                ))}
              </Field>
              {isSurveyTask(formik.values.type) && (
                <Field
                  name="survey_template"
                  component={FormTextField}
                  select
                  fullWidth
                  required
                  disabled={isLoadingSurveyTemplates}
                  label="Survey Template"
                >
                  {surveyTemplates.map(
                    (surveyTemplate: ISurveyTemplateListEntity) => (
                      <MenuItem
                        key={surveyTemplate.id}
                        value={surveyTemplate.id}
                      >
                        {surveyTemplate.name}
                      </MenuItem>
                    )
                  )}
                </Field>
              )}

              <Field
                name="notes"
                component={FormTextField}
                multiline
                fullWidth
                minRows={5}
                label="Notes"
              />

              <Divider>
                <Typography variant="subtitle1">Roles</Typography>
              </Divider>
              <Field
                name="executor_role"
                component={FormTextField}
                select
                required
                fullWidth
                disabled={isLoadingRoleTemplates}
                label="Executor role"
              >
                {availableRoleTemplates.map((template: IRoleTemplate) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Field>
              <Field
                name="reviewer_role"
                component={FormTextField}
                select
                required
                fullWidth
                disabled={isLoadingRoleTemplates}
                label="Reviewer role"
              >
                {availableRoleTemplates.map((template: IRoleTemplate) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Field>
            </StyledForm>
          </FormikProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
          // We need to tell the button the form ID to submit,
          // since the <form> itself is in a different scope (in the DialogContent).
          form={FORM_ID}
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskTemplateCreateDialog;
