import {
  WORKFLOW_TEMPLATES_LIST_URL,
  WORKFLOW_TEMPLATE_DETAIL_URL,
} from 'config/urls';
import { IRouteWithEntityPermissions } from 'config/routes';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import WorkflowTemplatesList from './List';
import WorkflowTemplateDetail from './Detail';

const entity = PERMISSION_ENTITIES.TEMPLATES;

const routes: IRouteWithEntityPermissions[] = [
  {
    path: WORKFLOW_TEMPLATES_LIST_URL,
    entity,
    permission: ENTITY_PERMISSIONS.VIEW,
    element: <WorkflowTemplatesList />,
  },
  {
    path: WORKFLOW_TEMPLATE_DETAIL_URL,
    entity,
    permission: ENTITY_PERMISSIONS.VIEW,
    element: <WorkflowTemplateDetail />,
  },
];

export default routes;
