import React from 'react';

import _ from 'lodash';
import { FieldArrayRenderProps } from 'formik';

import { colors } from 'theme/palette';
import { CloseRoundedIcon } from 'icons';
import { Typography, Divider, Box, IconButton } from 'components';

import { getTotalPriceRepresentation } from 'entities/Order/utils';

const ComponentsToOrder: React.FC<FieldArrayRenderProps> = ({
  remove,
  form: {
    values: { componentsToOrder },
  },
}) => (
  <>
    {_.map(componentsToOrder, (component, index: number) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* TODO: Allow editing the quantity here */}

          <Typography variant="subtitle2">
            <strong>{component.model}</strong>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <Typography variant="subtitle2">
              ({component.quantity} x ${component.price.amount}){' '}
              <strong>
                {getTotalPriceRepresentation(
                  component.price.amount,
                  component.quantity
                )}
              </strong>
            </Typography>

            <IconButton onClick={() => remove(index)}>
              <CloseRoundedIcon />
            </IconButton>
          </Box>
        </Box>

        <Typography variant="subtitle2">{component.category_name}</Typography>

        <Divider flexItem color={colors.orange1} />
      </Box>
    ))}
  </>
);

export default ComponentsToOrder;
