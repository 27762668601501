import React from 'react';

import _ from 'lodash';

import { styled } from '@mui/material/styles';

import { Typography, Divider, Box, Notes } from 'components';

import { ITaskTemplateListEntity } from 'entities/TaskTemplate/sdk';

const StyledTaskTemplateTitle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

interface ITaskTemplateTitle {
  taskTemplate: ITaskTemplateListEntity;
}

const TaskTemplateTitle: React.FC<ITaskTemplateTitle> = ({ taskTemplate }) => (
  <StyledTaskTemplateTitle>
    <Typography variant="subtitle2">
      <strong>{_.capitalize(taskTemplate.type)}</strong>: {taskTemplate.name}
    </Typography>
    <Divider flexItem />
    <Box sx={{ display: 'flex', gap: '5px' }}>
      <Typography variant="caption">
        Executor role: <strong>{taskTemplate.executor_role.name}</strong>
      </Typography>
      <Divider flexItem orientation="vertical" />
      <Typography variant="caption">
        Reviewer role: <strong>{taskTemplate.reviewer_role.name}</strong>
      </Typography>
    </Box>
    {!_.isEmpty(taskTemplate.notes) && <Notes value={taskTemplate.notes} />}
  </StyledTaskTemplateTitle>
);

export default TaskTemplateTitle;
