import { useFetch, post } from 'utils/sdk';

export interface IUserListEntity {
  id: number;
  is_admin: boolean;
  email: string;
}

export const useAllUsers = () => {
  const {
    data: users,
    error,
    isLoading,
    refetch,
  } = useFetch<IUserListEntity[]>('users/', { paginate: false });

  return { users, error, isLoading, refetch };
};

export interface IUserDetailEntity {
  id: number;
  is_admin: boolean;
  email: string;
  user_groups: number[];
}

export const useUserDetail = (id: string) => {
  const {
    data: user,
    error,
    isLoading,
  } = useFetch<IUserDetailEntity>(`users/${id}/`);

  return { user, error, isLoading };
};

export interface IUpdateUserPayload {
  user_groups: number[];
}

export const updateUser = (id: string, payload: IUpdateUserPayload) =>
  post(`users/${id}/update/`, payload);

export interface ICreateUserPayload {
  email: string;
  password: string;
  organization: number;
  user_groups: number[];
}

export const createUser = (payload: ICreateUserPayload) =>
  post(`users/create/`, payload);
