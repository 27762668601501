import React from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { useFormik, FormikProvider, Field, FieldArray } from 'formik';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormTextField,
  Box,
  Button,
  Divider,
  Notes,
  Typography,
} from 'components';
import { notifySuccess, notifyApiError } from 'utils/notifications';

import {
  useWorkflowTemplateGroup,
  IWorkflowTemplateGroupUpdatePayload,
  updateWorkflowTemplateGroup,
} from 'entities/WorkflowTemplateGroup/sdk';

import WorkflowTemplatesFieldArray from 'entities/WorkflowTemplate/components/WorkflowTemplatesFieldArray';

interface IFormValues {
  name: string;
  workflow_templates: {
    id: number;
    name: string;
  }[];
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  workflow_templates: yup
    .array()
    .of(yup.object({ id: yup.number(), name: yup.string() }))
    .min(1, 'Please add at least one workflow template'),
});

const FORM_ID = 'workflow-template-group-edit';

interface IWorkflowTemplateGroupEditDialog {
  workflowTemplateGroupId: string;
  handleClose: () => void;
  handleSuccess: () => void;
}

const WorkflowTemplateGroupEditDialog: React.FC<
  IWorkflowTemplateGroupEditDialog
> = ({ workflowTemplateGroupId, handleClose, handleSuccess }) => {
  const { workflowTemplateGroup, isLoading, error } = useWorkflowTemplateGroup(
    workflowTemplateGroupId
  );

  const onSubmit = async (values: IFormValues) => {
    try {
      const valuesToSubmit: IWorkflowTemplateGroupUpdatePayload = {
        name: values.name,
      };

      if (workflowTemplateGroup.can_update) {
        valuesToSubmit.workflow_templates = _.map(
          values.workflow_templates,
          'id'
        );
      }

      await updateWorkflowTemplateGroup(
        workflowTemplateGroupId,
        valuesToSubmit
      );

      notifySuccess('Workflow template group updated successfully.');
      handleSuccess();
      handleClose();
    } catch (e: any) {
      notifyApiError(e);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: _.get(workflowTemplateGroup, 'name', ''),
      workflow_templates: _.get(
        workflowTemplateGroup,
        'workflow_templates',
        []
      ),
    },
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  if (isLoading || error) {
    return null;
  }

  return (
    <Dialog disableBackdropClick onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        <div>
          Edit workflow template group:{' '}
          <strong>{workflowTemplateGroup.name}</strong>
        </div>
      </DialogTitle>
      <DialogContent>
        <form id={FORM_ID} onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <Field
              name="name"
              component={FormTextField}
              required
              fullWidth
              autoFocus
              label="Name"
            />

            <Box sx={{ mt: 2 }}>
              <Divider>
                <Typography variant="subtitle1">Templates</Typography>
              </Divider>

              <Box sx={{ mt: 1, mb: 1 }}>
                {!workflowTemplateGroup.can_update && (
                  <Notes value="Templates cannot be currently modified as group is already being used in a workflow." />
                )}
              </Box>

              <FieldArray
                name="workflow_templates"
                render={props => (
                  // This is the "official" way to pass props to `FieldArray` custom component in Formik. :)
                  <WorkflowTemplatesFieldArray
                    {...props}
                    modifiable={workflowTemplateGroup.can_update}
                  />
                )}
              />
            </Box>
          </FormikProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          form={FORM_ID}
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkflowTemplateGroupEditDialog;
