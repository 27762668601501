import React, { useEffect } from 'react';

import _ from 'lodash';
import { Field, FieldArrayRenderProps } from 'formik';

import { Typography, MultiSelect, Divider, Alert } from 'components';

import { useAllUsers } from 'entities/User/sdk';
import { IRoleTemplate } from 'entities/WorkflowRole/sdk';

const USERS_FIELD_MAPPING = {
  key: 'id',
  label: 'email',
};

const RolesFieldArray: React.FC<FieldArrayRenderProps> = ({
  push,
  form: { values, setFieldValue },
  name: formPath,
}) => {
  const { users, isLoading: isLoadingUsers } = useAllUsers();

  useEffect(() => {
    // This effects handles clearing the selected users in the roles
    // each time the template is changed.
    const selectedTemplateGroupRoles: IRoleTemplate[] =
      values.template_group.unique_role_templates;

    setFieldValue(formPath, []);

    for (const roleTemplate of selectedTemplateGroupRoles) {
      push({ template: roleTemplate, users: [] });
    }
  }, [setFieldValue, formPath, values.template_group, push]);

  if (isLoadingUsers || _.isEmpty(users)) {
    return null;
  }

  return (
    <>
      <Divider>
        <Typography variant="subtitle1">Roles</Typography>
      </Divider>
      {_.map(values.roles, (role, index: number) => {
        const roleSelectField = (
          <Field
            key={index}
            name={`${formPath}.${index}.users`}
            component={MultiSelect}
            required
            fullWidth
            label={role.template.name}
            options={users}
            fieldMapping={USERS_FIELD_MAPPING}
            sx={{ mt: 2 }}
          />
        );
        if (role.template.admin) {
          return (
            <>
              {roleSelectField}
              <Alert sx={{ mt: 1 }} severity="warning">
                {role.template.name} is the admin role!
              </Alert>
            </>
          );
        }

        return <>{roleSelectField}</>;
      })}
    </>
  );
};

export default RolesFieldArray;
