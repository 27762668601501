import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

import { colors } from './palette';

const theme = createTheme({
  palette: {
    primary: {
      // Set the Odyssey orange as main color
      main: colors.mainOrange,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.grey5,
    },
  },
  components: {
    MuiSvgIcon: {
      defaultProps: {
        fontSize: 'small',
      },
    },
    MuiDialogContent: {
      defaultProps: {
        dividers: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          // Do not uppercase buttons
          textTransform: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
          backgroundColor: colors.grey1,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          '&:focus, &:focus-within': {
            outline: 0,
          },
        },
        columnSeparator: {
          color: colors.grey4,
        },
        columnHeaderTitle: {
          fontWeight: 600,
        },
        columnHeaders: {
          backgroundColor: colors.grey1,
        },
        cell: {
          '&:focus, &:focus-within': {
            outline: 0,
          },
        },
      },
    },
  },
});

export default theme;
