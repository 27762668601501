import _ from 'lodash';

import {
  FormTextField,
  Checkbox,
  Radio,
  RadioGroup,
  FormGroup,
  FileUploadField,
} from 'components';

import { FILE_TYPES } from 'entities/File/sdk';

import { QUESTION_TYPES, QUESTION_FILE_TYPES, IQuestion } from './sdk';

export const questionHasTextAnswer = (type: string): boolean =>
  type === QUESTION_TYPES.FREETEXT || type === QUESTION_TYPES.SINGLE_SELECT;

export const isNumberQuestion = (type: string): boolean =>
  type === QUESTION_TYPES.INTEGER || type === QUESTION_TYPES.DECIMAL;

export const isSelectQuestion = (type: string): boolean =>
  type === QUESTION_TYPES.SINGLE_SELECT ||
  type === QUESTION_TYPES.MULTIPLE_SELECT;

export const QUESTION_TYPE_TO_INPUT_PLACEHOLDER: {
  [type: string]: string;
} = {
  [QUESTION_TYPES.FREETEXT]: 'Your answer',
  [QUESTION_TYPES.INTEGER]: '42',
  [QUESTION_TYPES.DECIMAL]: '3.14',
};

export const QUESTION_TYPE_TO_ANSWER: {
  [type: string]: {
    fieldName: string;
    defaultValue: any;
    Component?: any;
    additionalProps?: any;
  };
} = {
  [QUESTION_TYPES.FREETEXT]: {
    fieldName: 'text_answer',
    defaultValue: '',
    additionalProps: {
      component: FormTextField,
      fullWidth: true,
      placeholder: QUESTION_TYPE_TO_INPUT_PLACEHOLDER[QUESTION_TYPES.FREETEXT],
    },
  },
  [QUESTION_TYPES.INTEGER]: {
    fieldName: 'number_answer',
    defaultValue: '',
    additionalProps: {
      component: FormTextField,
      type: 'number',
      fullWidth: true,
      placeholder: QUESTION_TYPE_TO_INPUT_PLACEHOLDER[QUESTION_TYPES.INTEGER],
    },
  },
  [QUESTION_TYPES.DECIMAL]: {
    fieldName: 'number_answer',
    defaultValue: '',
    additionalProps: {
      component: FormTextField,
      type: 'number',
      fullWidth: true,
      placeholder: QUESTION_TYPE_TO_INPUT_PLACEHOLDER[QUESTION_TYPES.DECIMAL],
      inputProps: {
        step: '0.1',
      },
    },
  },
  [QUESTION_TYPES.BOOLEAN]: {
    fieldName: 'boolean_answer',
    defaultValue: '',
    Component: RadioGroup,
    additionalProps: {
      control: <Radio />,
    },
  },
  [QUESTION_TYPES.SINGLE_SELECT]: {
    fieldName: 'text_answer',
    defaultValue: '',
    Component: RadioGroup,
    additionalProps: {
      control: <Radio />,
    },
  },
  [QUESTION_TYPES.MULTIPLE_SELECT]: {
    fieldName: 'choices_answer',
    defaultValue: [],
    Component: FormGroup,
    additionalProps: {
      control: <Checkbox />,
    },
  },
  [QUESTION_TYPES.GEOPOINT]: {
    fieldName: 'geopoint_answer',
    defaultValue: {
      longitude: '',
      latitude: '',
    },
    additionalProps: {
      component: FormTextField,
      type: 'number',
      fullWidth: true,
      inputProps: {
        step: '0.1',
      },
    },
  },
  [QUESTION_TYPES.FILE_UPLOAD]: {
    fieldName: 'file_answer',
    defaultValue: {
      file: '',
      feedback: '',
    },
    additionalProps: {
      component: FileUploadField,
    },
  },
};

// @ts-ignore
export const QUESTION_FILE_TYPE_TO_ALLOWED_FILES: {
  [key: string]: string | string[];
} = {
  [QUESTION_FILE_TYPES.IMAGE]: [
    FILE_TYPES.JPEG,
    FILE_TYPES.PNG,
    FILE_TYPES.SVG,
  ],
  [QUESTION_FILE_TYPES.PDF_FILE]: FILE_TYPES.PDF_FILE,
  [QUESTION_FILE_TYPES.WORD_DOCUMENT]: FILE_TYPES.WORD_DOCUMENT,
  [QUESTION_FILE_TYPES.EXCEL_SPREADSHEET]: FILE_TYPES.EXCEL_SPREADSHEET,
};

export const parseNumberQuestionRange = (
  question: IQuestion
): { min: number | null; max: number | null } => {
  const { lower, upper } = question.range;

  let min = parseFloat(lower);
  let max = parseFloat(upper);

  if (question.type === QUESTION_TYPES.INTEGER) {
    // @ts-ignore
    min = parseInt(min);
    // @ts-ignore
    max = parseInt(max);
  }

  return { min: min || null, max: max || null };
};

export const getNumberQuestionHelperText = (question: IQuestion): string => {
  const { min, max } = parseNumberQuestionRange(question);

  const helperTexts: string[] = [];

  if (min) {
    helperTexts.push(`Min value: ${min}`);
  }
  if (max) {
    helperTexts.push(`Max value: ${max}`);
  }

  return _.join(helperTexts, ' / ');
};
