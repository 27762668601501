import React, { useCallback } from 'react';

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { FieldArrayRenderProps } from 'formik';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { styled } from '@mui/material/styles';

import { colors } from 'theme/palette';

import { useMenu } from 'utils/hooks';
import { Box, AddButton, Menu, MenuItem, Paper } from 'components';

import { IQuestion, QUESTION_TYPES } from 'entities/Question/sdk';
import { isSelectQuestion, isNumberQuestion } from 'entities/Question/utils';
import QuestionType from 'entities/Question/components/QuestionType';

import QuestionForm from '../QuestionForm';

interface IStyledQuestionsContainer {
  isDraggingOver: boolean;
}

const StyledQuestionsContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isDraggingOver',
})<IStyledQuestionsContainer>(({ isDraggingOver }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  marginTop: '5px',
  backgroundColor: isDraggingOver ? colors.grey1 : colors.grey0,
  border: `1px solid ${colors.grey3}`,
  borderRadius: '15px',
  padding: '20px 50px',
}));

const QuestionsFieldArray: React.FC<FieldArrayRenderProps> = ({
  push,
  move,
  remove,
  form,
}) => {
  const { values } = form;

  const {
    anchorEl: questionTypeAnchorEl,
    isOpen: questionTypeMenuOpen,
    open: handleQuestionTypeButtonClick,
    close: handleQuestionTypeMenuClose,
  } = useMenu();

  const handleAddNewQuestion = useCallback(
    (questionType: string) => {
      let newQuestion: IQuestion = {
        uuid: uuidv4(),
        title: '',
        type: questionType,
        required: false,
        hint: '',
      };

      if (isNumberQuestion(questionType)) {
        newQuestion = {
          ...newQuestion,
          // @ts-ignore
          min: '',
          // @ts-ignore
          max: '',
        };
      }

      if (isSelectQuestion(questionType)) {
        newQuestion = {
          ...newQuestion,
          // Add a "placeholder" empty option when adding a new select question
          options: [
            {
              uuid: uuidv4(),
              value: '',
            },
          ],
        };
      }

      if (questionType === QUESTION_TYPES.FILE_UPLOAD) {
        newQuestion = {
          ...newQuestion,
          file_type: '',
        };
      }

      push(newQuestion);

      handleQuestionTypeMenuClose();
    },
    [handleQuestionTypeMenuClose, push]
  );

  const onQuestionDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      const startIndex = source.index;
      const endIndex = destination.index;

      if (startIndex === endIndex) {
        return;
      }

      move(startIndex, endIndex);
    },
    [move]
  );

  return (
    <DragDropContext onDragEnd={onQuestionDragEnd}>
      <Droppable droppableId="questions">
        {(provided, snapshot) => (
          <StyledQuestionsContainer
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
          >
            {_.map(values.questions, (question, index: number) => (
              <QuestionForm
                key={question.uuid}
                index={index}
                question={question}
                handleRemove={() => {
                  // Maybe add a confirmation alert?
                  remove(index);
                }}
              />
            ))}
            {provided.placeholder}

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <AddButton
                variant="contained"
                onClick={handleQuestionTypeButtonClick}
              >
                Add question
              </AddButton>
              <Paper>
                <Menu
                  anchorEl={questionTypeAnchorEl}
                  open={questionTypeMenuOpen}
                  onClose={handleQuestionTypeMenuClose}
                >
                  {_.values(QUESTION_TYPES).map((questionType: string) => (
                    <MenuItem
                      key={questionType}
                      onClick={() => handleAddNewQuestion(questionType)}
                    >
                      <QuestionType type={questionType} />
                    </MenuItem>
                  ))}
                </Menu>
              </Paper>
            </Box>
          </StyledQuestionsContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default QuestionsFieldArray;
