import React, { useMemo } from 'react';

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormikProvider, useFormik, Field, FieldArray } from 'formik';

import { SURVEY_TEMPLATE_DETAIL_URL } from 'config/urls';
import {
  FormTextField,
  Typography,
  Button,
  Box,
  FormHelperText,
} from 'components';
import yup from 'utils/yup';

import { IQuestion, QUESTION_TYPES } from 'entities/Question/sdk';
import {
  isSelectQuestion,
  isNumberQuestion,
  parseNumberQuestionRange,
} from 'entities/Question/utils';
import {
  useSurveyTemplate,
  ISurveyTemplateCreateValues,
  createSurveyTemplate,
} from 'entities/SurveyTemplate/sdk';

import { buildDetailUrl } from 'utils/urls';
import { notifySuccess, notifyApiError } from 'utils/notifications';

import QuestionsFieldArray from './components/QuestionsFieldArray';

export interface IFormValues {
  name: string;
  questions: {
    uuid: string;

    title: string;
    type: string;
    required: boolean;
    hint?: string;

    // Number question specific fields
    min?: number;
    max?: number;

    // Select question specific fields
    options?: {
      uuid: string;
      value: string;
    }[];

    // File upload question specific fields
    file_type?: string;
    template_file?: {
      id: number;
      file_type: string;
    };
  }[];
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  questions: yup
    .array()
    .of(
      yup.object({
        title: yup.string().required('Question title is required'),
        type: yup.string().required(),
        required: yup.boolean().required(),
        hint: yup.string(),

        // Number question specific fields
        min: yup
          .number()
          // @ts-ignore
          .when(['type', 'max'], (type: string, max: number, schema) => {
            if (isNumberQuestion(type)) {
              const validator =
                type === QUESTION_TYPES.INTEGER
                  ? schema.integer('Please provide an integer value')
                  : schema;

              if (max) {
                return validator.max(
                  max,
                  `Min value cannot be greater than ${max}`
                );
              }
            }
          }),
        max: yup.number().when('type', (type: string, schema) => {
          if (type === QUESTION_TYPES.INTEGER) {
            return schema.integer('Please provide an integer value');
          }

          return schema;
        }),

        // Select question specific fields
        options: yup
          .array()
          .of(
            yup.object({
              value: yup.string(),
            })
          )
          .when('type', (type: string) => {
            if (isSelectQuestion(type)) {
              return yup
                .array()
                .of(
                  yup.object({
                    value: yup.string().required('Please provide an option'),
                  })
                )
                .unique('Duplicated options are not allowed')
                .min(1, 'Please add at least 1 option')
                .required();
            }
          }),

        // File upload question specific fields
        file_type: yup.string().when('type', (type: string, schema) => {
          if (type === QUESTION_TYPES.FILE_UPLOAD) {
            return schema.required('Please select a file type');
          }
        }),
      })
    )
    .min(1, 'Please add at least 1 question')
    .required(),
});

const SurveyTemplateCreate: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const duplicateId = useMemo(
    () => searchParams.get('duplicateId'),
    [searchParams]
  );

  const { surveyTemplate: duplicateSurveyTemplate } =
    useSurveyTemplate(duplicateId);

  const onSubmit = async (values: IFormValues) => {
    try {
      let valuesToSubmit: ISurveyTemplateCreateValues = {
        ...values,
        questions: _.map(values.questions, question => {
          const response = _.omit(
            question,
            'uuid',
            'options',
            'min',
            'max',
            'template_file'
          );

          if (isSelectQuestion(question.type)) {
            return { ...response, options: _.map(question.options, 'value') };
          }

          if (question.template_file) {
            return {
              ...response,
              template_file: question.template_file.id,
            };
          }

          const { min, max } = question;

          if (min || max) {
            let range = {};

            if (min) {
              range = { ...range, lower: min };
            }
            if (max) {
              range = { ...range, upper: max };
            }

            if (!_.isEmpty(range)) {
              return { ...response, range };
            }
          }

          return response;
        }),
      };

      const { id } = await createSurveyTemplate(valuesToSubmit);

      notifySuccess('Survey template created successfully.');
      navigate(buildDetailUrl(SURVEY_TEMPLATE_DETAIL_URL, id));
    } catch (e: any) {
      notifyApiError(e);
    }
  };

  // @ts-ignore
  const initialValues: IFormValues = useMemo(() => {
    if (duplicateSurveyTemplate) {
      return {
        name: `Duplicate of ${duplicateSurveyTemplate.name}`,
        questions: _.map(
          duplicateSurveyTemplate.questions,
          (question: IQuestion) => {
            const response = {
              uuid: uuidv4(),
              ..._.omit(question, 'range', 'options', 'file_type'),
            };

            if (isNumberQuestion(question.type)) {
              const { min, max } = parseNumberQuestionRange(question);

              return { ...response, min: min || '', max: max || '' };
            }

            if (isSelectQuestion(question.type)) {
              return {
                ...response,
                options: _.map(question.options, (value: string) => ({
                  uuid: uuidv4(),
                  value,
                })),
              };
            }

            if (question.type === QUESTION_TYPES.FILE_UPLOAD) {
              return { ...response, file_type: question.file_type };
            }

            return response;
          }
        ),
      };
    }

    return {
      name: '',
      questions: [],
    };
  }, [duplicateSurveyTemplate]);

  const formik = useFormik<IFormValues>({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  // Do not display form if we are creating a duplicate but the form is not populated.
  if (duplicateId && _.isEmpty(formik.initialValues.name)) {
    // TODO: Display better loading screen here (skeleton)
    return null;
  }

  return (
    <>
      <FormikProvider value={formik}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Survey template
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
              gap: 10,
            }}
          >
            <Field
              name="name"
              component={FormTextField}
              autoFocus
              fullWidth
              required
              label="Name"
            />
            <Button
              variant="contained"
              type="submit"
              sx={{ width: '200px' }}
              disabled={
                formik.isSubmitting ||
                (!duplicateId && !formik.dirty) ||
                !formik.isValid
              }
            >
              Save
            </Button>
          </Box>

          <Typography variant="h5" sx={{ mt: 2 }}>
            Questions
          </Typography>
          <FieldArray
            name="questions"
            // @ts-ignore
            component={QuestionsFieldArray}
          />

          {_.isEmpty(formik.values.questions) && (
            <FormHelperText error variant="filled">
              Please add at least 1 question
            </FormHelperText>
          )}
        </form>
      </FormikProvider>
    </>
  );
};

export default SurveyTemplateCreate;
