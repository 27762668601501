import { WORKFLOWS_LIST_URL, WORKFLOW_DETAIL_URL } from 'config/urls';
import { IRouteWithEntityPermissions } from 'config/routes';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import WorkflowsList from './List';
import WorkflowDetail from './Detail';

const entity = PERMISSION_ENTITIES.WORKFLOWS;

const routes: IRouteWithEntityPermissions[] = [
  {
    path: WORKFLOWS_LIST_URL,
    entity,
    permission: ENTITY_PERMISSIONS.VIEW,
    element: <WorkflowsList />,
  },
  {
    path: WORKFLOW_DETAIL_URL,
    entity,
    permission: ENTITY_PERMISSIONS.VIEW,
    element: <WorkflowDetail />,
  },
];

export default routes;
