import React, { useState, useCallback } from 'react';

import _ from 'lodash';
import { styled } from '@mui/material/styles';

import { colors } from 'theme/palette';
import { SendRoundedIcon } from 'icons';
import { Typography, TextField, Box, IconButton, Paper } from 'components';

import { dateTimeToHumanReadable } from 'utils/date';
import { notifySuccess, notifyApiError } from 'utils/notifications';

import {
  IChangeRequest,
  addChangeRequestComment,
} from 'entities/ChangeRequest/sdk';

const StyledChangeRequest = styled(Paper)(() => ({
  padding: '15px',
  marginTop: '10px',
}));

const StyledComment = styled(Paper)(() => ({
  padding: '10px',
  backgroundColor: colors.grey0,
}));

interface IChangeRequestDetails {
  request: IChangeRequest;
  refetchChangeRequests: () => void;
  disableAddComment?: boolean;
}

const ChangeRequestDetails: React.FC<IChangeRequestDetails> = ({
  request,
  refetchChangeRequests,
  disableAddComment = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');

  const submitComment = useCallback(async () => {
    setIsSubmitting(true);

    try {
      await addChangeRequestComment(request.id, comment);

      notifySuccess('Comment added successfully');

      refetchChangeRequests();
      setComment('');
    } catch (e: any) {
      notifyApiError(e);
    }

    setIsSubmitting(false);
  }, [request.id, comment, refetchChangeRequests]);

  const handleCommentChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setComment(e.target.value),
    []
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        submitComment();
      }
    },
    [submitComment]
  );

  return (
    <StyledChangeRequest elevation={3}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle1">
          Reason: <strong>{request.reason}</strong>
        </Typography>

        <Typography
          variant="caption"
          color={colors.grey4}
          sx={{ marginRight: '10px' }}
        >
          {dateTimeToHumanReadable(request.created_at)} by{' '}
          <strong>{request.created_by.email}</strong>
        </Typography>
      </Box>

      {!_.isEmpty(request.comments) && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginTop: '10px',
          }}
        >
          {_.map(request.comments, (comment, index: number) => (
            <StyledComment key={index} variant="outlined">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle2">
                  <strong>{comment.created_by.email}</strong>
                </Typography>
                <Typography variant="caption" color={colors.grey4}>
                  {dateTimeToHumanReadable(comment.created_at)}
                </Typography>
              </Box>

              <Typography variant="subtitle2">{comment.text}</Typography>
            </StyledComment>
          ))}
        </Box>
      )}

      {!disableAddComment && (
        <Box sx={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
          <TextField
            fullWidth
            label="Comment"
            value={comment}
            onKeyDown={handleKeyDown}
            onChange={handleCommentChange}
            disabled={isSubmitting}
          />
          <IconButton
            color="success"
            disableRipple
            onClick={submitComment}
            disabled={isSubmitting || _.isEmpty(comment)}
          >
            <SendRoundedIcon />
          </IconButton>
        </Box>
      )}
    </StyledChangeRequest>
  );
};

export default ChangeRequestDetails;
