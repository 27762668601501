import React, { useMemo, useCallback } from 'react';

import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Box, AddFab, Typography, Protected, LinearProgress } from 'components';
import DataGrid, {
  transformToColumns,
  GridColDef,
  GridAlignment,
  GridRowParams,
} from 'components/DataGrid';

import { WORKFLOW_DETAIL_URL } from 'config/urls';

import { buildDetailUrl } from 'utils/urls';
import { useDialogWithQueryParam } from 'utils/hooks';
import { parseDateTime, formatDate } from 'utils/date';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import { IWorkflow, useWorkflows } from 'entities/Workflow/sdk';

import WorkflowCreate from '../Create';

const columnsFields: string[] = [
  'workflow',
  'templateGroup',
  'dateCreated',
  'dateCompleted',
  'progress',
];

const columns: GridColDef[] = transformToColumns(columnsFields).map(col => {
  if (col.field.startsWith('date')) {
    return {
      ...col,
      type: 'date',
      valueGetter: ({ value }) => parseDateTime(value),
      valueFormatter: ({ value }) => formatDate(value) || 'N/A',
    };
  }

  if (col.field === 'progress') {
    return {
      ...col,
      type: 'number',
      headerAlign: 'center' as GridAlignment,
      align: 'center' as GridAlignment,
      renderCell: ({ row: { progress } }) => (
        <Box sx={{ width: '80%' }}>
          <LinearProgress value={progress} />
        </Box>
      ),
    };
  }

  return col;
});

const WorkflowList: React.FC = () => {
  const navigate = useNavigate();

  const { workflows, error, isLoading } = useWorkflows();

  const rows = useMemo(
    () =>
      _.map(workflows, (workflow: IWorkflow) => {
        const { id, name, template_group, created_at, completed_at, progress } =
          workflow;

        return {
          id,
          workflow: name,
          templateGroup: template_group.name,
          dateCreated: created_at,
          dateCompleted: completed_at,
          progress: progress * 100,
        };
      }),
    [workflows]
  );

  const handleRowClick = useCallback(
    (params: GridRowParams) =>
      navigate(buildDetailUrl(WORKFLOW_DETAIL_URL, params.row.id)),
    [navigate]
  );

  const {
    isOpen: isCreateDialogOpen,
    open: openCreateDialog,
    close: closeCreateDialog,
  } = useDialogWithQueryParam('create');

  if (error) {
    return (
      <Typography variant="subtitle2">Failed to fetch workflows.</Typography>
    );
  }

  return (
    <>
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows}
        onRowClick={handleRowClick}
      />

      <Protected
        entity={PERMISSION_ENTITIES.WORKFLOWS}
        permission={ENTITY_PERMISSIONS.ADD}
      >
        <AddFab onClick={openCreateDialog} />
        {isCreateDialogOpen && (
          <WorkflowCreate handleClose={closeCreateDialog} />
        )}
      </Protected>
    </>
  );
};

export default WorkflowList;
