import React, { useMemo } from 'react';

import _ from 'lodash';

import {
  DataGrid as MuiDataGrid,
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
  GridAlignment,
  DATA_GRID_PROPS_DEFAULT_VALUES,
} from '@mui/x-data-grid';

export const transformToColumns = (fields: string[]): GridColDef[] =>
  _.map(fields, (field: string) => ({
    field,
    headerName: _.startCase(field),
    flex: 1,
  }));

const { rowHeight, headerHeight } = DATA_GRID_PROPS_DEFAULT_VALUES;
const borderHeight = 2; // Top & bottom borders are 1px in height, resulting in 2px for both.

interface IDataGrid extends React.ComponentProps<typeof MuiDataGrid> {
  maxRows?: number;
}

const DataGrid: React.FC<IDataGrid> = ({ rows, maxRows = 10, ...rest }) => {
  const autoHeight = useMemo(() => rows.length < maxRows, [rows, maxRows]);

  const sx = useMemo(() => {
    let inlineStyles = rest.sx;

    // If we have a row click handler provided - style the rows with a pointer cursor.
    if (rest.onRowClick) {
      inlineStyles = {
        ...inlineStyles,
        '& .MuiDataGrid-row:hover': { cursor: 'pointer' },
      };
    }

    // If we have more rows than the max - calculate height so we can display a scrollbar after the max rows displayed.
    if (!autoHeight) {
      const rowsHeight = rowHeight * maxRows;
      const height = headerHeight + rowsHeight + borderHeight;

      inlineStyles = { ...inlineStyles, height };
    }

    return inlineStyles;
  }, [autoHeight, maxRows, rest.sx, rest.onRowClick]);

  return (
    <MuiDataGrid
      hideFooter
      rows={rows}
      autoHeight={autoHeight}
      localeText={{
        // Reference: https://github.com/mui/mui-x/blob/HEAD/packages/grid/x-data-grid/src/constants/localeTextConstants.ts
        columnMenuHideColumn: 'Hide column',
        columnsPanelShowAllButton: 'Show all columns',
        columnsPanelHideAllButton: 'Hide all columns',
      }}
      {...rest}
      sx={sx}
    />
  );
};

export default DataGrid;

export type { GridColDef, GridRowParams, GridAlignment };
export { GridActionsCellItem };
