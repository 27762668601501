import { useFetch } from 'utils/sdk';

export interface IRoleTemplate {
  id: number;
  name: string;
  admin: boolean;
}

export const useAvailableRoleTemplates = () => {
  const { data, error, isLoading } = useFetch<IRoleTemplate[]>(
    'workflows/templates/roles/'
  );

  return { availableRoleTemplates: data || [], error, isLoading };
};
