import { colors } from 'theme/palette';

// Node element dimensions. These can be altered based on our needs.
export const [NODE_WIDTH_IN_PX, NODE_HEIGHT_IN_PX] = [330, 120];

// Indicates how many nodes we display on a single line before transfering them to a new one. This can be altered based on our needs.
export const NODES_ON_A_SINGLE_LINE = 3;

export const ZOOM_SPEED_IN_MS = 800;
export const ZOOM_DELAY_SPEED_IN_MS = 50;

export const COMMON_NODE_STYLES = {
  padding: '15px',
  width: `${NODE_WIDTH_IN_PX}px`,
  height: `${NODE_HEIGHT_IN_PX}px`,
  borderRadius: '15px',
  borderColor: colors.grey4,
};
