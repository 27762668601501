import React from 'react';

import _ from 'lodash';
import { useLocation, Link } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import {
  Box,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  Toolbar,
} from 'components';

import { colors } from 'theme/palette';

import { useRouteGroupsForSignedUser, IRouteEntry, IRouteGroup } from './utils';

export const drawerWidth = '270px';

const Sidebar: React.FC = () => {
  const { pathname: currentPath } = useLocation();
  const userRouteGroups = useRouteGroupsForSignedUser();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          backgroundColor: colors.grey0,
          boxSizing: 'border-box',
        },
      }}
    >
      <Toolbar />
      <Box sx={{ pt: 2 }}>
        {_.map(userRouteGroups, (routeGroup: IRouteGroup) => (
          <div key={routeGroup.name}>
            <Divider>
              <Typography variant="subtitle1">
                <strong>{routeGroup.name}</strong>
              </Typography>
            </Divider>
            <List>
              {_.map(routeGroup.routes, (route: IRouteEntry) => (
                <ListItem
                  button
                  key={route.to}
                  component={Link}
                  to={route.to}
                  selected={_.startsWith(currentPath, route.to)}
                >
                  <ListItemIcon sx={{ minWidth: '40px' }}>
                    {route.icon}
                  </ListItemIcon>
                  <ListItemText primary={route.text} />
                </ListItem>
              ))}
            </List>
          </div>
        ))}
      </Box>
    </Drawer>
  );
};

export default Sidebar;
