import React, { useCallback } from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Field, FieldArray, FormikProvider } from 'formik';

import { WORKFLOW_DETAIL_URL } from 'config/urls';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormTextField,
  Button,
  MenuItem,
  Box,
} from 'components';
import { buildDetailUrl } from 'utils/urls';
import { notifySuccess, notifyApiError } from 'utils/notifications';

import {
  IWorkflowTemplateGroupListEntity,
  useWorkflowTemplateGroups,
} from 'entities/WorkflowTemplateGroup/sdk';
import { createWorkflow, IWorkflowCreatePayload } from 'entities/Workflow/sdk';

import RolesFieldArray from './components/RolesFieldArray';

interface IFormValues {
  name: string;
  template_group: {
    id: number;
    name: string;
  };
  roles: {
    template: { id: number; name: string };
    users: number[];
  }[];
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  template_group: yup.object().required('Template group is required'),
  roles: yup
    .array()
    .of(
      yup.object({
        template: yup.object({ id: yup.number(), name: yup.string() }),
        users: yup
          .array()
          .of(yup.number())
          .when('template', ({ name: templateName }, schema) =>
            schema.min(1, `Please assign at least one user for ${templateName}`)
          ),
      })
    )
    .min(1)
    .required(),
});

const FORM_ID = 'workflowForm';

interface IWorkflowCreate {
  handleClose: () => void;
}

const WorkflowCreate: React.FC<IWorkflowCreate> = ({ handleClose }) => {
  const navigate = useNavigate();

  const { workflowTemplateGroups, isLoading: isLoadingWorkflowTemplateGroups } =
    useWorkflowTemplateGroups();

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      try {
        const { name, template_group, roles } = values;
        const valuesToSubmit: IWorkflowCreatePayload = {
          name,
          template_group: template_group.id,
          roles: _.map(roles, role => ({
            template: role.template.id,
            users: role.users,
          })),
        };

        const { id } = await createWorkflow(valuesToSubmit);
        notifySuccess('Workflow created successfully.');
        handleClose();

        navigate(buildDetailUrl(WORKFLOW_DETAIL_URL, id));
      } catch (e: any) {
        notifyApiError(e);
      }
    },
    [handleClose, navigate]
  );

  const formik = useFormik<IFormValues>({
    initialValues: {
      name: '',
      // @ts-ignore
      template_group: '',
      roles: [],
    },
    validationSchema,
    onSubmit,
  });

  return (
    <Dialog disableBackdropClick onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Create workflow</DialogTitle>
      <DialogContent>
        <form id={FORM_ID} onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <Field
              name="name"
              component={FormTextField}
              required
              fullWidth
              autoFocus
              label="Name"
            />

            {!isLoadingWorkflowTemplateGroups && (
              <Field
                name="template_group"
                component={FormTextField}
                required
                select
                fullWidth
                label="Template group"
                sx={{ mt: 2 }}
              >
                {workflowTemplateGroups.map(
                  (templateGroup: IWorkflowTemplateGroupListEntity) => (
                    <MenuItem
                      key={templateGroup.id}
                      value={templateGroup as any}
                    >
                      {templateGroup.name}
                    </MenuItem>
                  )
                )}
              </Field>
            )}

            {formik.values.template_group && (
              <Box sx={{ mt: 2 }}>
                <FieldArray
                  name="roles"
                  // @ts-ignore
                  component={RolesFieldArray}
                />
              </Box>
            )}
          </FormikProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          form={FORM_ID}
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkflowCreate;
