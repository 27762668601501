import React, { useMemo, useCallback } from 'react';

import _ from 'lodash';
import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';

import { useMenu } from 'utils/hooks';
import { colors } from 'theme/palette';
import { Box, Button, Menu, MenuItem, Toolbar, Divider } from 'components';
import { OdysseyLogo, AccountCircleRoundedIcon, HelpRoundedIcon } from 'icons';

import { useSignedUser } from 'entities/Auth/sdk';
import { BASE_API_URL } from 'utils/constants';

const Navigation: React.FC = () => {
  const { user } = useSignedUser();

  const userEmail = useMemo(() => _.get(user, 'email', ''), [user]);

  const {
    anchorEl,
    isOpen: isAccountMenuOpen,
    open: handleAccountClick,
    close: handleAccountClose,
  } = useMenu();

  const handleLogout = useCallback(() => {
    // We do this because this endpoint returns a 302 redirect response.
    window.location.href = `${BASE_API_URL}/auth/logout/`;
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: colors.white,
          borderBottom: `1px solid ${colors.grey3}`,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar>
          <Box sx={{ height: '50px', flexGrow: 1 }}>
            <Link to="/">
              <img
                src={OdysseyLogo}
                alt="Odyssey company logo"
                style={{ height: '50px' }}
              />
            </Link>
          </Box>
          <Button
            variant="text"
            color="secondary"
            onClick={handleAccountClick}
            endIcon={<AccountCircleRoundedIcon />}
          >
            {userEmail}
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={isAccountMenuOpen}
            onClose={handleAccountClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
          <Divider
            flexItem
            orientation="vertical"
            sx={{ marginLeft: 1, marginRight: 1 }}
          />
          <Button variant="text" endIcon={<HelpRoundedIcon />}>
            Help
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Navigation;
