import React, { useMemo } from 'react';

import _ from 'lodash';

import { ITaskDetailEntity } from 'entities/Task/sdk';
import { useSurveyQuestionsList } from 'entities/Survey/sdk';

import SubmitAnswersForm from '../components/SubmitAnswersForm';
import ReviewAnswersForm from '../components/ReviewAnswersForm';

interface ISurveyTaskDetail {
  task: ITaskDetailEntity;
}

const SurveyTaskDetail: React.FC<ISurveyTaskDetail> = ({ task }) => {
  const surveyId = useMemo(() => _.get(task, 'survey_id'), [task]);

  const { questions, isLoading: isLoadingQuestions } = useSurveyQuestionsList(
    surveyId,
    { revalidateOnFocus: false }
  );

  if (isLoadingQuestions) {
    return null;
  }

  return (
    <>
      {task.is_executor && !task.completed_at && (
        <SubmitAnswersForm
          taskId={task.id}
          hasChangeRequests={task.has_change_requests}
          questions={questions}
        />
      )}
      {task.is_reviewer && (task.completed_at || task.has_change_requests) && (
        <ReviewAnswersForm taskId={task.id} questions={questions} />
      )}
    </>
  );
};

export default SurveyTaskDetail;
