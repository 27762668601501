export const colors = {
  white: '#ffffff',

  mainOrange: '#ff7f00',

  grey0: '#eef0f0',
  grey1: '#e0e0e0',
  grey2: '#d4d4d4',
  grey3: '#bababa',
  grey4: '#969696',
  grey5: '#373939',

  orange0: '#fcecdc',
  orange1: '#ffba75',
  orange2: '#ff9d3b',

  red0: '#ff8a8a',
  red1: '#ff5454',

  green0: '#f3fcdc',
};
