import _ from 'lodash';

// Price: "12.00"
// Quantity: 3
// Result -> "$36.00"
export const getTotalPriceRepresentation = (
  price: string,
  quantity: number
): string => {
  const totalPrice = _.toNumber(price) * quantity;
  const formattedPrice = totalPrice.toFixed(2);
  return `$${formattedPrice}`;
};
