import { notifyError } from 'utils/notifications';

export const bytesToMegaBytes = (bytes: number): string =>
  (bytes / 1024 ** 2).toFixed(2);

export const downloadUrl = async (url: string, filename: string) => {
  const fileResponse = await fetch(url);

  if (!fileResponse.ok) {
    notifyError('Unable to download file.');
    return;
  }

  const fileData = await fileResponse.blob();

  const element = document.createElement('a');

  element.href = URL.createObjectURL(fileData);
  element.download = filename;

  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
