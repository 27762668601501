import React, { useMemo, useState, useCallback } from 'react';

import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import { TASKS_LIST_URL } from 'config/urls';
import { Box, Typography, Button } from 'components';
import {
  notifySuccess,
  notifyError,
  notifyApiError,
} from 'utils/notifications';

import TaskContentWrapper from 'entities/Task/components/ContentWrapper';

import { approveTask, rejectTask } from 'entities/Task/sdk';
import { ISurveyQuestion, requestChangesForAnswers } from 'entities/Survey/sdk';

import QuestionDetails from './QuestionDetails';

export interface IFormValues {
  changeRequests: { answer: number; reason: string; comment: string }[];
}

interface IReviewAnswersForm {
  taskId: number;
  questions: ISurveyQuestion[];
}

const ReviewAnswersForm: React.FC<IReviewAnswersForm> = ({
  taskId,
  questions,
}) => {
  const navigate = useNavigate();

  const [isApprovingTask, setIsApprovingTask] = useState<boolean>(false);
  const [isRejectingTask, setIsRejectingTask] = useState<boolean>(false);

  const handleApproveTask = useCallback(async () => {
    setIsApprovingTask(true);

    try {
      await approveTask(taskId);
      notifySuccess('Task approved');
      setIsApprovingTask(false);
      navigate(TASKS_LIST_URL);
    } catch (e: any) {
      setIsApprovingTask(false);
      notifyApiError(e);
    }
  }, [taskId, navigate]);

  const handleRejectTask = useCallback(async () => {
    setIsRejectingTask(true);

    try {
      await rejectTask(taskId);
      notifyError('Task rejected');
      setIsRejectingTask(false);
      navigate(TASKS_LIST_URL);
    } catch (e: any) {
      setIsRejectingTask(false);
      notifyApiError(e);
    }
  }, [taskId, navigate]);

  const initialValues = useMemo(
    () => ({
      changeRequests: _.map(questions, question => ({
        answer: _.get(question, 'answers.0.id'),
        reason: '',
        comment: '',
      })),
    }),
    [questions]
  );

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      try {
        const valuesToSubmit = _(values.changeRequests)
          .filter(({ reason }) => !_.isEmpty(reason))
          .map(({ comment, ...changeRequest }) => {
            if (_.isEmpty(comment)) {
              return changeRequest;
            }

            return {
              ...changeRequest,
              comments: [{ text: comment }],
            };
          })
          .value();

        await requestChangesForAnswers({ change_requests: valuesToSubmit });

        notifySuccess('Changes requested successfully');
        navigate(TASKS_LIST_URL);
      } catch (e: any) {
        notifyApiError(e);
      }
    },
    [navigate]
  );

  const formik = useFormik<IFormValues>({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  const isSubmitting = useMemo(
    () => isApprovingTask || isRejectingTask || formik.isSubmitting,
    [isApprovingTask, isRejectingTask, formik.isSubmitting]
  );

  const canRequestChanges = useMemo(
    () => _(formik.values.changeRequests).map('reason').some(),
    [formik.values.changeRequests]
  );

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h6">Answers:</Typography>
      <TaskContentWrapper>
        <FormikProvider value={formik}>
          {_.map(questions, (question, index: number) => (
            <QuestionDetails key={index} index={index} question={question} />
          ))}
        </FormikProvider>
      </TaskContentWrapper>

      <Box mt={2}>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button
            color="error"
            variant="contained"
            onClick={handleRejectTask}
            disabled={isSubmitting}
          >
            Reject
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting || !canRequestChanges}
          >
            Request changes
          </Button>
          <Button
            color="success"
            variant="contained"
            onClick={handleApproveTask}
            disabled={isSubmitting}
          >
            Approve
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default ReviewAnswersForm;
