import React from 'react';

import _ from 'lodash';
import { Field } from 'formik';

import {
  Divider,
  Typography,
  DownloadableFile,
  Notes,
  MenuItem,
  FormTextField,
  FileUploadField,
} from 'components';

import { IQuestion, QUESTION_FILE_TYPES } from 'entities/Question/sdk';
import QuestionFileType from 'entities/Question/components/QuestionFileType';

interface IFileUploadQuestionValidations {
  question: IQuestion;
  modifiable: boolean;
  index: number;
}

const FileUploadQuestionValidations: React.FC<
  IFileUploadQuestionValidations
> = ({ question, modifiable, index }) => {
  return (
    <>
      <Divider>
        <Typography variant="subtitle1">Template file</Typography>
      </Divider>

      {question.template_file && (
        <DownloadableFile
          title="Current template file"
          url={question.template_file.url}
          name={question.template_file.original_file_name}
        />
      )}

      <Notes value="Upload an optional template file that the task executors should use as a guide when answering the question." />
      <Field
        name={`questions.${index}.template_file`}
        disabled={!modifiable}
        component={FileUploadField}
      />

      <Field
        name={`questions.${index}.file_type`}
        component={FormTextField}
        select
        fullWidth
        required
        disabled={!modifiable}
        label="File type"
      >
        {_.map(QUESTION_FILE_TYPES, (value: string, key: string) => (
          <MenuItem key={key} value={value}>
            <QuestionFileType type={value} />
          </MenuItem>
        ))}
      </Field>
    </>
  );
};

export default FileUploadQuestionValidations;
