export const USERS_LIST_URL = '/users';

export const WORKFLOW_TEMPLATE_GROUPS_URL = '/workflow-template-groups';
export const WORKFLOW_TEMPLATE_GROUPS_LIST_URL = `${WORKFLOW_TEMPLATE_GROUPS_URL}`;
export const WORKFLOW_TEMPLATE_GROUP_DETAIL_URL = `${WORKFLOW_TEMPLATE_GROUPS_URL}/:id`;

export const WORKFLOW_TEMPLATES_URL = '/workflow-templates';
export const WORKFLOW_TEMPLATES_LIST_URL = `${WORKFLOW_TEMPLATES_URL}`;
export const WORKFLOW_TEMPLATE_DETAIL_URL = `${WORKFLOW_TEMPLATES_URL}/:id`;

export const WORKFLOWS_URL = '/workflows';
export const WORKFLOWS_LIST_URL = `${WORKFLOWS_URL}`;
export const WORKFLOW_DETAIL_URL = `${WORKFLOWS_URL}/:id`;

export const SURVEY_TEMPLATES_URL = '/survey-templates';
export const SURVEY_TEMPLATES_LIST_URL = `${SURVEY_TEMPLATES_URL}`;
export const SURVEY_TEMPLATE_CREATE_URL = `${SURVEY_TEMPLATES_URL}/create`;
export const SURVEY_TEMPLATE_DETAIL_URL = `${SURVEY_TEMPLATES_URL}/:id`;
export const SURVEY_TEMPLATE_EDIT_URL = `${SURVEY_TEMPLATE_DETAIL_URL}/edit`;

export const TASK_TEMPLATES_URL = '/task-templates';
export const TASK_TEMPLATES_LIST_URL = `${TASK_TEMPLATES_URL}`;

export const USER_GROUPS_URL = '/user-groups';
export const USER_GROUPS_LIST_URL = `${USER_GROUPS_URL}`;

export const TASKS_URL = '/tasks';
export const TASKS_LIST_URL = `${TASKS_URL}`;
export const TASK_DETAIL_URL = `${TASKS_URL}/:id`;

export const COMPONENTS_URL = '/components';
export const COMPONENTS_LIST_URL = `${COMPONENTS_URL}`;
export const COMPONENT_DETAIL_URL = `${COMPONENTS_URL}/:id`;
