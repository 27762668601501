import { post } from 'utils/sdk';

export interface IFile {
  id: number;
  original_file_name: string;
  file_type: string;
  url: string;
}

interface IDirectUploadStartPayload {
  file_name: string;
  file_type: string;
}

interface IDirectUploadStartResponse {
  id: number;
  url: string;
  fields: { [key: string]: string };
}

export const directUploadStart = (
  payload: IDirectUploadStartPayload
): Promise<IDirectUploadStartResponse> =>
  post<IDirectUploadStartResponse>('files/upload/direct/start/', payload);

interface IDirectUploadDoPayload {
  file: File;
  data: IDirectUploadStartResponse;
}

export const directUploadDo = async ({
  data,
  file,
}: IDirectUploadDoPayload) => {
  const { url, fields } = data;

  const postData = new FormData();

  for (const key in fields) {
    postData.append(key, fields[key]);
  }

  postData.append('file', file);

  // We use the native `fetch` API here and not the SDK `post` wrapper that we have,
  // because here we call the S3 URL that the server returned from the upload start API call,
  // and we also omit credentials here in order to avoid S3 CORS errors.
  const response = await fetch(url, {
    method: 'POST',
    credentials: fields ? 'omit' : 'include',
    body: postData,
  });

  return response;
};

interface IDirectUploadFinishPayload {
  data: IDirectUploadStartResponse;
}

export const directUploadFinish = ({ data }: IDirectUploadFinishPayload) =>
  post<IFile>('files/upload/direct/finish/', { file_id: data.id });

export const MAX_FILE_SIZE_IN_BYTES = 10485760; // 10 MiB

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const FILE_TYPES: { [key: string]: string | string[] } = {
  // Images
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  SVG: 'image/svg+xml',

  // Documents
  WORD_DOCUMENT: [
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  ],
  EXCEL_SPREADSHEET: [
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  ],
  PDF_FILE: 'application/pdf',
};
