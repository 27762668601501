import { USER_GROUPS_LIST_URL } from 'config/urls';
import { IRouteWithEntityPermissions } from 'config/routes';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import UserGroupsList from './List';

const entity = PERMISSION_ENTITIES.USER_GROUPS;

const routes: IRouteWithEntityPermissions[] = [
  {
    path: USER_GROUPS_LIST_URL,
    entity,
    permission: ENTITY_PERMISSIONS.VIEW,
    element: <UserGroupsList />,
  },
];

export default routes;
