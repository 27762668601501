import React from 'react';

import { Link } from 'react-router-dom';

import { styled } from '@mui/material/styles';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

import { colors } from 'theme/palette';
import { NavigateNextIcon } from 'icons';
import { Paper, Typography } from 'components';

const StyledLink = styled(Link)(() => ({
  color: colors.mainOrange,
  '&:hover': {
    textDecoration: 'none',
  },
}));

interface IBreadcrumb {
  name: string;
  url?: string;
}

interface IBreadcrumbs {
  breadcrumbs: IBreadcrumb[];
}

const Breadcrumbs: React.FC<IBreadcrumbs> = ({ breadcrumbs }) => (
  <Paper sx={{ p: 1, mb: 1 }}>
    <MuiBreadcrumbs
      separator={
        <NavigateNextIcon fontSize="small" sx={{ color: colors.grey4 }} />
      }
    >
      {breadcrumbs.map(({ name, url }) =>
        url ? (
          <StyledLink key={url} to={url}>
            {name}
          </StyledLink>
        ) : (
          <Typography key={name} color={colors.grey4} variant="subtitle1">
            {name}
          </Typography>
        )
      )}
    </MuiBreadcrumbs>
  </Paper>
);

export default Breadcrumbs;
