import React, { useState, useEffect, useMemo, useCallback } from 'react';

import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Typography, Protected, AddFab } from 'components';
import DataGrid, {
  transformToColumns,
  GridRowParams,
  GridColDef,
  GridActionsCellItem,
  GridAlignment,
} from 'components/DataGrid';

import { COMPONENT_DETAIL_URL } from 'config/urls';

import { EditRoundedIcon } from 'icons';
import { buildDetailUrl } from 'utils/urls';
import { useDialogWithQueryParam } from 'utils/hooks';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import { formatPrice } from 'entities/Component/utils';
import { useComponentList } from 'entities/Component/sdk';
import ComponentCreateDialog from 'entities/Component/components/CreateDialog';
import ComponentEditDialog from 'entities/Component/components/EditDialog';

const EDIT_QUERY_PARAM = 'edit';

const columnsFields: string[] = [
  'model',
  'manufacturer',
  'category',
  'latestPrice',
  'actions',
];

const ComponentList: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { components, isLoading, error, refetch } = useComponentList();

  const {
    isOpen: isCreateDialogOpen,
    open: openCreateDialog,
    close: closeCreateDialog,
  } = useDialogWithQueryParam('create');

  const [editComponentId, setEditComponentId] = useState<string>(
    searchParams.get(EDIT_QUERY_PARAM)
  );

  const {
    isOpen: isEditDialogOpen,
    open: openEditDialog,
    close: closeEditDialog,
  } = useDialogWithQueryParam(EDIT_QUERY_PARAM, editComponentId);

  useEffect(() => {
    // Open/close the edit dialog based on the state variable, which can be either preset from the query params or manually set from the table edit handler.
    if (!_.isEmpty(editComponentId)) {
      openEditDialog();
    } else {
      closeEditDialog();
    }
  }, [editComponentId, openEditDialog, closeEditDialog]);

  const rows = useMemo(
    () =>
      _.map(components, component => {
        const { id, model, manufacturer, category, latest_price } = component;

        return {
          id,
          model,
          category: _.get(category, 'name', 'N/A'),
          manufacturer: manufacturer.name,
          latestPrice: _.get(latest_price, 'amount'),
        };
      }),
    [components]
  );

  const handleRowClick = useCallback(
    (params: GridRowParams) =>
      navigate(buildDetailUrl(COMPONENT_DETAIL_URL, params.row.id)),
    [navigate]
  );

  const columns: GridColDef[] = useMemo(
    () =>
      transformToColumns(columnsFields).map((col: GridColDef) => {
        if (col.field === 'latestPrice') {
          return {
            ...col,
            type: 'number',
            align: 'center' as GridAlignment,
            headerAlign: 'center' as GridAlignment,
            valueGetter: ({ value: price }) => parseFloat(price),
            valueFormatter: ({ value }) => formatPrice(value),
          };
        }

        if (col.field === 'actions') {
          return {
            ...col,
            type: 'actions',
            headerName: '',
            maxWidth: 50,
            getActions: (params: GridRowParams) => [
              <GridActionsCellItem
                icon={<EditRoundedIcon />}
                onClick={() => setEditComponentId(params.id.toString())}
                label="Edit"
                showInMenu
              />,
            ],
          };
        }

        return col;
      }),
    []
  );

  if (error) {
    return (
      <Typography variant="subtitle2">Failed to fetch components</Typography>
    );
  }

  return (
    <>
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows}
        onRowClick={handleRowClick}
      />

      {isEditDialogOpen && !_.isNil(editComponentId) && (
        <ComponentEditDialog
          componentId={editComponentId}
          handleClose={() => setEditComponentId(null)}
          handleSuccess={refetch}
        />
      )}

      <Protected
        entity={PERMISSION_ENTITIES.COMPONENTS}
        permission={ENTITY_PERMISSIONS.ADD}
      >
        <AddFab onClick={openCreateDialog} />
        {isCreateDialogOpen && (
          <ComponentCreateDialog
            handleClose={closeCreateDialog}
            handleSuccess={refetch}
          />
        )}
      </Protected>
    </>
  );
};

export default ComponentList;
