import { useFetch, post } from 'utils/sdk';

export interface ITaskToDo {
  id: number;
  template: {
    id: number;
    name: string;
    type: string;
  };
  workflow: {
    id: number;
    name: string;
  };

  executor_role: {
    id: number;
    name: string;
  };
  reviewer_role: {
    id: number;
    name: string;
  };

  executor?: {
    id: number;
    email: string;
    organization: {
      id: number;
      short_name: string;
    } | null;
  };
  reviewer?: {
    id: number;
    email: string;
  };
}

export const useTasksToDo = () => {
  const { data, error, isLoading } = useFetch<ITaskToDo[]>(
    'workflows/tasks/todo/'
  );

  return { tasks: data, error, isLoading };
};

export interface ITaskDetailEntity {
  id: number;

  survey_id: number;
  order_id: number;

  has_change_requests: boolean;

  template: {
    id: number;
    name: string;
    type: string;
    notes: string;
  };
  workflow: {
    id: number;
    name: string;
  };

  executor_role: {
    id: number;
    name: string;
  };
  executor: {
    id: number;
    email: string;
  };

  is_executor: boolean;
  is_reviewer: boolean;

  completed_at: string;
  reviewed_at: string;
}

export const useTaskDetail = (taskId: string) => {
  const { data, error, isLoading } = useFetch<ITaskDetailEntity>(
    `workflows/tasks/${taskId}/`
  );

  return { task: data, error, isLoading };
};

export const completeTask = (
  taskId: number,
  values: { answers?: any[]; order_items?: any[] }
) => post(`workflows/tasks/${taskId}/complete/`, values);

export const approveTask = (taskId: number) =>
  post(`workflows/tasks/${taskId}/approve/`, {});

export const rejectTask = (taskId: number) =>
  post(`workflows/tasks/${taskId}/reject/`, {});

export const claimTask = (taskId: number) =>
  post(`workflows/tasks/${taskId}/claim/`, {});

export const TASK_STATUS = {
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
  FOR_REVIEW: 'for_review',
  REJECTED: 'rejected',
};

export const TASK_STATUS_TO_COLOR_VARIANT: {
  [key: string]: 'primary' | 'success' | 'error' | 'info';
} = {
  [TASK_STATUS.IN_PROGRESS]: 'primary',
  [TASK_STATUS.COMPLETED]: 'success',
  [TASK_STATUS.REJECTED]: 'error',
  [TASK_STATUS.FOR_REVIEW]: 'info',
};
