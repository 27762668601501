import React from 'react';

import { styled } from '@mui/material/styles';

import { colors } from 'theme/palette';
import Paper, { PaperProps } from 'components/Paper';

const StyledItemWrapper = styled(Paper)(() => ({
  width: '50%',
  padding: '15px',
  backgroundColor: colors.white,
}));

const ItemWrapper: React.FC<PaperProps> = ({ children, ...rest }) => (
  <StyledItemWrapper variant="outlined" {...rest}>
    {children}
  </StyledItemWrapper>
);

export default ItemWrapper;
