import React from 'react';

import MuiTableContainer from '@mui/material/TableContainer';
import type { TableContainerProps } from '@mui/material/TableContainer';

import { Paper } from 'components';

const TableContainer: React.FC<TableContainerProps> = ({
  children,
  ...props
}) => (
  <MuiTableContainer component={Paper} {...props}>
    {children}
  </MuiTableContainer>
);

export default TableContainer;
