import React, { useMemo, useCallback, useEffect } from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { useFormik, FormikProvider, Field } from 'formik';

import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  AutocompleteField,
  FormTextField,
  MenuItem,
  Divider,
  Typography,
  DateField,
} from 'components';

import { now } from 'utils/date';
import { notifySuccess, notifyApiError } from 'utils/notifications';

import { generateAutocompleteOptions } from 'components/AutocompleteField/utils';

import {
  useComponentDetail,
  IComponentUpdateValues,
  updateComponent,
} from 'entities/Component/sdk';
import { useComponentCategoryList } from 'entities/Component/Category/sdk';
import { useComponentManufacturersList } from 'entities/Component/Manufacturer/sdk';

const FORM_ID = 'component-update-form-id';

const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
}));

interface IFormValues {
  categoryName: {
    value: string;
  };
  manufacturer: string;

  model: string;
  description: string;

  price: {
    amount: string;
    date: string;
  };
}

const validationSchema = yup.object({
  categoryName: yup.object({
    value: yup.string().required('Category is required'),
  }),
  manufacturer: yup.string().required('Manufacturer is required'),

  model: yup.string().required('Model is required'),
  description: yup.string(),

  price: yup.object({
    amount: yup.number().positive('Price must be a positive number'),
    date: yup
      .date()
      // We need the `date` to be nullable, because we want to be able to clear this value by setting it to null.
      .nullable()
      .when('amount', (amount: number, schema) => {
        if (amount) {
          return schema.required();
        }
      }),
  }),
});

interface IComponentEditDialog {
  componentId: string;
  handleClose: () => void;
  handleSuccess: () => void;
}

const ComponentEditDialog: React.FC<IComponentEditDialog> = ({
  componentId,
  handleClose,
  handleSuccess,
}) => {
  const { component, isLoading: isLoadingComponent } =
    useComponentDetail(componentId);

  const { categories, isLoading: isLoadingComponentCategories } =
    useComponentCategoryList();

  const { manufacturers, isLoading: isLoadingManufacturers } =
    useComponentManufacturersList();

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      const { categoryName, price } = values;

      try {
        let valuesToSubmit: IComponentUpdateValues = {
          category_name: categoryName.value,
          ..._.pick(values, 'model', 'description', 'manufacturer'),
        };

        if (price.amount && price.date) {
          valuesToSubmit = {
            ...valuesToSubmit,
            price: {
              amount: price.amount,
              valid_until: price.date,
            },
          };
        }

        await updateComponent(componentId, valuesToSubmit);

        notifySuccess('Component updated successfully.');

        handleSuccess();
        handleClose();
      } catch (e: any) {
        notifyApiError(e);
      }
    },
    [componentId, handleSuccess, handleClose]
  );

  const formik = useFormik<IFormValues>({
    initialValues: {
      categoryName: {
        value: _.get(component, 'category.name', ''),
      },
      manufacturer: _.get(component, 'manufacturer.id', ''),

      model: _.get(component, 'model', ''),
      description: _.get(component, 'description', ''),

      // TODO: Populate from latest price when available from API
      price: {
        amount: '',
        date: null,
      },
    },
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const { setFieldValue, values } = formik;
  const { amount } = values.price;

  useEffect(() => {
    if (!amount) {
      setFieldValue('price.date', null, false);
    }
  }, [amount, setFieldValue]);

  const isLoading = useMemo(
    () =>
      isLoadingComponent ||
      isLoadingComponentCategories ||
      isLoadingManufacturers,
    [isLoadingComponent, isLoadingComponentCategories, isLoadingManufacturers]
  );

  if (isLoading || _.isEmpty(formik.initialValues.model)) {
    return null;
  }

  return (
    <Dialog disableBackdropClick onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Update component</DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <StyledForm id={FORM_ID} onSubmit={formik.handleSubmit}>
            <Field
              name="categoryName"
              component={AutocompleteField}
              options={generateAutocompleteOptions(categories, 'name')}
              loading={isLoadingComponentCategories}
              textFieldProps={{ label: 'Category', required: true }}
            />

            <Field
              name="manufacturer"
              component={FormTextField}
              select
              required
              label="Manufacturer"
            >
              {_.map(manufacturers, ({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Field>

            <Field
              name="model"
              component={FormTextField}
              required
              label="Model"
            />
            <Field
              name="description"
              component={FormTextField}
              multiline
              minRows={3}
              label="Description"
            />

            <Divider>
              <Typography variant="subtitle1">Price</Typography>
            </Divider>

            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '10px',
              }}
            >
              <Field
                name="price.amount"
                component={FormTextField}
                fullWidth
                type="number"
                label="Amount"
                inputProps={{ min: 0, step: 0.01 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">USD</InputAdornment>
                  ),
                }}
              />
              <Field
                disabled={!amount}
                name="price.date"
                component={DateField}
                fullWidth
                minDate={now()}
                label="Valid until"
              />
            </Box>
          </StyledForm>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          form={FORM_ID}
          type="submit"
          variant="contained"
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ComponentEditDialog;
