import React from 'react';

import _ from 'lodash';
import { useParams, Link } from 'react-router-dom';

import { buildDetailUrl } from 'utils/urls';
import { RESOURCES } from 'utils/constants';
import { useDialogWithQueryParam } from 'utils/hooks';
import {
  WORKFLOW_TEMPLATE_DETAIL_URL,
  WORKFLOW_TEMPLATE_GROUPS_LIST_URL,
} from 'config/urls';
import {
  Typography,
  Divider,
  Protected,
  EditFab,
  Breadcrumbs,
} from 'components';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import { useWorkflowTemplateGroup } from 'entities/WorkflowTemplateGroup/sdk';
import WorkflowTemplateGroupEditDialog from 'entities/WorkflowTemplateGroup/components/EditDialog';

const WorkflowTemplateGroupDetail: React.FC = () => {
  const { id: workflowTemplateGroupId } = useParams();

  const { workflowTemplateGroup, isLoading, error, refetch } =
    useWorkflowTemplateGroup(workflowTemplateGroupId);

  const {
    isOpen: isEditDialogOpen,
    open: openEditDialog,
    close: closeEditDialog,
  } = useDialogWithQueryParam('edit');

  if (isLoading) {
    return (
      <Typography variant="subtitle2">Loading workflow template...</Typography>
    );
  }

  if (error) {
    return (
      <Typography variant="subtitle2">
        Failed to fetch workflow template.
      </Typography>
    );
  }

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            name: RESOURCES.WORKFLOW_TEMPLATE_GROUPS,
            url: WORKFLOW_TEMPLATE_GROUPS_LIST_URL,
          },
          { name: workflowTemplateGroup.name },
        ]}
      />

      {!_.isEmpty(workflowTemplateGroup.workflow_templates) && (
        <>
          <Divider>
            <Typography variant="h6">Workflow templates</Typography>
          </Divider>

          <ul>
            {_.map(
              workflowTemplateGroup.workflow_templates,
              workflowTemplate => (
                <li key={workflowTemplate.id}>
                  <Link
                    to={buildDetailUrl(
                      WORKFLOW_TEMPLATE_DETAIL_URL,
                      workflowTemplate.id
                    )}
                  >
                    {workflowTemplate.name}
                  </Link>
                </li>
              )
            )}
          </ul>
        </>
      )}

      <Protected
        entity={PERMISSION_ENTITIES.TEMPLATES}
        permission={ENTITY_PERMISSIONS.CHANGE}
      >
        <EditFab onClick={openEditDialog} />
        {isEditDialogOpen && (
          <WorkflowTemplateGroupEditDialog
            workflowTemplateGroupId={workflowTemplateGroupId}
            handleClose={closeEditDialog}
            handleSuccess={refetch}
          />
        )}
      </Protected>
    </>
  );
};

export default WorkflowTemplateGroupDetail;
