import React, { useEffect, useMemo, useState, useCallback } from 'react';

import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { Typography, AddFab, Protected } from 'components';
import DataGrid, {
  transformToColumns,
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
} from 'components/DataGrid';

import { EditRoundedIcon } from 'icons';
import { useDialogWithQueryParam } from 'utils/hooks';

import { useSignedUser } from 'entities/Auth/sdk';
import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import { IUserGroupListEntity, useAllUserGroups } from 'entities/UserGroup/sdk';
import UserGroupEditDialog from 'entities/UserGroup/components/EditDialog';
import UserGroupCreateDialog from 'entities/UserGroup/components/CreateDialog';

const EDIT_QUERY_PARAM = 'edit';

const columnsFields: string[] = ['userGroup', 'actions'];

const UserGroupsList: React.FC = () => {
  const [searchParams] = useSearchParams();

  const { refetch: refetchSignedUser } = useSignedUser();

  const {
    userGroups,
    isLoading,
    error,
    refetch: refetchUserGroups,
  } = useAllUserGroups();

  const {
    isOpen: isCreateDialogOpen,
    open: openCreateDialog,
    close: closeCreateDialog,
  } = useDialogWithQueryParam('create');

  const [editUserGroupId, setEditUserGroupId] = useState<string>(
    searchParams.get(EDIT_QUERY_PARAM)
  );

  const {
    isOpen: isEditDialogOpen,
    open: openEditDialog,
    close: closeEditDialog,
  } = useDialogWithQueryParam(EDIT_QUERY_PARAM, editUserGroupId);

  useEffect(() => {
    // Open/close the edit dialog based on the state variable, which can be either preset from the query params or manually set from the table edit handler.
    if (!_.isEmpty(editUserGroupId)) {
      openEditDialog();
    } else {
      closeEditDialog();
    }
  }, [editUserGroupId, openEditDialog, closeEditDialog]);

  const handleUserGroupEditSuccess = useCallback(() => {
    refetchUserGroups();
    // We need to refetch the user in order to update the permissions
    refetchSignedUser();
  }, [refetchUserGroups, refetchSignedUser]);

  const rows = useMemo(
    () =>
      _.map(userGroups, (userGroup: IUserGroupListEntity) => {
        const { id, name } = userGroup;

        return {
          id,
          userGroup: name,
        };
      }),
    [userGroups]
  );

  const columns: GridColDef[] = useMemo(
    () =>
      transformToColumns(columnsFields).map((col: GridColDef) => {
        if (col.field === 'actions') {
          return {
            ...col,
            type: 'actions',
            headerName: '',
            maxWidth: 50,
            getActions: (params: GridRowParams) => [
              <GridActionsCellItem
                icon={<EditRoundedIcon />}
                onClick={() => setEditUserGroupId(params.id.toString())}
                label="Edit"
                showInMenu
              />,
            ],
          };
        }

        return col;
      }),
    []
  );

  if (error) {
    return (
      <Typography variant="subtitle2">Failed to fetch user groups</Typography>
    );
  }

  return (
    <>
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows}
        disableSelectionOnClick
      />

      {isEditDialogOpen && !_.isNil(editUserGroupId) && (
        <UserGroupEditDialog
          userGroupId={editUserGroupId}
          handleClose={() => setEditUserGroupId(null)}
          handleSuccess={handleUserGroupEditSuccess}
        />
      )}
      <Protected
        entity={PERMISSION_ENTITIES.USER_GROUPS}
        permission={ENTITY_PERMISSIONS.ADD}
      >
        <AddFab onClick={openCreateDialog} />
        {isCreateDialogOpen && (
          <UserGroupCreateDialog
            handleClose={closeCreateDialog}
            handleSuccess={refetchUserGroups}
          />
        )}
      </Protected>
    </>
  );
};

export default UserGroupsList;
