import React, { useMemo } from 'react';

import _ from 'lodash';
import { Field, useFormikContext } from 'formik';

import {
  Box,
  FormTextField,
  FormControlLabel,
  FormHelperText,
  DownloadableFile,
  Divider,
  Checkbox,
} from 'components';

import { QUESTION_TYPES } from 'entities/Question/sdk';
import {
  isNumberQuestion,
  getNumberQuestionHelperText,
  QUESTION_TYPE_TO_ANSWER,
  QUESTION_FILE_TYPE_TO_ALLOWED_FILES,
} from 'entities/Question/utils';

interface IAnswerInput {
  disabled: boolean;
  question: any;
  fieldName: string;
  isTouched: boolean;
  errorMessage: string;
}

const AnswerInput: React.FC<IAnswerInput> = ({
  disabled,
  question,
  fieldName,
  isTouched,
  errorMessage,
}) => {
  const { values } = useFormikContext();

  const answerValue = useMemo(
    () => _.get(values, fieldName),
    [values, fieldName]
  );

  const { Component: AnswerComponent, additionalProps } =
    QUESTION_TYPE_TO_ANSWER[question.type];

  if (isNumberQuestion(question.type)) {
    // Display either the error message (if there is such) or a hint text for min/max value limitations
    const helperText = errorMessage
      ? errorMessage
      : getNumberQuestionHelperText(question);

    return (
      <Field
        name={fieldName}
        {...additionalProps}
        helperText={helperText}
        disabled={disabled}
      />
    );
  }

  if (question.type === QUESTION_TYPES.MULTIPLE_SELECT) {
    return (
      <AnswerComponent>
        {_.map(question.options, (option: string, index: number) => (
          <Field
            key={index}
            disabled={disabled}
            value={option}
            label={option}
            name={fieldName}
            as={FormControlLabel}
            control={<Checkbox checked={_.includes(answerValue, option)} />}
          />
        ))}

        {isTouched && errorMessage && (
          <FormHelperText error variant="filled">
            {errorMessage}
          </FormHelperText>
        )}
      </AnswerComponent>
    );
  }

  if (question.type === QUESTION_TYPES.SINGLE_SELECT) {
    return (
      <AnswerComponent value={answerValue}>
        {_.map(question.options, (option: string, index: number) => (
          <Field
            key={index}
            disabled={disabled}
            value={option}
            label={option}
            name={fieldName}
            as={FormControlLabel}
            {...additionalProps}
          />
        ))}

        {isTouched && errorMessage && (
          <FormHelperText error variant="filled">
            {errorMessage}
          </FormHelperText>
        )}
      </AnswerComponent>
    );
  }

  if (question.type === QUESTION_TYPES.BOOLEAN) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <AnswerComponent value={answerValue}>
          <Field
            value={false}
            disabled={disabled}
            label="No"
            name={fieldName}
            as={FormControlLabel}
            {...additionalProps}
          />
          <Field
            value={true}
            disabled={disabled}
            label="Yes"
            name={fieldName}
            as={FormControlLabel}
            {...additionalProps}
          />

          {isTouched && errorMessage && (
            <FormHelperText error variant="filled">
              {errorMessage}
            </FormHelperText>
          )}
        </AnswerComponent>
      </Box>
    );
  }

  if (question.type === QUESTION_TYPES.GEOPOINT) {
    return (
      <>
        <Field
          name={`${fieldName}.longitude`}
          disabled={disabled}
          label="Longitude"
          {...additionalProps}
        />
        <Field
          name={`${fieldName}.latitude`}
          disabled={disabled}
          label="Latitude"
          {...additionalProps}
          sx={{ marginTop: '10px' }}
        />
      </>
    );
  }

  if (question.type === QUESTION_TYPES.FILE_UPLOAD) {
    const { template_file } = question;

    const allowedFiles =
      QUESTION_FILE_TYPE_TO_ALLOWED_FILES[question.file_type];

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        {template_file && (
          <DownloadableFile
            title="Template file"
            url={template_file.url}
            name={template_file.original_file_name}
          />
        )}

        <Divider />

        <Field
          name={`${fieldName}.file`}
          disabled={disabled}
          {...additionalProps}
          accept={allowedFiles}
        />

        <Field
          name={`${fieldName}.feedback`}
          disabled={disabled}
          component={FormTextField}
          label="Feedback (optional)"
          multiline
          fullWidth
          minRows={3}
        />
      </Box>
    );
  }

  return <Field name={fieldName} disabled={disabled} {...additionalProps} />;
};

export default AnswerInput;
