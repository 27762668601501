import { useFetch } from 'utils/sdk';

export interface IComponentCategory {
  id: number;
  name: string;
}

export const useComponentCategoryList = () => {
  const {
    data: categories,
    error,
    isLoading,
    refetch,
  } = useFetch<IComponentCategory[]>('components/categories/');

  return { categories, error, isLoading, refetch };
};
