import React from 'react';

import _ from 'lodash';

import MuiTableRow from '@mui/material/TableRow';
import type { TableRowProps } from '@mui/material/TableRow';

// This is a "discriminated union" in TypeScript
// with which we can accomplish conditional props for the component.
// It basically says:
//   -> If `clickable` is `false` - `onClick` is optional.
//   -> If `clickable` is `true`  - `onClick` is required.
// Reference: https://www.benmvp.com/blog/conditional-react-props-typescript/
type ClickableProps =
  | { clickable?: false; onClick?: any }
  | { clickable: true; onClick: any };

const TableRow: React.FC<TableRowProps & ClickableProps> = ({
  clickable = false,
  children,
  ...rest
}) => {
  const props = {
    // https://mui.com/api/table-row/#props
    hover: clickable || rest.hover,
    sx: {
      // Set a pointer cursor if row is clickable
      cursor: clickable && 'pointer',
      ...rest.sx,
    },
    ..._.omit(rest, 'hover', 'sx'),
  };

  return <MuiTableRow {...props}>{children}</MuiTableRow>;
};

export default TableRow;
