import React from 'react';

import Fab from 'components/Fab';
import type { FabProps } from 'components/Fab';

import { EditRoundedIcon } from 'icons';

const EditFab: React.FC<FabProps> = ({ onClick, ...rest }) => (
  <Fab
    color="primary"
    aria-label="edit"
    onClick={onClick}
    sx={{ position: 'fixed', bottom: 20, right: 20 }}
    {...rest}
  >
    <EditRoundedIcon />
  </Fab>
);

export default EditFab;
