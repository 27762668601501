import React, { useMemo } from 'react';

import _ from 'lodash';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceDot,
} from 'recharts';

import { colors } from 'theme/palette';
import { Typography } from 'components';
import { parseDateTime, formatDateTime } from 'utils/date';

import { formatPrice } from 'entities/Component/utils';
import { IComponentDetailEntity } from 'entities/Component/sdk';

interface IComponentPriceHistory {
  component: IComponentDetailEntity;
}

const ComponentPriceHistory: React.FC<IComponentPriceHistory> = ({
  component,
}) => {
  const chartData = useMemo(
    () =>
      _.map(component.prices, price => ({
        ...price,
        created_at: formatDateTime(
          parseDateTime(price.created_at),
          'yyyy-MM-dd [HH:mm]'
        ),
        amount: _.toNumber(price.amount),
      })),
    [component.prices]
  );

  const latestPrice = useMemo(() => _.last(chartData), [chartData]);

  if (_.isNil(latestPrice)) {
    return null;
  }

  return (
    <div>
      <Typography variant="h6" align="center" ml={6}>
        Price history
      </Typography>

      <LineChart width={800} height={400} data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />

        <XAxis dataKey="created_at" padding={{ left: 70, right: 70 }} />
        <YAxis
          type="number"
          // Reference: https://recharts.org/en-US/api/YAxis#domain
          // [0] - Lower limit is automatically calculated by ReCharts.
          // [1] - Upper limit is the max value + 1% of it.
          //             This ensures that we always have a little "padding" effect
          //             at the top of the chart so it doesn't look cut.
          domain={['auto', (dataMax: number) => dataMax + 0.01 * dataMax]}
        />

        <Tooltip
          formatter={(value: string, name: string) => [
            formatPrice(value),
            _.capitalize(name),
          ]}
        />

        <ReferenceDot
          r={10}
          stroke="none"
          fill={colors.red0}
          x={latestPrice.created_at}
          y={latestPrice.amount}
        />

        <Line type="monotone" dataKey="amount" stroke={colors.mainOrange} />
      </LineChart>
    </div>
  );
};

export default ComponentPriceHistory;
