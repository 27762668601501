import React from 'react';

import { colors } from 'theme/palette';
import { Box, Typography } from 'components';

interface IBlockquote {
  value: string;
}

const Notes: React.FC<IBlockquote> = ({ value }) => (
  <Box
    sx={{
      borderLeft: `3px solid ${colors.mainOrange}`,
      paddingLeft: '5px',
      fontStyle: 'italic',
    }}
  >
    <Typography variant="caption">{value}</Typography>
  </Box>
);

export default Notes;
