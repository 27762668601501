import React, { useCallback } from 'react';

import { FieldArrayRenderProps } from 'formik';
import { styled } from '@mui/material/styles';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { colors } from 'theme/palette';
import { Typography, Divider, Box, FormHelperText } from 'components';

import { useWorkflowTemplates } from '../../sdk';

import UsedWorkflowTemplate from '../UsedWorkflowTemplate';
import AvailableWorkflowTemplate from '../AvailableWorkflowTemplate';

const StyledWorkflowTemplatesFieldArray = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '2fr 1px 2fr',
  gridGap: '10px',
  padding: '10px',
  border: `1px solid ${colors.grey2}`,
  borderRadius: '5px',
}));

const StyledWorkflowTemplatesContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '10px 2px',
  height: '300px',
  overflowY: 'auto',
}));

interface IWorkflowTemplatesFieldArray extends FieldArrayRenderProps {
  modifiable: boolean;
}

const WorkflowTemplatesFieldArray: React.FC<IWorkflowTemplatesFieldArray> = ({
  move,
  handlePush,
  handleRemove,
  form: { values, errors },
  modifiable = true,
}) => {
  const { workflowTemplates: availableWorkflowTemplates } =
    useWorkflowTemplates();

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      const startIndex = source.index;
      const endIndex = destination.index;

      if (startIndex === endIndex) {
        return;
      }

      move(startIndex, endIndex);
    },
    [move]
  );

  return (
    <StyledWorkflowTemplatesFieldArray>
      <div>
        <Typography variant="subtitle1" color={colors.grey4}>
          Available workflow templates
        </Typography>
        <StyledWorkflowTemplatesContainer>
          {availableWorkflowTemplates.map(workflowTemplate => (
            <AvailableWorkflowTemplate
              key={workflowTemplate.id}
              modifiable={modifiable}
              workflowTemplate={workflowTemplate}
              handleAdd={handlePush(workflowTemplate)}
            />
          ))}
        </StyledWorkflowTemplatesContainer>
      </div>
      <Divider light orientation="vertical" />
      <div>
        <Typography variant="subtitle1" color={colors.grey4}>
          Used workflow templates
        </Typography>

        {errors.workflow_templates && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '90%',
            }}
          >
            {/* By default, `FormHelperText` has some margins to display it a bit "nested". We do not want this here, thus we remove the margins explicitly. */}
            <FormHelperText error variant="filled" sx={{ margin: 0 }}>
              {errors.workflow_templates}
            </FormHelperText>
          </Box>
        )}

        {!errors.workflow_templates && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="availableWorkflowTemplates">
              {provided => (
                <StyledWorkflowTemplatesContainer
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {values.workflow_templates.map(
                    (
                      workflowTemplate: { id: number; name: string },
                      index: number
                    ) => (
                      <UsedWorkflowTemplate
                        key={index}
                        index={index}
                        modifiable={modifiable}
                        workflowTemplate={workflowTemplate}
                        handleRemove={handleRemove(index)}
                      />
                    )
                  )}
                  {provided.placeholder}
                </StyledWorkflowTemplatesContainer>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    </StyledWorkflowTemplatesFieldArray>
  );
};

export default WorkflowTemplatesFieldArray;
