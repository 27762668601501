import { useFetch } from 'utils/sdk';

export interface IOrderItem {
  id: number;

  component: {
    id: number;
    category_name: string;
    manufacturer_name: string;
    model: string;
    description: string;
  };

  price: {
    id: number;
    amount: string;
  };

  quantity: number;
}

export const useOrderItemsList = (orderId: number) => {
  const {
    data: orderItems,
    error,
    isLoading,
  } = useFetch<IOrderItem[]>(
    orderId ? `components/orders/${orderId}/items/` : null
  );

  return { orderItems, error, isLoading };
};
