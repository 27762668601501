import React from 'react';

import _ from 'lodash';
import { useFormik, FormikProvider, Field } from 'formik';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Box,
  Checkbox,
  Divider,
  Typography,
} from 'components';

import { notifySuccess, notifyApiError } from 'utils/notifications';

import { IUserGroupListEntity, useAllUserGroups } from 'entities/UserGroup/sdk';
import {
  useUserDetail,
  IUpdateUserPayload,
  updateUser,
} from 'entities/User/sdk';

const FORM_ID = 'user-edit-form';

interface IFormValues {
  userGroups: { id: number; name: string; selected: boolean }[];
}

interface IUserEditDialog {
  userId: string;
  handleClose: () => void;
  handleSuccess: () => void;
}

const UserEditDialog: React.FC<IUserEditDialog> = ({
  userId,
  handleClose,
  handleSuccess,
}) => {
  const { user, isLoading: isLoadingUser } = useUserDetail(userId);
  const { userGroups, isLoading: isLoadingUserGroups } = useAllUserGroups();

  const onSubmit = async (values: IFormValues) => {
    try {
      const userGroupsIds = _.map(
        _.filter(values.userGroups, 'selected'),
        'id'
      );
      const valuesToSubmit: IUpdateUserPayload = {
        user_groups: userGroupsIds,
      };

      await updateUser(userId, valuesToSubmit);

      handleSuccess();
      handleClose();

      notifySuccess(`User ${user.email} updated successfully.`);
    } catch (e: any) {
      notifyApiError(e);
    }
  };

  const formik = useFormik({
    initialValues: {
      userGroups: _.map(userGroups, (userGroup: IUserGroupListEntity) => ({
        ...userGroup,
        selected: _.includes(_.get(user, 'user_groups', []), userGroup.id),
      })),
    },
    onSubmit,
    enableReinitialize: true,
  });

  if (isLoadingUser || isLoadingUserGroups) {
    return null;
  }

  return (
    <Dialog disableBackdropClick onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        <div>
          Edit user <strong>{user.email}</strong>
        </div>
      </DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <form id={FORM_ID} onSubmit={formik.handleSubmit}>
            <Divider>
              <Typography variant="subtitle1">User groups</Typography>
            </Divider>
            <Box sx={{ display: 'grid' }}>
              {_.map(
                userGroups,
                (userGroup: IUserGroupListEntity, index: number) => (
                  <FormControlLabel
                    key={userGroup.id}
                    label={userGroup.name}
                    control={
                      <Field
                        type="checkbox"
                        name={`userGroups.${index}.selected`}
                        as={Checkbox}
                      />
                    }
                  />
                )
              )}
            </Box>
          </form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          form={FORM_ID}
          type="submit"
          variant="contained"
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserEditDialog;
