import React, { useMemo } from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { useFormik, FormikProvider, Field } from 'formik';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormTextField,
  Button,
  Divider,
  Typography,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components';

import { notifySuccess, notifyApiError } from 'utils/notifications';

import {
  IEntityPermission,
  getEntityPermissionsPairsList,
} from 'entities/EntityPermission/sdk';
import { groupPermissionsByEntity } from 'entities/EntityPermission/utils';

import {
  ICreateUserGroupPayload,
  createUserGroup,
} from 'entities/UserGroup/sdk';

const FORM_ID = 'user-group-edit-form';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
});

interface IFormValues {
  name: string;
  groupedEntityPermissions: {
    [key: string]: IEntityPermission[];
  };
}

interface IUserGroupCreateDialog {
  handleClose: () => void;
  handleSuccess: () => void;
}

const UserGroupCreateDialog: React.FC<IUserGroupCreateDialog> = ({
  handleClose,
  handleSuccess,
}) => {
  const onSubmit = async (values: IFormValues) => {
    try {
      const entityPermissions = _.flatten(
        _.values(values.groupedEntityPermissions)
      );
      const valuesToSubmit: ICreateUserGroupPayload = {
        name: values.name,
        entity_permissions: entityPermissions,
      };

      await createUserGroup(valuesToSubmit);

      handleSuccess();
      handleClose();

      notifySuccess(`User group ${values.name} created successfully.`);
    } catch (e: any) {
      notifyApiError(e);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      groupedEntityPermissions: groupPermissionsByEntity(
        getEntityPermissionsPairsList()
      ),
    },
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const { values } = formik;

  const tableHeaders = useMemo(() => {
    // Extracts the permissions from the first entity in the entity permissions object from the form.
    // [ { permission: 'add', ... }, ... ], ...
    const permissionsForFirstEntity = _.values(
      values.groupedEntityPermissions
    )[0];

    // Map the permissions to just the permission action (capitalized)
    return _.map(permissionsForFirstEntity, entityPermission =>
      _.capitalize(entityPermission.permission)
    );
  }, [values.groupedEntityPermissions]);

  return (
    <Dialog disableBackdropClick onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Create user group</DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <form id={FORM_ID} onSubmit={formik.handleSubmit}>
            <Field
              name="name"
              component={FormTextField}
              label="Name"
              fullWidth
              required
              autoFocus
            />

            <Divider sx={{ mt: 2, mb: 2 }}>
              <Typography variant="subtitle1">Permissions</Typography>
            </Divider>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Entity</TableCell>
                    {_.map(tableHeaders, (header: string) => (
                      <TableCell key={header} align="center">
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(
                    formik.values.groupedEntityPermissions,
                    (entityPermissions, key: string) => (
                      <TableRow key={key}>
                        <TableCell component="th" scope="row">
                          {_.capitalize(_.startCase(key))}
                        </TableCell>
                        {_.map(entityPermissions, (_unused, index: number) => (
                          <TableCell key={index} align="center">
                            <Field
                              type="checkbox"
                              name={`groupedEntityPermissions.${key}.${index}.value`}
                              as={Checkbox}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          form={FORM_ID}
          type="submit"
          variant="contained"
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserGroupCreateDialog;
