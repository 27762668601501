import {
  isValid,
  parseISO,
  format,
  formatISO,
  formatDistanceToNow,
} from 'date-fns';

// TODO: Should we generate this in UTC?
export const now = (): Date => new Date();

export const dateTimeIsValid = (datetime: Date): boolean => isValid(datetime);

export const parseDateTime = (timestamp: string): Date | null => {
  const parsed = parseISO(timestamp);

  if (!isValid(parsed)) {
    return null;
  }

  return parsed;
};

export const formatDateTime = (
  datetime: Date,
  dateTimeFormat?: string
): string | null => {
  if (!isValid(datetime)) {
    return null;
  }

  if (dateTimeFormat) {
    return format(datetime, dateTimeFormat);
  }

  return formatISO(datetime);
};

export const formatDate = (datetime: Date): string | null => {
  if (!isValid(datetime)) {
    return null;
  }

  return formatISO(datetime, {
    representation: 'date',
  });
};

export const dateTimeToHumanReadable = (timestamp: string): string => {
  const parsed = parseDateTime(timestamp);

  if (!parsed) {
    return 'N/A';
  }

  return formatDistanceToNow(parsed, { addSuffix: true });
};
