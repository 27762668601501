import React from 'react';

import { styled } from '@mui/material/styles';

import { colors } from 'theme/palette';
import { Box, Typography } from 'components';
import { DoNotDisturbRoundedIcon } from 'icons';

const Icon = styled(DoNotDisturbRoundedIcon)(() => ({
  fontSize: '120px',
  color: colors.red1,
}));

const NoPermission: React.FC = () => (
  <Box
    sx={{
      height: '80vh',
      display: 'flex',
      gap: '30px',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Typography variant="h2">No permission</Typography>
    <Typography variant="h6">
      You don't have permission to access this page.
    </Typography>

    <Icon />
  </Box>
);

export default NoPermission;
