import { BrowserRouter } from 'react-router-dom';

import { SWRConfig } from 'swr';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import theme from 'theme';
import Routes from 'config/routes';
import { get as fetcher } from 'utils/sdk';

function App() {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <SWRConfig value={{ fetcher }}>
            <Routes />

            <ToastContainer theme="colored" />
          </SWRConfig>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
