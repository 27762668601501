import { useFetch, post } from 'utils/sdk';

import { IQuestion } from 'entities/Question/sdk';

export interface ISurveyTemplateListEntity {
  id: number;
  name: string;
  can_update: boolean;
}

export const useSurveyTemplates = () => {
  const { data, error, isLoading, refetch } = useFetch<
    ISurveyTemplateListEntity[]
  >('surveys/templates/', {
    paginate: false,
  });

  return { surveyTemplates: data || [], error, isLoading, refetch };
};

export interface ISurveyTemplateCreateValues {
  name: string;
  questions: {
    title: string;
    type: string;
    required: boolean;
    hint?: string;

    // Number question specific fields
    range?: {
      lower?: number;
      upper?: number;
    };

    // Select question specific fields
    options?: string[];

    // File upload question specific fields
    file_type?: string;
    template_file?: number;
  }[];
}

export interface ISurveyTemplateCreateResponse {
  id: number;
  name: string;
}

export const createSurveyTemplate = (values: ISurveyTemplateCreateValues) =>
  post<ISurveyTemplateCreateResponse>('surveys/templates/create/', values);

export interface ISurveyTemplateDetailEntity {
  id: number;
  name: string;
  can_update: boolean;
  questions: IQuestion[];
}

export const useSurveyTemplate = (id: string) => {
  const { data, error, isLoading, refetch } =
    useFetch<ISurveyTemplateDetailEntity>(
      id ? `surveys/templates/${id}/` : null
    );

  return { surveyTemplate: data, error, isLoading, refetch };
};

export interface ISurveyTemplateUpdateValues {
  name?: string;
  questions?: {
    id?: number;
    title: string;
    type: string;
    hint?: string;
    required: boolean;

    // Number question specific fields
    range?: {
      lower?: number;
      upper?: number;
    };

    // Select question specific fields
    options?: string[];

    // File upload question specific fields
    file_type?: string;
    template_file?: number;
  }[];
}

export const updateSurveyTemplate = (
  id: string,
  data: ISurveyTemplateUpdateValues
) => post(`surveys/templates/${id}/update/`, data);
