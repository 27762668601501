import React, { useMemo } from 'react';

import _ from 'lodash';
import { getIn, useFormikContext } from 'formik';

import { colors } from 'theme/palette';
import { Box, Notes, Typography, Divider } from 'components';

import { useChangeRequestsForAnswer } from 'entities/ChangeRequest/sdk';
import ChangeRequestDetails from 'entities/ChangeRequest/components/Details';

import TaskItemWrapper from 'entities/Task/components/ItemWrapper';
import { QUESTION_TYPE_TO_ANSWER } from 'entities/Question/utils';
import QuestionType from 'entities/Question/components/QuestionType';
import QuestionFileType from 'entities/Question/components/QuestionFileType';

import { IFormValues } from '../';
import AnswerInput from '../AnswerInput';

interface IQuestionDetails {
  index: number;
  answer: IFormValues['answers'][0];
  shouldDisableInput: boolean;
}

const QuestionDetails: React.FC<IQuestionDetails> = ({
  answer,
  index,
  shouldDisableInput,
}) => {
  const { touched, errors } = useFormikContext();

  const { question } = answer;

  const { changeRequests, refetch: refetchChangeRequests } =
    useChangeRequestsForAnswer(answer.id, answer.has_change_requests);

  const { fieldName: answerFieldName } = QUESTION_TYPE_TO_ANSWER[question.type];

  const fieldName = useMemo(
    () => `answers.${index}.${answerFieldName}`,
    [index, answerFieldName]
  );

  const isTouched = useMemo(
    () => getIn(touched, fieldName),
    [touched, fieldName]
  );
  const errorMessage = useMemo(
    () => getIn(errors, fieldName),
    [errors, fieldName]
  );

  const hasError = useMemo(
    () => Boolean(isTouched && errorMessage),
    [isTouched, errorMessage]
  );

  return (
    <TaskItemWrapper sx={hasError && { border: `1px solid ${colors.red1}` }}>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          justifyContent: 'space-between',
          marginBottom: '5px',
        }}
      >
        <Box sx={{ display: 'flex', gap: '5px' }}>
          <Typography variant="h6">
            {index + 1}. {question.title}
          </Typography>

          {question.required && (
            <Typography variant="subtitle1" sx={{ color: 'red' }}>
              *
            </Typography>
          )}
        </Box>

        {_.isNil(question.file_type) ? (
          <QuestionType type={question.type} />
        ) : (
          <QuestionFileType type={question.file_type} />
        )}
      </Box>

      <Notes value={question.hint} />

      <Box mt={1}>
        <AnswerInput
          disabled={shouldDisableInput}
          question={question}
          fieldName={fieldName}
          isTouched={isTouched}
          errorMessage={errorMessage}
        />
      </Box>

      {!_.isEmpty(changeRequests) && (
        <Box mt={1}>
          <Divider>
            <Typography variant="subtitle1">Change requests</Typography>
          </Divider>
          {_.map(changeRequests, request => (
            <ChangeRequestDetails
              key={request.id}
              request={request}
              refetchChangeRequests={refetchChangeRequests}
            />
          ))}
        </Box>
      )}
    </TaskItemWrapper>
  );
};

export default QuestionDetails;
