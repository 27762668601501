import React, { useMemo } from 'react';

import _ from 'lodash';
import { styled } from '@mui/material/styles';

import { colors } from 'theme/palette';
import { Typography, Divider, Box } from 'components';

import { useOrderItemsList } from 'entities/Component/Order/sdk';
import { getTotalPriceRepresentation } from 'entities/Order/utils';

const StyledOrderDetails = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: colors.orange0,
  border: `2px solid ${colors.orange1}`,
  padding: '20px',
}));

interface IOrderDetails {
  orderId: number;
}

const OrderDetails: React.FC<IOrderDetails> = ({ orderId }) => {
  const { orderItems, isLoading, error } = useOrderItemsList(orderId);

  const totalOrderPrice = useMemo(
    () =>
      // Accumulate total order price based on components' price and quantity
      _.reduce(
        orderItems,
        (sum: number, { price, quantity }) =>
          sum + _.toNumber(price.amount) * quantity,
        0
      ),
    [orderItems]
  );

  if (isLoading) {
    return <Typography variant="subtitle2">Loading order items...</Typography>;
  }

  if (error) {
    return <Typography variant="subtitle2">Failed to order items.</Typography>;
  }

  return (
    <StyledOrderDetails>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Typography variant="subtitle1">
          <strong>Order</strong>
        </Typography>

        <Divider flexItem color={colors.orange1} />

        {_.map(orderItems, ({ component, price, quantity }, index: number) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {/* TODO: Allow editing the quantity here */}

              <Typography variant="subtitle2">
                <strong>{component.model}</strong>
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle2">
                  ({quantity} x ${price.amount}){' '}
                  <strong>
                    {getTotalPriceRepresentation(price.amount, quantity)}
                  </strong>
                </Typography>
              </Box>
            </Box>

            <Typography variant="subtitle2">
              {component.category_name}
            </Typography>

            <Divider flexItem color={colors.orange1} />
          </Box>
        ))}
      </Box>

      <Typography variant="subtitle1" align="right" sx={{ marginTop: '10px' }}>
        Total: <strong>${totalOrderPrice.toFixed(2)}</strong>
      </Typography>
    </StyledOrderDetails>
  );
};

export default OrderDetails;
