import { useMemo } from 'react';

import _ from 'lodash';

import { post, useFetch } from 'utils/sdk';

export interface ISignedUser {
  id: number;
  email: string;
  is_active: boolean;
  is_admin: boolean;
  is_superuser: boolean;
  permissions: {
    [key: string]: {
      [key: string]: boolean;
    };
  }[];
}

export interface ILoginPayload {
  email: string;
  password: string;
}

export const login = (data: ILoginPayload) => post('auth/login/', data);

export const useSignedUser = () => {
  const {
    data: user,
    error,
    mutate,
    refetch,
    isLoading,
  } = useFetch<ISignedUser>('auth/me/', null, { revalidateIfStale: false });

  const isAuthenticated = useMemo(
    // From the SWR docs:
    //    Note that data and error can exist at the same time.
    //    So the UI can display the existing data, while knowing the upcoming request has failed.
    //
    // There's a case when the `user` is NOT NULL, but we have an error.
    // This happens because the last successful request returned the user object, but the actually last one returned an error.
    // With checking both the `user` and the `error` objects we make sure that we know the exact state of the user.
    () => !_.isNil(user) && !error,
    [user, error]
  );

  return { user, error, mutate, refetch, isLoading, isAuthenticated };
};
