import React, { useMemo } from 'react';

import _ from 'lodash';
import { styled } from '@mui/material/styles';

import { Paper, Box, Divider, Typography } from 'components';

import { ISurveyQuestion } from 'entities/Survey/sdk';

import { useChangeRequestsForAnswer } from 'entities/ChangeRequest/sdk';
import ChangeRequestDetails from 'entities/ChangeRequest/components/Details';

import QuestionHeader from 'entities/Question/components/Header';
import AnswerReviewInput from 'entities/Question/components/AnswerReviewInput';

const StyledWrapper = styled(Paper)(() => ({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
}));

interface IQuestionDetails {
  question: ISurveyQuestion;
  index: number;
}

const QuestionDetails: React.FC<IQuestionDetails> = ({ question, index }) => {
  const answer = useMemo(() => _.head(question.answers), [question.answers]);

  const hasChangeRequests = useMemo(
    () => Boolean(answer?.has_change_requests),
    [answer]
  );

  const { changeRequests, refetch: refetchChangeRequests } =
    useChangeRequestsForAnswer(answer?.id, hasChangeRequests);

  return (
    <StyledWrapper variant="outlined">
      <QuestionHeader question={question} index={index} />

      <AnswerReviewInput question={question} answer={answer} />

      {hasChangeRequests && (
        <Box>
          <Divider>
            <Typography variant="subtitle1">Change requests</Typography>
          </Divider>

          {_.map(changeRequests, request => (
            <ChangeRequestDetails
              key={request.id}
              request={request}
              refetchChangeRequests={refetchChangeRequests}
              disableAddComment
            />
          ))}
        </Box>
      )}
    </StyledWrapper>
  );
};

export default QuestionDetails;
