export const BASE_BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL;
export const BASE_API_URL = `${BASE_BACKEND_URL}/api`;

export const RESOURCES = {
  // Workflows
  TASKS: 'To Do',
  WORKFLOWS: 'Workflows',

  // Users
  USERS: 'Users',
  USER_GROUPS: 'User Groups',

  // Components
  COMPONENTS: 'Components',

  // Templates
  SURVEY_TEMPLATES: 'Survey Templates',
  WORKFLOW_TEMPLATES: 'Workflow Templates',
  WORKFLOW_TEMPLATE_GROUPS: 'Workflow Template Groups',
  TASK_TEMPLATES: 'Task Templates',
};
