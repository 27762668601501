import React, { useMemo, useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { AddFab, BooleanIcon, Protected, Typography } from 'components';
import DataGrid, {
  transformToColumns,
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
} from 'components/DataGrid';

import { EditRoundedIcon } from 'icons';
import { useDialogWithQueryParam } from 'utils/hooks';

import { useSignedUser } from 'entities/Auth/sdk';

import { IUserListEntity, useAllUsers } from 'entities/User/sdk';
import UserEditDialog from 'entities/User/components/EditDialog';
import UserCreateDialog from 'entities/User/components/CreateDialog';

import {
  ENTITY_PERMISSIONS,
  PERMISSION_ENTITIES,
} from 'entities/EntityPermission/sdk';

const EDIT_QUERY_PARAM = 'edit';

const columnsFields: string[] = ['user', 'admin', 'actions'];

const UsersList: React.FC = () => {
  const { user: signedUser, refetch: refetchSignedUser } = useSignedUser();
  const { users, error, isLoading, refetch: refetchAllUsers } = useAllUsers();

  const [searchParams] = useSearchParams();

  const {
    isOpen: isCreateDialogOpen,
    open: openCreateDialog,
    close: closeCreateDialog,
  } = useDialogWithQueryParam('create');

  const [editUserId, setEditUserId] = useState<string>(
    searchParams.get(EDIT_QUERY_PARAM)
  );

  const {
    isOpen: isEditDialogOpen,
    open: openEditDialog,
    close: closeEditDialog,
  } = useDialogWithQueryParam(EDIT_QUERY_PARAM, editUserId);

  useEffect(() => {
    // Open/close the edit dialog based on the state variable, which can be either preset from the query params or manually set from the table edit handler.
    if (!_.isEmpty(editUserId)) {
      openEditDialog();
    } else {
      closeEditDialog();
    }
  }, [editUserId, openEditDialog, closeEditDialog]);

  const handleUserEditSuccess = useCallback(() => {
    // TODO: Refetch users once we start making changes to the user properties listed in the table.
    // refetchUsers();

    const signedUserId = _.get(signedUser, 'id');

    if (_.isEqual(editUserId, signedUserId)) {
      // We need to refetch the user in order to update the permissions
      refetchSignedUser();
    }
  }, [editUserId, signedUser, refetchSignedUser]);

  const rows = useMemo(
    () =>
      _.map(users, (user: IUserListEntity) => {
        const { id, email, is_admin } = user;

        return {
          id,
          user: email,
          admin: is_admin,
        };
      }),
    [users]
  );

  const columns: GridColDef[] = useMemo(
    () =>
      transformToColumns(columnsFields).map((col: GridColDef) => {
        if (col.field === 'admin') {
          return {
            ...col,
            type: 'boolean',
            maxWidth: 150,
            renderCell: ({ row }) => <BooleanIcon value={row.admin} />,
          };
        }

        if (col.field === 'actions') {
          return {
            ...col,
            type: 'actions',
            headerName: '',
            maxWidth: 50,
            getActions: (params: GridRowParams) => [
              <GridActionsCellItem
                icon={<EditRoundedIcon />}
                onClick={() => setEditUserId(params.id.toString())}
                label="Edit"
                showInMenu
              />,
            ],
          };
        }

        return col;
      }),
    []
  );

  if (error) {
    return <Typography variant="subtitle2">Failed to fetch users</Typography>;
  }

  return (
    <>
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows}
        disableSelectionOnClick
      />

      {isEditDialogOpen && !_.isNil(editUserId) && (
        <UserEditDialog
          userId={editUserId}
          handleClose={() => setEditUserId(null)}
          handleSuccess={handleUserEditSuccess}
        />
      )}

      <Protected
        entity={PERMISSION_ENTITIES.USERS}
        permission={ENTITY_PERMISSIONS.ADD}
      >
        <AddFab onClick={openCreateDialog} />
        {isCreateDialogOpen && (
          <UserCreateDialog
            handleClose={closeCreateDialog}
            handleSuccess={refetchAllUsers}
          />
        )}
      </Protected>
    </>
  );
};

export default UsersList;
