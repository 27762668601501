import React, { useMemo, useCallback } from 'react';

import _ from 'lodash';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MultiSelect,
} from 'components';
import { Field, FormikProvider, FormikValues, useFormik } from 'formik';
import { notifyApiError, notifySuccess } from 'utils/notifications';
import {
  addWorkflowComponents,
  useWorkflowOfferedComponents,
} from 'entities/Workflow/sdk';
import { styled } from '@mui/material/styles';
import { IComponentListEntity, useComponentList } from 'entities/Component/sdk';

const StyledForm = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
}));

interface IFormValues extends FormikValues {
  components: number[];
}

interface IWorkflowAddComponentsDialog {
  workflowId: string;
  handleSuccess: () => void;
  handleClose: () => void;
}

const FORM_ID = 'workflow-add-components';

const COMPONENT_FIELD_MAPPING = {
  key: 'id',
  label: 'fullName',
};

const WorkflowAddComponentsDialog: React.FC<IWorkflowAddComponentsDialog> = ({
  workflowId,
  handleSuccess,
  handleClose,
}) => {
  const { components: allComponents, isLoading: isLoadingAllComponents } =
    useComponentList();

  const { offeredComponents, isLoading: isLoadingOfferedComponents } =
    useWorkflowOfferedComponents(_.toNumber(workflowId));

  const isLoading = useMemo(
    () => isLoadingAllComponents || isLoadingOfferedComponents,
    [isLoadingAllComponents, isLoadingOfferedComponents]
  );

  const filteredComponents = useMemo(
    () =>
      _(allComponents)
        // Exclude components without prices
        .filter((el: IComponentListEntity) => !_.isNil(el.latest_price))
        // Exclude components that are already added
        .filter(({ id }) => !_.includes(_.map(offeredComponents, 'id'), id))
        .map(({ id, model, manufacturer, category }) => {
          let fullName = `${manufacturer.name} - ${model}`;

          if (category) {
            fullName = `${fullName} (${category.name})`;
          }

          return {
            id,
            fullName,
          };
        })
        .value(),
    [allComponents, offeredComponents]
  );

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      try {
        await addWorkflowComponents(workflowId, values);
        notifySuccess('Components added to workflow successfully.');
        handleSuccess();
        handleClose();
      } catch (e: any) {
        notifyApiError(e);
      }
    },
    [workflowId, handleClose, handleSuccess]
  );

  const formik = useFormik<IFormValues>({
    initialValues: { components: [] },
    onSubmit,
  });

  return (
    <Dialog disableBackdropClick onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Add components</DialogTitle>
      <DialogContent>
        <form id={FORM_ID} onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <StyledForm>
              <Field
                disabled={isLoading || _.isEmpty(filteredComponents)}
                name="components"
                component={MultiSelect}
                label="Components"
                options={filteredComponents}
                fieldMapping={COMPONENT_FIELD_MAPPING}
              />
            </StyledForm>
          </FormikProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
          // We need to tell the button the form ID to submit,
          // since the <form> itself is in a different scope (in the DialogContent).
          form={FORM_ID}
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkflowAddComponentsDialog;
