import React from 'react';

import _ from 'lodash';

import { Box, Typography, Notes } from 'components';

import { IQuestion } from 'entities/Question/sdk';

import QuestionType from '../QuestionType';
import QuestionFileType from '../QuestionFileType';

interface IQuestionHeader {
  question: IQuestion;
  index: number;
}

const QuestionHeader: React.FC<IQuestionHeader> = ({ question, index }) => (
  <div>
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        justifyContent: 'space-between',
        marginBottom: '5px',
      }}
    >
      <Box sx={{ display: 'flex', gap: '5px' }}>
        <Typography variant="h6">
          {index + 1}. {question.title}
        </Typography>

        {question.required && (
          <Typography variant="subtitle1" sx={{ color: 'red' }}>
            *
          </Typography>
        )}
      </Box>

      <Box sx={{ display: 'flex', gap: '5px' }}>
        {_.isNil(question.file_type) ? (
          <QuestionType type={question.type} />
        ) : (
          <QuestionFileType type={question.file_type} />
        )}
      </Box>
    </Box>

    <Notes value={question.hint} />
  </div>
);

export default QuestionHeader;
