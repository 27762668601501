import React from 'react';

import { Box, Typography } from 'components';
import {
  ImageRoundedIcon,
  PictureAsPdfRoundedIcon,
  BorderAllRoundedIcon,
  ArticleRoundedIcon,
} from 'icons';

import { QUESTION_FILE_TYPES } from 'entities/Question/sdk';

export const QUESTION_FILE_TYPE_TO_DISPLAY = {
  [QUESTION_FILE_TYPES.IMAGE]: {
    alias: 'Image',
    icon: <ImageRoundedIcon />,
  },
  [QUESTION_FILE_TYPES.PDF_FILE]: {
    alias: 'PDF file',
    icon: <PictureAsPdfRoundedIcon />,
  },
  [QUESTION_FILE_TYPES.EXCEL_SPREADSHEET]: {
    alias: 'Excel spreadsheet',
    icon: <BorderAllRoundedIcon />,
  },
  [QUESTION_FILE_TYPES.WORD_DOCUMENT]: {
    alias: 'Word document',
    icon: <ArticleRoundedIcon />,
  },
};

interface IQuestionFileType {
  type: string;
}

const QuestionFileType: React.FC<IQuestionFileType> = ({ type }) => {
  const { icon, alias } = QUESTION_FILE_TYPE_TO_DISPLAY[type];

  return (
    <Box
      sx={{
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
      }}
    >
      {icon}
      <Typography variant="subtitle1">{alias}</Typography>
    </Box>
  );
};

export default QuestionFileType;
