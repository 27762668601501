import React, { useEffect, useMemo } from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import {
  useFormik,
  Field,
  FormikProvider,
  FieldArray,
  FormikValues,
} from 'formik';

import { styled } from '@mui/material/styles';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormTextField,
  Button,
  Typography,
  MultiSelect,
  Divider,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Box,
  Notes,
} from 'components';

import { notifySuccess, notifyApiError } from 'utils/notifications';

import { ITaskTemplateListEntity } from 'entities/TaskTemplate/sdk';
import {
  IRoleTemplate,
  useAvailableRoleTemplates,
} from 'entities/WorkflowRole/sdk';
import {
  IWorkflowTemplateUpdatePayload,
  updateWorkflowTemplate,
  useWorkflowTemplate,
} from 'entities/WorkflowTemplate/sdk';

import TaskTemplatesFieldArray from 'entities/TaskTemplate/components/TaskTemplatesFieldArray';

const ROLE_SELECT_FIELD_MAPPING = {
  key: 'id',
  label: 'name',
};

const StyledForm = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
}));

interface IFormValues extends FormikValues {
  name: string;
  single_user_workflow: boolean;
  task_templates: ITaskTemplateListEntity[];
  main_role: number;
  role_templates_with_read_access: number[];
  role_templates_with_write_access: number[];
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  main_role: yup.number().required('Please select a main role'),
  task_templates: yup
    .array()
    .of(yup.object({ id: yup.number(), name: yup.string() }))
    .min(1, 'Please use at least 1 task template')
    .required(),
});

const FORM_ID = 'workflow-template-edit';

interface IWorkflowTemplateEditDialog {
  workflowTemplateId: string;
  handleClose: () => void;
  handleSuccess: () => void;
}

const WorkflowTemplateEditDialog: React.FC<IWorkflowTemplateEditDialog> = ({
  workflowTemplateId,
  handleClose,
  handleSuccess,
}) => {
  const {
    workflowTemplate,
    isLoading: isLoadingWorkflowTemplate,
    error,
  } = useWorkflowTemplate(workflowTemplateId);

  const { availableRoleTemplates } = useAvailableRoleTemplates();

  const onSubmit = async (values: IFormValues) => {
    try {
      let valuesToSubmit: IWorkflowTemplateUpdatePayload = {
        name: values.name,
      };

      if (workflowTemplate.can_update) {
        valuesToSubmit = {
          ...values,
          task_templates: _.map(values.task_templates, 'id'),
        };
      }

      await updateWorkflowTemplate(workflowTemplateId, valuesToSubmit);

      notifySuccess('Workflow template updated successfully.');
      handleSuccess();
      handleClose();
    } catch (e: any) {
      notifyApiError(e);
    }
  };

  const formik = useFormik<IFormValues>({
    initialValues: {
      name: _.get(workflowTemplate, 'name', ''),
      single_user_workflow: _.get(
        workflowTemplate,
        'single_user_workflow',
        false
      ),
      // @ts-ignore
      main_role: _.get(workflowTemplate, 'main_role.id', ''),
      role_templates_with_read_access: _.map(
        _.get(workflowTemplate, 'role_templates_with_read_access', []),
        'id'
      ),
      role_templates_with_write_access: _.map(
        _.get(workflowTemplate, 'role_templates_with_write_access', []),
        'id'
      ),
      task_templates: _.get(workflowTemplate, 'task_templates', []),
    },
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const { setFieldValue } = formik;
  const { main_role: mainRoleId, task_templates: selectedTaskTemplates } =
    formik.values;

  const selectedRoleTemplates = useMemo(
    () =>
      // Consolidate the role templates from the selected task templates executor & reviewer roles
      _(selectedTaskTemplates)
        .flatMap((taskTemplate: ITaskTemplateListEntity) => [
          taskTemplate.executor_role,
          taskTemplate.reviewer_role,
        ])
        // We might have duplicated roles, so strip them out.
        .uniqBy('id')
        .value(),
    [selectedTaskTemplates]
  );

  const roleTemplatesExceptSelected = useMemo(
    // Role templates with read/write access are all roles that are different from the selected ones.
    () => _.xorBy(availableRoleTemplates, selectedRoleTemplates, 'id'),
    [availableRoleTemplates, selectedRoleTemplates]
  );

  useEffect(() => {
    /*
      This effect clears the previously selected main role if
      this role is no longer present in the available roles
    */

    const selectedRoleTemplatesIds = _.map(selectedRoleTemplates, 'id');

    if (!_.includes(selectedRoleTemplatesIds, mainRoleId)) {
      setFieldValue('main_role', '');
    }
  }, [setFieldValue, selectedRoleTemplates, mainRoleId]);

  if (isLoadingWorkflowTemplate || error) {
    return null;
  }

  return (
    <Dialog maxWidth="md" disableBackdropClick onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Edit workflow template</DialogTitle>
      <DialogContent>
        <form id={FORM_ID} onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <StyledForm>
              <Field
                name="name"
                component={FormTextField}
                required
                fullWidth
                autoFocus
                label="Name"
              />

              {!workflowTemplate.can_update && (
                <Box sx={{ mt: 1, mb: 1 }}>
                  <Notes value="Tasks & roles cannot be modified as this template is being used in a workflow." />
                </Box>
              )}

              <FormControlLabel
                label="Single user workflow"
                disabled={!workflowTemplate.can_update}
                control={
                  <Field
                    type="checkbox"
                    name="single_user_workflow"
                    as={Checkbox}
                  />
                }
              />

              <>
                <Divider>
                  <Typography variant="subtitle1">Task templates</Typography>
                </Divider>
                <FieldArray
                  name="task_templates"
                  // @ts-ignore
                  render={props => (
                    <TaskTemplatesFieldArray
                      {...props}
                      modifiable={workflowTemplate.can_update}
                    />
                  )}
                />
              </>

              <>
                <Divider>
                  <Typography variant="subtitle1">Roles</Typography>
                </Divider>

                <StyledForm>
                  <Field
                    name="main_role"
                    component={FormTextField}
                    select
                    required
                    fullWidth
                    disabled={
                      _.isEmpty(selectedRoleTemplates) ||
                      !workflowTemplate.can_update
                    }
                    label="Main role"
                  >
                    {selectedRoleTemplates.map((template: IRoleTemplate) => (
                      <MenuItem key={template.id} value={template.id}>
                        {template.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    name="role_templates_with_read_access"
                    disabled={!workflowTemplate.can_update}
                    component={MultiSelect}
                    label="Roles with read access"
                    options={roleTemplatesExceptSelected}
                    fieldMapping={ROLE_SELECT_FIELD_MAPPING}
                  />
                  <Field
                    name="role_templates_with_write_access"
                    disabled={!workflowTemplate.can_update}
                    component={MultiSelect}
                    label="Roles with write access"
                    options={roleTemplatesExceptSelected}
                    fieldMapping={ROLE_SELECT_FIELD_MAPPING}
                  />
                </StyledForm>
              </>
            </StyledForm>
          </FormikProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
          // We need to tell the button the form ID to submit,
          // since the <form> itself is in a different scope (in the DialogContent).
          form={FORM_ID}
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkflowTemplateEditDialog;
