import React from 'react';

import { styled } from '@mui/material/styles';

import { colors } from 'theme/palette';
import { parseDateTime, formatDate, dateTimeToHumanReadable } from 'utils/date';
import { Box, Paper, Typography, LinearProgress } from 'components';

import { IWorkflowDetailEntity } from 'entities/Workflow/sdk';

const StyledInfo = styled(Paper)(() => ({
  padding: '15px',
  width: '100%',
}));

interface IWorkflowGeneralInfo {
  workflow: IWorkflowDetailEntity;
}

const WorkflowGeneralInfo: React.FC<IWorkflowGeneralInfo> = ({ workflow }) => (
  <StyledInfo>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="h6">{workflow.name}</Typography>
      <Box sx={{ width: '30%' }}>
        <LinearProgress value={workflow.progress * 100} />
      </Box>
    </Box>

    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="subtitle2" color={colors.grey4}>
        Created {dateTimeToHumanReadable(workflow.created_at)} (
        {formatDate(parseDateTime(workflow.created_at))})
      </Typography>

      {workflow.completed_at && (
        <Typography variant="subtitle2" color={colors.grey4}>
          Completed {dateTimeToHumanReadable(workflow.completed_at)} (
          {formatDate(parseDateTime(workflow.completed_at))})
        </Typography>
      )}
    </Box>
  </StyledInfo>
);

export default WorkflowGeneralInfo;
