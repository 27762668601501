import React, { useMemo } from 'react';

import _ from 'lodash';
import { styled } from '@mui/material/styles';

import { IWorkflowTask } from 'entities/Workflow/sdk';
import { useSurveyQuestionsList } from 'entities/Survey/sdk';

import QuestionDetails from './QuestionDetails';

const StyledWrapper = styled('div')(() => ({
  display: 'flex',
  gap: '20px',
  flexDirection: 'column',
}));

interface ISurveyTaskDetails {
  task: IWorkflowTask;
}

const SurveyTaskDetails: React.FC<ISurveyTaskDetails> = ({ task }) => {
  const surveyId = useMemo(() => _.get(task, 'survey_id'), [task]);

  const { questions, isLoading: isLoadingQuestions } =
    useSurveyQuestionsList(surveyId);

  if (isLoadingQuestions) {
    return null;
  }

  return (
    <StyledWrapper>
      {_.map(questions, (question, index) => (
        <QuestionDetails key={index} question={question} index={index} />
      ))}
    </StyledWrapper>
  );
};

export default SurveyTaskDetails;
