import React from 'react';

import { styled } from '@mui/material/styles';

import { Draggable } from 'react-beautiful-dnd';

import { colors } from 'theme/palette';
import { CloseRoundedIcon } from 'icons';
import { Paper, IconButton } from 'components';

import { ITaskTemplateListEntity } from 'entities/TaskTemplate/sdk';
import TaskTemplateTitle from 'entities/TaskTemplate/components/TaskTemplateTitle';

interface IUsedTaskTemplate {
  index: number;
  taskTemplate: ITaskTemplateListEntity;
  handleRemove: () => void;
  modifiable: boolean;
}

interface IStyledUsedTaskTemplate {
  isDragging: boolean;
}

const StyledUsedTaskTemplate = styled(Paper, {
  shouldForwardProp: prop => prop !== 'isDragging',
})<IStyledUsedTaskTemplate>(({ isDragging }) => ({
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: isDragging ? colors.grey4 : colors.grey3,
}));

const UsedTaskTemplate: React.FC<IUsedTaskTemplate> = ({
  index,
  taskTemplate,
  handleRemove,
  modifiable,
}) => (
  <Draggable
    draggableId={`${index}`}
    index={index}
    isDragDisabled={!modifiable}
  >
    {(provided, snapshot) => (
      <StyledUsedTaskTemplate
        ref={provided.innerRef}
        isDragging={snapshot.isDragging}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <TaskTemplateTitle taskTemplate={taskTemplate} />
        {modifiable && (
          <IconButton onClick={handleRemove}>
            <CloseRoundedIcon />
          </IconButton>
        )}
      </StyledUsedTaskTemplate>
    )}
  </Draggable>
);

export default UsedTaskTemplate;
