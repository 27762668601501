import React from 'react';

import { Outlet } from 'react-router-dom';

import { Box, Navigation, Sidebar, Toolbar, drawerWidth } from 'components';

const AuthLayout: React.FC = () => (
  <Box>
    <Navigation />
    <Sidebar />

    <Box component="main" sx={{ marginLeft: drawerWidth, padding: 2 }}>
      <Toolbar />
      <Outlet />
    </Box>
  </Box>
);

export default AuthLayout;
