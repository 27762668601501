import queryString, { UrlObject, ParsedUrl } from 'query-string';

// https://www.npmjs.com/package/query-string#:~:text=.-,stringifyUrl,-(object%2C%20options%3F)
export const stringifyUrl = (url: UrlObject): string =>
  // From: { url: 'https://api.com/', query: { param: 'value', arrayParam: [1, 2] } }
  // To:   'https://api.com?param=value&arrayParam=1,2'
  queryString.stringifyUrl(url, { arrayFormat: 'comma' });

// https://www.npmjs.com/package/query-string#:~:text=.-,parseUrl,-(string%2C%20options%3F)
export const parseUrl = (url: string): ParsedUrl =>
  // From: 'https://api.com?param=value&arrayParam=1,2'
  // To:   { url: 'https://api.com/', query: { param: 'value', arrayParam: [1, 2] } }
  queryString.parseUrl(url, { arrayFormat: 'comma' });

export const buildDetailUrl = (
  detailUrl: string,
  identifier: string | number,
  key: string = 'id'
): string => detailUrl.replace(`:${key}`, identifier.toString());
