import _ from 'lodash';
import { toast } from 'react-toastify';

import { IAPIException } from 'utils/sdk';

export const notifySuccess = (message: string) => toast.success(message);
export const notifyError = (message: string) => toast.error(message);

export const notifyApiError = (error: IAPIException) => {
  const data = _.get(error, 'data');

  // This mainly occurs when we have an unhandled exception on the back-end (e.g. 500)
  if (!data) {
    notifyError('Unexpected error.');
    return;
  }

  const { message, extra } = data;

  const extraFields = _.get(extra, 'fields');

  if (!extraFields) {
    notifyError(message);
    return;
  }

  const notifyForField = (
    field: string,
    errors: string[] | { [field: string]: string[] }[]
  ) => {
    /*
     Errors can be either:

     ["This field may not be blank"]

     OR

     [{ fieldName: ["This field may not be blank"] }]
    */
    for (const error of errors) {
      if (_.isString(error)) {
        notifyError(`${_.capitalize(field)}: ${error}`);
        return;
      }

      // Recursively notify for errors down the chain by appending the main field
      for (const [innerField, innerError] of _.entries(error)) {
        notifyForField(`${field} ${innerField}`, innerError);
      }
    }
  };

  for (const [field, errors] of _.entries(extraFields)) {
    notifyForField(field, errors as []);
  }
};
