import React, { useMemo } from 'react';
import MuiLinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';

import { Typography, Box } from 'components';

const LinearProgress: React.FC<LinearProgressProps & { value: number }> = ({
  value,
  ...props
}) => {
  const color = useMemo(() => (value === 100 ? 'success' : 'primary'), [value]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <MuiLinearProgress
          color={color}
          variant="determinate"
          // Fill the bar a little bit if percentage is less than 2
          value={value < 2 ? 2 : value}
          sx={{ height: 5 }}
          {...props}
        />
      </Box>

      <Typography
        variant="subtitle2"
        lineHeight="normal"
      >{`${value}%`}</Typography>
    </Box>
  );
};

export default LinearProgress;
