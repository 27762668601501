import React, { useMemo } from 'react';

import _ from 'lodash';

import { Box, Typography, Divider } from 'components';

import { useChangeRequestsForAnswer } from 'entities/ChangeRequest/sdk';
import ChangeRequestDetails from 'entities/ChangeRequest/components/Details';
import CreateChangeRequestForm from 'entities/ChangeRequest/components/CreateForm';

import { ISurveyQuestion } from 'entities/Survey/sdk';
import TaskItemWrapper from 'entities/Task/components/ItemWrapper';
import QuestionHeader from 'entities/Question/components/Header';
import AnswerReviewInput from 'entities/Question/components/AnswerReviewInput';

interface IQuestionDetails {
  question: ISurveyQuestion;
  index: number;
}

const QuestionDetails: React.FC<IQuestionDetails> = ({ question, index }) => {
  const answer = useMemo(() => _.head(question.answers), [question.answers]);

  const { changeRequests, refetch: refetchChangeRequests } =
    useChangeRequestsForAnswer(
      answer?.id,
      Boolean(answer?.has_change_requests)
    );

  return (
    <TaskItemWrapper>
      <QuestionHeader question={question} index={index} />

      <Box mt={1}>
        <AnswerReviewInput question={question} answer={answer} />
      </Box>

      <Box mt={1}>
        <Divider>
          <Typography variant="subtitle1">Change requests</Typography>
        </Divider>

        {_.map(changeRequests, request => (
          <ChangeRequestDetails
            key={request.id}
            request={request}
            refetchChangeRequests={refetchChangeRequests}
          />
        ))}

        {!_.isEmpty(changeRequests) && <Divider sx={{ margin: '10px 0' }} />}

        <CreateChangeRequestForm fieldName={`changeRequests.${index}`} />
      </Box>
    </TaskItemWrapper>
  );
};

export default QuestionDetails;
