import React from 'react';

import { styled } from '@mui/material/styles';
import { Draggable } from 'react-beautiful-dnd';

import { colors } from 'theme/palette';
import { CloseRoundedIcon } from 'icons';
import { Paper, IconButton } from 'components';

import WorkflowTemplateTitle from '../Title';

interface IUsedWorkflowTemplate {
  index: number;
  workflowTemplate: {
    id: number;
    name: string;
  };
  handleRemove: () => void;
  modifiable: boolean;
}

interface IStyledUsedWorkflowTemplate {
  isDragging: boolean;
}

const StyledUsedWorkflowTemplate = styled(Paper, {
  shouldForwardProp: prop => prop !== 'isDragging',
})<IStyledUsedWorkflowTemplate>(({ isDragging }) => ({
  padding: '10px',
  minHeight: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: isDragging ? colors.grey4 : colors.grey3,
}));

const UsedWorkflowTemplate: React.FC<IUsedWorkflowTemplate> = ({
  index,
  workflowTemplate,
  handleRemove,
  modifiable,
}) => (
  <Draggable
    draggableId={`${index}`}
    index={index}
    isDragDisabled={!modifiable}
  >
    {(provided, snapshot) => (
      <StyledUsedWorkflowTemplate
        ref={provided.innerRef}
        isDragging={snapshot.isDragging}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <WorkflowTemplateTitle workflowTemplate={workflowTemplate} />
        {modifiable && (
          <IconButton onClick={handleRemove}>
            <CloseRoundedIcon />
          </IconButton>
        )}
      </StyledUsedWorkflowTemplate>
    )}
  </Draggable>
);

export default UsedWorkflowTemplate;
