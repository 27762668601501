import React, { useMemo } from 'react';

import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { Paper, Divider, Typography, DownloadableFile } from 'components';
import DataGrid, { transformToColumns, GridColDef } from 'components/DataGrid';

import { parseDateTime, formatDate } from 'utils/date';

import { useWorkflowDocumentsList } from 'entities/Workflow/sdk';

const columnsFields: string[] = [
  'file',
  'uploadDate',
  'executor',
  'task',
  'question',
  'reviewer',
  'reviewDate',
];

const columns: GridColDef[] = transformToColumns(columnsFields).map(column => {
  const { field } = column;

  if (field === 'file') {
    return {
      ...column,
      valueGetter: ({ value }) => value.original_file_name,
      renderCell: ({ row: { file } }) => (
        <DownloadableFile url={file.url} name={file.original_file_name} />
      ),
    };
  }

  return column;
});

const StyledWrapper = styled(Paper)(() => ({
  padding: '15px',
  width: '100%',
}));

const WorkflowDocuments: React.FC = () => {
  const { id: workflowId } = useParams();

  const { documents, isLoading, error } = useWorkflowDocumentsList(workflowId);

  const rows = useMemo(
    () =>
      _.map(documents, doc => {
        const { id, task, question, user, file, created_at } = doc;

        const uploadDate = formatDate(parseDateTime(created_at));
        const reviewerEmail = _.get(
          task.reviewer,
          'email',
          task.reviewer_role.name
        );
        const reviewDate = formatDate(parseDateTime(task.reviewed_at)) || 'N/A';

        return {
          id,
          file,
          uploadDate,
          executor: user.email,
          task: task.template_name,
          question: question.title,
          reviewer: reviewerEmail,
          reviewDate,
        };
      }),
    [documents]
  );

  if (error) {
    return (
      <Typography variant="subtitle2">Failed to load documents.</Typography>
    );
  }

  if (_.isEmpty(documents)) {
    return null;
  }

  return (
    <StyledWrapper>
      <Divider>
        <Typography variant="h6" align="center">
          Documents
        </Typography>
      </Divider>

      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows}
        disableSelectionOnClick
      />
    </StyledWrapper>
  );
};

export default WorkflowDocuments;
