import React from 'react';

import Button, { ButtonProps } from 'components/Button';
import { AddRoundedIcon } from 'icons';

const AddButton: React.FC<ButtonProps> = ({ children, ...props }) => (
  <Button startIcon={<AddRoundedIcon />} {...props}>
    {children}
  </Button>
);

export default AddButton;
