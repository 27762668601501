import React, { useMemo, useCallback } from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useFormik, FieldArray, FormikProvider } from 'formik';

import { colors } from 'theme/palette';
import { TASKS_LIST_URL } from 'config/urls';
import { notifySuccess, notifyApiError } from 'utils/notifications';

import { Typography, Divider, Box, Button } from 'components';

import { useChangeRequestsForOrder } from 'entities/ChangeRequest/sdk';
import ChangeRequestDetails from 'entities/ChangeRequest/components/Details';

import { useOrderItemsList, IOrderItem } from 'entities/Component/Order/sdk';

import { completeTask, ITaskDetailEntity } from 'entities/Task/sdk';
import TaskContentWrapper from 'entities/Task/components/ContentWrapper';
import TaskItemWrapper from 'entities/Task/components/ItemWrapper';

import {
  IWorkflowOfferedComponent,
  useWorkflowOfferedComponents,
} from 'entities/Workflow/sdk';

import ComponentSelect from '../../components/ComponentSelect';
import ComponentsToOrder from '../../components/ComponentsToOrder';

const FORM_ID = 'order-form-id';

const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: colors.orange0,
  border: `2px solid ${colors.orange1}`,
  padding: '20px',
  marginTop: '10px',
}));

interface IComponentToOrder
  extends Omit<IWorkflowOfferedComponent, 'latest_price'> {
  price: {
    id: number;
    amount: string;
  };
  quantity: number;
}

const validationSchema = yup.object({
  componentsToOrder: yup.array().min(1),
});

interface IFormValues {
  componentsToOrder: IComponentToOrder[];
}

interface ISubmitOrderForm {
  task: ITaskDetailEntity;
}

const SubmitOrderForm: React.FC<ISubmitOrderForm> = ({ task }) => {
  const navigate = useNavigate();

  const orderId = useMemo(() => _.get(task, 'order_id'), [task]);

  const { offeredComponents, isLoading, error } = useWorkflowOfferedComponents(
    task.workflow.id
  );

  const { orderItems } = useOrderItemsList(orderId);

  const { changeRequests, refetch: refetchChangeRequests } =
    useChangeRequestsForOrder(orderId);

  const onSubmit = useCallback(
    async (values: IFormValues) => {
      try {
        const valuesToSubmit = {
          order_items: _.map(values.componentsToOrder, cmp => ({
            component: cmp.id,
            price: cmp.price.id,
            quantity: cmp.quantity,
          })),
        };

        await completeTask(task.id, valuesToSubmit);

        notifySuccess('Task completed.');
        navigate(TASKS_LIST_URL);
      } catch (e: any) {
        notifyApiError(e);
      }
    },
    [task.id, navigate]
  );

  const formik = useFormik<IFormValues>({
    initialValues: {
      componentsToOrder: _.map(orderItems, (item: IOrderItem) => ({
        ...item.component,
        price: item.price,
        quantity: item.quantity,
      })),
    },
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const { values, setFieldValue } = formik;

  const totalOrderPrice = useMemo(
    () =>
      // Accumulate total order price based on components' price and quantity
      _.reduce(
        values.componentsToOrder,
        (sum: number, component) =>
          sum + _.toNumber(component.price.amount) * component.quantity,
        0
      ),
    [values.componentsToOrder]
  );

  const addComponentToOrder = useCallback(
    (component: IWorkflowOfferedComponent, quantity: number) => {
      const existingComponentIdx = _.findIndex(values.componentsToOrder, {
        id: component.id,
      });

      const componentToAdd = {
        ..._.omit(component, 'latest_price'),
        price: component.latest_price,
      };

      if (existingComponentIdx !== -1) {
        // Component exists -> update quantity

        const newOrder = [...values.componentsToOrder];
        newOrder[existingComponentIdx] = {
          ...componentToAdd,
          quantity: newOrder[existingComponentIdx].quantity + quantity,
        };

        setFieldValue('componentsToOrder', newOrder);
      } else {
        // New component -> add to order
        setFieldValue('componentsToOrder', [
          ...values.componentsToOrder,
          { ...componentToAdd, quantity },
        ]);
      }
    },
    [setFieldValue, values.componentsToOrder]
  );

  if (isLoading) {
    return <Typography variant="subtitle2">Loading task...</Typography>;
  }
  if (error) {
    return <Typography variant="subtitle2">Failed to fetch task</Typography>;
  }

  if (_.isEmpty(offeredComponents)) {
    return (
      <Typography variant="h6">
        Please wait for components to be added to the workflow.
      </Typography>
    );
  }

  return (
    <>
      <TaskContentWrapper>
        <TaskItemWrapper>
          <ComponentSelect
            offeredComponents={offeredComponents}
            onAdd={addComponentToOrder}
          />

          <StyledForm id={FORM_ID} onSubmit={formik.handleSubmit}>
            <FormikProvider value={formik}>
              <Box
                sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
              >
                <Typography variant="subtitle1">
                  <strong>Current Order</strong>
                </Typography>

                <Divider flexItem color={colors.orange1} />

                <FieldArray
                  name="componentsToOrder"
                  // @ts-ignore
                  component={ComponentsToOrder}
                />
              </Box>

              <Typography
                variant="subtitle1"
                align="right"
                sx={{
                  marginTop: '10px',
                  // 36px is the <IconButton> dimension
                  // 10px is the gap between the component price & the button
                  marginRight: `${10 + 36}px`,
                }}
              >
                Total: <strong>${totalOrderPrice.toFixed(2)}</strong>
              </Typography>
            </FormikProvider>
          </StyledForm>

          {!_.isEmpty(changeRequests) && (
            <Box mt={1}>
              <Divider>
                <Typography variant="subtitle1">Change requests</Typography>
              </Divider>
              {_.map(changeRequests, request => (
                <ChangeRequestDetails
                  key={request.id}
                  request={request}
                  refetchChangeRequests={refetchChangeRequests}
                />
              ))}
            </Box>
          )}
        </TaskItemWrapper>
      </TaskContentWrapper>

      <Button
        form={FORM_ID}
        type="submit"
        variant="contained"
        disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
        sx={{ marginTop: '10px' }}
      >
        Place Order
      </Button>
    </>
  );
};

export default SubmitOrderForm;
