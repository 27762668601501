import React from 'react';

import { styled } from '@mui/material/styles';

import { AddRoundedIcon } from 'icons';
import { colors } from 'theme/palette';
import { Paper, IconButton } from 'components';

import WorkflowTemplateTitle from '../Title';

const StyledAvailableWorkflowTemplate = styled(Paper)(() => ({
  padding: '10px',
  minHeight: '50px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: colors.grey0,
}));

interface IAvailableWorkflowTemplate {
  workflowTemplate: {
    id: number;
    name: string;
  };
  handleAdd: () => void;
  modifiable: boolean;
}

const AvailableWorkflowTemplate: React.FC<IAvailableWorkflowTemplate> = ({
  workflowTemplate,
  handleAdd,
  modifiable,
}) => (
  <StyledAvailableWorkflowTemplate>
    <WorkflowTemplateTitle workflowTemplate={workflowTemplate} />
    {modifiable && (
      <IconButton onClick={handleAdd}>
        <AddRoundedIcon />
      </IconButton>
    )}
  </StyledAvailableWorkflowTemplate>
);

export default AvailableWorkflowTemplate;
