import _ from 'lodash';

import { useRoutes, Navigate, RouteObject } from 'react-router-dom';

import { TASKS_LIST_URL } from 'config/urls';
import { AuthRequired, AuthLayout, ProtectedRoute } from 'components';

import NotFound from 'pages/NotFound';

import usersRoutes from 'pages/Users/routes';
import workflowsRoutes from 'pages/Workflows/routes';
import workflowTemplatesRoutes from 'pages/WorkflowTemplates/routes';
import workflowTemplateGroupsRoutes from 'pages/WorkflowTemplateGroups/routes';
import surveyTemplatesRoutes from 'pages/SurveyTemplates/routes';
import userGroupsRoutes from 'pages/UserGroups/routes';
import tasksRoutes from 'pages/Tasks/routes';
import componentsRoutes from 'pages/Components/routes';
import taskTemplatesRoutes from 'pages/TaskTemplates/routes';

export interface IRouteWithEntityPermissions extends RouteObject {
  permission: string;
  entity: string;
}

// Wraps all provided routes with a `ProtectedRoute` with the entity permissions defined in each of them.
const protectRoutes = (routes: IRouteWithEntityPermissions[]): RouteObject[] =>
  _.map(routes, (route: IRouteWithEntityPermissions) => ({
    ..._.omit(route, ['entity', 'permission']),
    element: (
      <ProtectedRoute entity={route.entity} permission={route.permission}>
        {route.element}
      </ProtectedRoute>
    ),
  }));

const Routes = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: <AuthRequired />,
      children: [
        {
          element: <AuthLayout />,
          children: [
            {
              index: true,
              element: <Navigate to={TASKS_LIST_URL} />,
            },
            ...protectRoutes([
              ...usersRoutes,
              ...workflowsRoutes,
              ...workflowTemplatesRoutes,
              ...workflowTemplateGroupsRoutes,
              ...surveyTemplatesRoutes,
              ...userGroupsRoutes,
              ...tasksRoutes,
              ...componentsRoutes,
              ...taskTemplatesRoutes,
            ]),
            {
              path: '*',
              element: <NotFound />,
            },
          ],
        },
      ],
    },
  ]);

  return routes;
};

export default Routes;
