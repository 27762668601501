import React from 'react';

import { styled } from '@mui/material/styles';

import { Typography } from 'components';

const StyledWorkflowTemplateTitle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

interface IWorkflowTemplateTitle {
  workflowTemplate: {
    id: number;
    name: string;
  };
}

const WorkflowTemplateTitle: React.FC<IWorkflowTemplateTitle> = ({
  workflowTemplate,
}) => (
  <StyledWorkflowTemplateTitle>
    <Typography variant="subtitle2">{workflowTemplate.name}</Typography>
  </StyledWorkflowTemplateTitle>
);

export default WorkflowTemplateTitle;
