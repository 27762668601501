import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { FieldArrayRenderProps } from 'formik';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { styled } from '@mui/material/styles';

import { Box, AddButton, Typography, Divider } from 'components';

import SelectQuestionOption from '../SelectQuestionOption';

const StyledSelectQuestionOptionsFieldArray = styled('div')(() => ({
  paddingLeft: '20px',
  paddingRight: '20px',
}));

interface ISelectQuestionOptionsFieldArray extends FieldArrayRenderProps {
  questionIndex: number;
}

const SelectQuestionOptionsFieldArray: React.FC<
  ISelectQuestionOptionsFieldArray
> = ({ push, move, remove, form, name: questionFormPath }) => {
  const { values } = form;

  const questionOptions = useMemo(
    () => _.get(values, questionFormPath, []),
    [values, questionFormPath]
  );

  const handleAddNewOption = useCallback(
    () => push({ uuid: uuidv4(), value: '' }),
    [push]
  );

  const onOptionDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      const startIndex = source.index;
      const endIndex = destination.index;

      if (startIndex === endIndex) {
        return;
      }

      move(startIndex, endIndex);
    },
    [move]
  );

  return (
    <>
      <StyledSelectQuestionOptionsFieldArray>
        {!_.isEmpty(questionOptions) && (
          <>
            <Divider sx={{ mb: 2 }}>
              <Typography variant="subtitle1">Options</Typography>
            </Divider>
            <DragDropContext onDragEnd={onOptionDragEnd}>
              <Droppable droppableId="options">
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {_.map(questionOptions, ({ uuid }, optionIndex: number) => (
                      <SelectQuestionOption
                        key={uuid}
                        uuid={uuid}
                        optionIndex={optionIndex}
                        questionFormPath={questionFormPath}
                        // Do not allow emptying the options
                        disableRemove={_.size(questionOptions) === 1}
                        handleRemove={() => {
                          // Maybe add a confirmation alert?
                          remove(optionIndex);
                        }}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            // The X icon in the option is 36x36
            marginRight: '36px',
          }}
        >
          <AddButton variant="outlined" onClick={handleAddNewOption}>
            Add option
          </AddButton>
        </Box>
      </StyledSelectQuestionOptionsFieldArray>
    </>
  );
};

export default SelectQuestionOptionsFieldArray;
