import { styled } from '@mui/material/styles';

import { colors } from 'theme/palette';
import { Paper } from 'components';

const StyledContentWrapper = styled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px 0',
  gap: '20px',
  backgroundColor: colors.grey0,
}));

export default StyledContentWrapper;
