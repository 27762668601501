import { useFetch } from 'utils/sdk';

interface IOrganizationListEntity {
  id: number;
  short_name: string;
}

export const useOrganizations = () => {
  const { data, error, isLoading } =
    useFetch<IOrganizationListEntity[]>('organizations/');

  return { organizations: data || [], error, isLoading };
};
