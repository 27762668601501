import React, { useCallback } from 'react';

import { FieldArrayRenderProps } from 'formik';

import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { styled } from '@mui/material/styles';

import {
  Typography,
  Divider,
  AddButton,
  Box,
  FormHelperText,
} from 'components';
import { useDialog } from 'utils/hooks';
import { colors } from 'theme/palette';

import {
  useTaskTemplates,
  ITaskTemplateListEntity,
} from 'entities/TaskTemplate/sdk';
import TaskTemplateCreateDialog from 'entities/TaskTemplate/components/CreateDialog';

import AvailableTaskTemplate from '../AvailableTaskTemplate';
import UsedTaskTemplate from '../UsedTaskTemplate';

const StyledTaskTemplatesFieldArray = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1px 1fr',
  gridGap: '10px',
  padding: '10px',
  border: `1px solid ${colors.grey2}`,
  borderRadius: '5px',
}));

const StyledTasksContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  maxHeight: '390px',
  padding: '10px 0',
  overflowY: 'auto',
}));

interface ITaskTemplatesFieldArray extends FieldArrayRenderProps {
  modifiable: boolean;
}

const TaskTemplatesFieldArray: React.FC<ITaskTemplatesFieldArray> = ({
  move,
  handlePush,
  handleRemove,
  form: { values, errors },
  modifiable = true,
}) => {
  const { taskTemplates: availableTaskTemplates, mutate: mutateTaskTemplates } =
    useTaskTemplates();

  const {
    isOpen: isTaskTemplateDialogOpen,
    open: openTaskTemplateCreateDialog,
    close: closeTaskTemplateCreateDialog,
  } = useDialog();

  const handleNewTaskTemplateAdd = useCallback(
    (newTaskTemplate: ITaskTemplateListEntity) => {
      // Optimistically update the UI without refetching the task templates from the API.
      mutateTaskTemplates([...availableTaskTemplates, newTaskTemplate]);
    },
    [availableTaskTemplates, mutateTaskTemplates]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      const startIndex = source.index;
      const endIndex = destination.index;

      if (startIndex === endIndex) {
        return;
      }

      move(startIndex, endIndex);
    },
    [move]
  );

  return (
    <>
      <StyledTaskTemplatesFieldArray>
        <div>
          <Typography variant="subtitle1" color={colors.grey4}>
            Available task templates
          </Typography>
          <StyledTasksContainer>
            {availableTaskTemplates.map(
              (taskTemplate: ITaskTemplateListEntity) => (
                <AvailableTaskTemplate
                  key={taskTemplate.id}
                  modifiable={modifiable}
                  taskTemplate={taskTemplate}
                  handleAdd={handlePush(taskTemplate)}
                />
              )
            )}
          </StyledTasksContainer>
          {modifiable && (
            <AddButton
              variant="outlined"
              onClick={openTaskTemplateCreateDialog}
              sx={{ mt: 2 }}
            >
              New task template
            </AddButton>
          )}
        </div>
        <Divider light orientation="vertical" />
        <div>
          <Typography variant="subtitle1" color={colors.grey4}>
            Used task templates
          </Typography>

          {errors.task_templates && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90%',
              }}
            >
              {/* By default, `FormHelperText` has some margins to display it a bit "nested". We do not want this here, thus we remove the margins explicitly. */}
              <FormHelperText error variant="filled" sx={{ margin: 0 }}>
                {errors.task_templates}
              </FormHelperText>
            </Box>
          )}

          {!errors.task_templates && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="availableTaskTemplates">
                {provided => (
                  <StyledTasksContainer
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {values.task_templates.map(
                      (
                        taskTemplate: ITaskTemplateListEntity,
                        index: number
                      ) => (
                        <UsedTaskTemplate
                          key={index}
                          index={index}
                          modifiable={modifiable}
                          taskTemplate={taskTemplate}
                          handleRemove={handleRemove(index)}
                        />
                      )
                    )}
                    {provided.placeholder}
                  </StyledTasksContainer>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </StyledTaskTemplatesFieldArray>
      {isTaskTemplateDialogOpen && (
        <TaskTemplateCreateDialog
          handleClose={closeTaskTemplateCreateDialog}
          handleSuccess={handleNewTaskTemplateAdd}
        />
      )}
    </>
  );
};

export default TaskTemplatesFieldArray;
