import React, { useMemo, useState, useCallback } from 'react';

import _ from 'lodash';
import { FieldProps } from 'formik';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';

import { colors } from 'theme/palette';
import { Box, Typography, Button, BooleanIcon } from 'components';
import { FileUploadOutlinedIcon, UploadFileOutlinedIcon } from 'icons';

import { bytesToMegaBytes } from 'utils/files';
import { notifyApiError } from 'utils/notifications';

import {
  directUploadStart,
  directUploadDo,
  directUploadFinish,
  MAX_FILE_SIZE_IN_BYTES,
  FILE_TYPES,
} from 'entities/File/sdk';

interface IStyledDropzone {
  disabled: boolean;
  isDragActive: boolean;
  isDragAccept: boolean;
  isDragReject: boolean;
}

const StyledDropzone = styled('div', {
  shouldForwardProp: prop =>
    prop !== 'disabled' &&
    prop !== 'isDragActive' &&
    prop !== 'isDragAccept' &&
    prop !== 'isDragReject',
})<IStyledDropzone>(
  ({ disabled, isDragActive, isDragAccept, isDragReject }) => {
    const commonStyles = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      gap: '10px',
      padding: '20px',
      borderRadius: 2,
    };

    if (disabled) {
      return {
        ...commonStyles,
        cursor: 'not-allowed',
        background: colors.grey1,
        border: `2px dashed ${colors.grey3}`,
      };
    }

    const borderStyle = isDragActive ? 'solid' : 'dashed';

    let backgroundStyles: any = { background: colors.grey0 };

    if (isDragActive) {
      let mainStripeColor = colors.orange1;
      let secondaryStripeColor = colors.orange2;

      if (isDragAccept) {
        backgroundStyles = {
          ...backgroundStyles,
          // Animate the background stripes to move slightly to the left inifinitely
          animation: 'gradient 10s linear infinite',
          '@keyframes gradient': {
            from: {
              backgroundPosition: '100% 0%',
            },
            to: {
              backgroundPosition: '15% 100%',
            },
          },
        };
      } else if (isDragReject) {
        mainStripeColor = colors.red0;
        secondaryStripeColor = colors.red1;
      }

      backgroundStyles = {
        ...backgroundStyles,
        backgroundSize: '50px 50px',
        // Make the background have a striped gradient.
        // Reference: https://css-tricks.com/stripes-css/
        background: `repeating-linear-gradient(
        45deg,
        ${mainStripeColor} 0 25%,
        ${secondaryStripeColor} 25% 50%
      )`,
      };
    }

    return {
      ...commonStyles,
      ...backgroundStyles,
      cursor: 'pointer',
      border: `2px ${borderStyle} ${colors.mainOrange}`,
    };
  }
);

interface IStyledFileUploadIcon {
  isDragAccept: boolean;
}

const StyledFileUploadIcon = styled(FileUploadOutlinedIcon, {
  shouldForwardProp: prop => prop !== 'isDragAccept',
})<IStyledFileUploadIcon>(({ isDragAccept }) => ({
  color: colors.grey4,
  fontSize: '50px',
  animation: isDragAccept ? 'move 0.5s infinite alternate' : null,
  '@keyframes move': {
    from: { transform: 'translateY(0)' },
    to: { transform: 'translateY(-10px)' },
  },
}));

interface IFileUploadField extends FieldProps {
  disabled?: boolean;
  accept?: string | string[];
}

// Flatten all accepted file types into a single string array
const DEFAULT_ACCEPTED_FILE_TYPES: string[] = _.flatten(_.values(FILE_TYPES));

const FileUploadField: React.FC<IFileUploadField> = ({
  field,
  form: { setFieldValue },
  disabled = false,
  accept = DEFAULT_ACCEPTED_FILE_TYPES,
}) => {
  const [fileToUpload, setFileToUpload] = useState<File>();

  const [fileIsUploading, setFileIsUploading] = useState<boolean>(false);
  const [fileIsUploaded, setFileIsUploaded] = useState<boolean>(false);

  const onDrop = useCallback((files: File[]) => {
    setFileIsUploaded(false);
    setFileToUpload(files[0]);
  }, []);

  const handleUpload = useCallback(async () => {
    setFileIsUploading(true);

    try {
      const startResponse = await directUploadStart({
        file_name: fileToUpload.name,
        file_type: fileToUpload.type,
      });

      await directUploadDo({ file: fileToUpload, data: startResponse });
      const uploadedFile = await directUploadFinish({ data: startResponse });

      setFileIsUploaded(true);

      setFieldValue(field.name, uploadedFile);
    } catch (e: any) {
      notifyApiError(e);
      setFileIsUploaded(false);
    }

    setFileIsUploading(false);
  }, [fileToUpload, field.name, setFieldValue]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    onDrop,
    disabled,
    accept,
    multiple: false, // We currently allow only a single file upload
    maxSize: MAX_FILE_SIZE_IN_BYTES,
  });

  const dropzoneTitle = useMemo(() => {
    if (disabled) {
      return 'Uploading files is disabled.';
    }

    if (isDragAccept) {
      return 'Drop the file to upload it.';
    } else if (isDragReject) {
      return 'File cannot be uploaded.';
    }

    return 'Drop a file or click here to upload.';
  }, [disabled, isDragAccept, isDragReject]);

  return (
    <div className="container">
      <StyledDropzone
        {...getRootProps({
          disabled,
          isDragActive,
          isDragAccept,
          isDragReject,
        })}
      >
        <input {...getInputProps()} />

        <StyledFileUploadIcon isDragAccept={isDragAccept} />
        <Typography variant="subtitle2">{dropzoneTitle}</Typography>

        {!_.isEmpty(fileRejections) && (
          <Typography variant="subtitle2" color="error">
            {/* We have a single file upload for now, that's why we display the one-and-only file rejection here */}
            File <strong>{fileRejections[0].file.name}</strong> cannot be
            uploaded.
          </Typography>
        )}

        {fileToUpload && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '3px',
            }}
          >
            {fileIsUploaded ? (
              <BooleanIcon value={fileIsUploaded} />
            ) : (
              <UploadFileOutlinedIcon style={{ color: colors.mainOrange }} />
            )}

            <Typography variant="subtitle2" lineHeight="normal">
              <strong>{fileToUpload.name}</strong> (
              {bytesToMegaBytes(fileToUpload.size)} MB)
            </Typography>
          </Box>
        )}
      </StyledDropzone>

      <Button
        size="small"
        variant="outlined"
        onClick={handleUpload}
        disabled={!fileToUpload || fileIsUploading || fileIsUploaded}
        sx={{ display: 'flex', gap: '5px', marginTop: '10px' }}
      >
        Upload file
      </Button>
    </div>
  );
};

export default FileUploadField;
