import { IQuestion } from 'entities/Question/sdk';

import { useFetch, post } from 'utils/sdk';

export interface ISurveyQuestion extends IQuestion {
  id: number;

  // Number question specific fields
  min?: number;
  max?: number;

  answers: {
    id: number;
    has_change_requests: boolean;

    text_answer?: string;
    boolean_answer?: boolean;
    number_answer?: number;
    choices_answer?: string[];
    geopoint_answer?: {
      longitude: string;
      latitude: string;
    };
    file_answer?: string;
  }[];
}

export const useSurveyQuestionsList = (
  surveyId: number | undefined,
  options: any = {}
) => {
  const {
    data: questions,
    error,
    isLoading,
  } = useFetch<ISurveyQuestion[]>(
    surveyId ? `surveys/${surveyId}/questions/` : null,
    {},
    options
  );

  return { questions, error, isLoading };
};

export const requestChangesForAnswers = (values: {
  change_requests: {
    answer: number;
    reason: string;
    comments?: { text: string }[];
  }[];
}) => post('surveys/answers/change-requests/create/', values);
