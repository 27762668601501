import { stringifyUrl } from 'utils/urls';
import { useFetch, post } from 'utils/sdk';

export interface IWorkflow {
  id: number;
  name: string;
  template_group: {
    id: number;
    name: string;
  };
  created_at: string;
  completed_at: string | null;
  progress: number;
}

export const useWorkflows = () => {
  const {
    data: workflows,
    error,
    isLoading,
    refetch,
  } = useFetch<IWorkflow[]>('workflows/', {
    paginate: false,
  });

  return { workflows, error, isLoading, refetch };
};

export interface IWorkflowCreatePayload {
  name: string;
  template_group: number;
  roles: { template: number; users: number[] }[];
}

export interface IWorkflowCreateResponse {
  id: number;
  name: string;
  template_group: {
    id: number;
    name: string;
  };
}

export const createWorkflow = (data: IWorkflowCreatePayload) =>
  post<IWorkflowCreateResponse>('workflows/create/', data);

export interface IWorkflowAddComponentsPayload {
  components: number[];
}

export const addWorkflowComponents = (
  workflowId: string,
  data: IWorkflowAddComponentsPayload
) => post(`workflows/${workflowId}/offered-components/add/`, data);

export interface IWorkflowOfferedComponent {
  id: number;
  category_name: string;
  manufacturer_name: string;
  model: string;
  description: string;
  latest_price: {
    id: number;
    amount: string;
  };
}

export const useWorkflowOfferedComponents = (workflowId: number) => {
  const {
    data: offeredComponents,
    error,
    isLoading,
    refetch,
  } = useFetch<IWorkflowOfferedComponent[]>(
    `workflows/${workflowId}/offered-components/`
  );

  return { offeredComponents, error, isLoading, refetch };
};

export const removeWorkflowComponent = (
  workflowId: number,
  componentId: number
) =>
  post(`workflows/${workflowId}/offered-components/${componentId}/remove/`, {});

export interface IWorkflowDetailEntity {
  id: number;
  name: string;

  progress: number;

  created_at: string;
  completed_at: string;
}

export const useWorkflowDetail = (workflowId: string) => {
  const {
    data: workflow,
    error,
    isLoading,
  } = useFetch<IWorkflowDetailEntity>(`workflows/${workflowId}/`);

  return { workflow, error, isLoading };
};

interface IWorkflowParticipant {
  id: number;
  email: string;

  organization?: {
    name: string;
  };

  workflow_roles: {
    name: string;
  }[];

  current_task?: {
    name: string;
  };
}

export const useWorkflowParticipantsList = (workflowId: string) => {
  const {
    data: participants,
    error,
    isLoading,
  } = useFetch<IWorkflowParticipant[]>(`workflows/${workflowId}/participants/`);

  return { participants, error, isLoading };
};

interface IWorkflowDocument {
  id: number;

  question: {
    id: number;
    title: string;
  };

  user: {
    id: number;
    email: string;
  };

  file: {
    id: number;
    original_file_name: string;
    file_type: string;
    url: string;
  };

  task: {
    id: number;
    reviewer: {
      id: number;
      email: string;
    } | null;
    reviewer_role: {
      id: number;
      name: string;
    };
    reviewed_at: string | null;
    template_name: string;
  };

  created_at: string;
}

export const useWorkflowDocumentsList = (workflowId: string) => {
  const {
    data: documents,
    error,
    isLoading,
  } = useFetch<IWorkflowDocument[]>(`workflows/${workflowId}/file-answers/`);

  return { documents, error, isLoading };
};

export interface IWorkflowOverviewTaskTemplate {
  id: number;
  name: string;
  type: string;
  completed_tasks_count: number;
  in_progress_tasks_count: number;
  for_review_tasks_count: number;
  all_tasks_count: number;
}

export const useWorkflowOverview = (workflowId: string) => {
  const { data, error, isLoading } = useFetch<IWorkflowOverviewTaskTemplate[]>(
    `workflows/${workflowId}/overview/`
  );

  return { data, error, isLoading };
};

export interface IWorkflowTask {
  id: number;

  survey_id: number;
  order_id: number;

  template: {
    id: number;
    name: string;
    type: string;
    notes: string;
  };

  executor_role: {
    id: number;
    name: string;
  };
  executor: {
    id: number;
    email: string;
  };
  completed_at: string;

  reviewer_role: {
    id: number;
    name: string;
  };
  reviewer: {
    id: number;
    email: string;
  };
  reviewed_at: string;

  status: string;
}

export const useWorkflowTasksList = (
  workflowId: string,
  taskTemplateId: number
) => {
  const url = stringifyUrl({
    url: `workflows/${workflowId}/tasks/`,
    query: { template: taskTemplateId },
  });

  const { data, error, isLoading } = useFetch<IWorkflowTask[]>(url);

  return { data, error, isLoading };
};
