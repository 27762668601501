import {
  SURVEY_TEMPLATES_LIST_URL,
  SURVEY_TEMPLATE_CREATE_URL,
  SURVEY_TEMPLATE_DETAIL_URL,
  SURVEY_TEMPLATE_EDIT_URL,
} from 'config/urls';
import { IRouteWithEntityPermissions } from 'config/routes';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import SurveyTemplatesList from './List';
import SurveyTemplateCreate from './Create';
import SurveyTemplateDetail from './Detail';
import SurveyTemplateEdit from './Edit';

const entity = PERMISSION_ENTITIES.TEMPLATES;

const routes: IRouteWithEntityPermissions[] = [
  {
    path: SURVEY_TEMPLATES_LIST_URL,
    entity,
    permission: ENTITY_PERMISSIONS.VIEW,
    element: <SurveyTemplatesList />,
  },
  {
    path: SURVEY_TEMPLATE_CREATE_URL,
    entity,
    permission: ENTITY_PERMISSIONS.ADD,
    element: <SurveyTemplateCreate />,
  },
  {
    path: SURVEY_TEMPLATE_DETAIL_URL,
    entity,
    permission: ENTITY_PERMISSIONS.VIEW,
    element: <SurveyTemplateDetail />,
  },
  {
    path: SURVEY_TEMPLATE_EDIT_URL,
    entity,
    permission: ENTITY_PERMISSIONS.CHANGE,
    element: <SurveyTemplateEdit />,
  },
];

export default routes;
