import { TASK_TEMPLATES_LIST_URL } from 'config/urls';
import { IRouteWithEntityPermissions } from 'config/routes';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import TaskTemplatesList from './List';

const routes: IRouteWithEntityPermissions[] = [
  {
    path: TASK_TEMPLATES_LIST_URL,
    entity: PERMISSION_ENTITIES.TEMPLATES,
    permission: ENTITY_PERMISSIONS.VIEW,
    element: <TaskTemplatesList />,
  },
];

export default routes;
