import React, { useMemo, useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';

import { Typography, Protected, AddFab } from 'components';
import DataGrid, {
  transformToColumns,
  GridRowParams,
  GridColDef,
  GridActionsCellItem,
} from 'components/DataGrid';

import { useDialogWithQueryParam } from 'utils/hooks';
import { EditRoundedIcon, ContentCopyRoundedIcon } from 'icons';

import {
  useTaskTemplates,
  TASK_TEMPLATE_TYPES,
} from 'entities/TaskTemplate/sdk';
import {
  ENTITY_PERMISSIONS,
  PERMISSION_ENTITIES,
} from 'entities/EntityPermission/sdk';

import TaskTemplateEditDialog from 'entities/TaskTemplate/components/EditDialog';
import TaskTemplateCreateDialog from 'entities/TaskTemplate/components/CreateDialog';

const EDIT_QUERY_PARAM = 'edit';

const columnsFields: string[] = [
  'taskTemplate',
  'type',
  'executor',
  'reviewer',
  'actions',
];

const TaskTemplatesList: React.FC = () => {
  const { taskTemplates, error, isLoading, refetch } = useTaskTemplates();

  // We use search params here because the task template ID can be preselected from the URL params
  const [searchParams] = useSearchParams();

  const {
    isOpen: isCreateDialogOpen,
    open: openCreateDialog,
    close: closeCreateDialog,
  } = useDialogWithQueryParam('create');

  const [editTaskTemplateId, setEditTaskTemplateId] = useState<string>(
    searchParams.get(EDIT_QUERY_PARAM)
  );

  const {
    isOpen: isEditDialogOpen,
    open: openEditDialog,
    close: closeEditDialog,
  } = useDialogWithQueryParam(EDIT_QUERY_PARAM, editTaskTemplateId);

  useEffect(() => {
    // Open/close the edit dialog based on the state variable, which can be either preset from the query params or manually set from the table edit handler.
    if (!_.isEmpty(editTaskTemplateId)) {
      openEditDialog();
    } else {
      closeEditDialog();
    }
  }, [editTaskTemplateId, openEditDialog, closeEditDialog]);

  const [duplicateTaskTemplateId, setDuplicateTaskTemplateId] =
    useState<string>();

  useEffect(() => {
    if (!_.isEmpty(duplicateTaskTemplateId)) {
      openCreateDialog();
    }
  }, [duplicateTaskTemplateId, openCreateDialog]);

  const handleCreateClose = useCallback(() => {
    setDuplicateTaskTemplateId(null);
    closeCreateDialog();
  }, [closeCreateDialog]);

  const rows = useMemo(
    () =>
      _.map(taskTemplates, taskTemplate => {
        const { id, type, name, executor_role, reviewer_role } = taskTemplate;

        return {
          id,
          type,
          taskTemplate: name,
          executor: executor_role.name,
          reviewer: reviewer_role.name,
        };
      }),
    [taskTemplates]
  );

  const columns: GridColDef[] = useMemo(
    () =>
      transformToColumns(columnsFields).map((col: GridColDef) => {
        if (col.field === 'type') {
          return {
            ...col,
            type: 'singleSelect',
            valueOptions: _.values(TASK_TEMPLATE_TYPES),
            valueFormatter: ({ value }) => _.capitalize(value),
          };
        }

        if (col.field === 'actions') {
          return {
            ...col,
            type: 'actions',
            headerName: '',
            maxWidth: 50,
            getActions: (params: GridRowParams) => [
              <GridActionsCellItem
                icon={<EditRoundedIcon />}
                onClick={() => setEditTaskTemplateId(params.id.toString())}
                label="Edit"
                showInMenu
              />,
              <GridActionsCellItem
                icon={<ContentCopyRoundedIcon />}
                onClick={() => setDuplicateTaskTemplateId(params.id.toString())}
                label="Duplicate"
                showInMenu
              />,
            ],
          };
        }

        return col;
      }),
    []
  );

  if (error) {
    return (
      <Typography variant="subtitle2">
        Failed to fetch task templates.
      </Typography>
    );
  }

  return (
    <>
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows}
        disableSelectionOnClick
      />

      {isEditDialogOpen && !_.isNil(editTaskTemplateId) && (
        <TaskTemplateEditDialog
          taskTemplateId={editTaskTemplateId}
          handleClose={() => setEditTaskTemplateId(null)}
          handleSuccess={refetch}
        />
      )}

      <Protected
        entity={PERMISSION_ENTITIES.TEMPLATES}
        permission={ENTITY_PERMISSIONS.ADD}
      >
        <AddFab onClick={openCreateDialog} />
        {isCreateDialogOpen && (
          <TaskTemplateCreateDialog
            handleClose={handleCreateClose}
            handleSuccess={refetch}
            duplicateId={duplicateTaskTemplateId}
          />
        )}
      </Protected>
    </>
  );
};

export default TaskTemplatesList;
