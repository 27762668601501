import { useCallback, useMemo } from 'react';

import _ from 'lodash';

import useSWR from 'swr';
import type { SWRConfiguration } from 'swr';

import { stringifyUrl } from 'utils/urls';
import { BASE_API_URL, BASE_BACKEND_URL } from 'utils/constants';

type HttpMethodType = 'get' | 'post' | 'delete';

export interface IPaginatedResponse<T> {
  limit: number;
  offset: number;
  count: number;
  next: string | null;
  previous: string | null;
  results: T[];
}

export interface IAPIException {
  data: { message: string; extra: { fields: any } };
  status: number;
}

const getBaseConfig = (method: HttpMethodType): RequestInit => ({
  method,
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    'x-requested-with': 'XMLHttpRequest',
  },
});

const handleResponse = async <T>(response: Response): Promise<T> => {
  if (!response.ok) {
    const data = await response.json();
    const error: IAPIException = { data, status: response.status };
    throw error;
  }

  // Parse text at first to avoid empty response JSON parse errors
  const text = await response.text();
  return text ? JSON.parse(text) : null;
};

export const get = async <T>(url: string, queryParams?: any): Promise<T> => {
  const apiUrl = stringifyUrl({
    url: `${BASE_API_URL}/${url}`,
    query: queryParams,
  });

  const response = await fetch(apiUrl, getBaseConfig('get'));

  return handleResponse<T>(response);
};

export const post = async <T>(
  url: string,
  data?: any,
  options?: any
): Promise<T> => {
  const response = await fetch(`${BASE_API_URL}/${url}`, {
    ...getBaseConfig('post'),
    ...options,
    body: JSON.stringify(data),
  });

  return handleResponse<T>(response);
};

export const useFetch = <T>(
  url: string | null,
  queryParams?: any,
  // https://swr.vercel.app/docs/options#options
  swrConfig?: SWRConfiguration
) => {
  const key = useMemo(
    () => (queryParams ? [url, queryParams] : url),
    [url, queryParams]
  );

  // TODO: Connected to the `/auth/me` call which is problematic with the redirects
  // const { mutate: globalMutate } = useSWRConfig();

  const { data, error, mutate } = useSWR<T>(key, {
    onError: error => {
      if (error.status === 403) {
        const refreshUrl = _.get(error.data, 'refresh_url');
        if (refreshUrl) {
          // Redirect to the BE authentication endpoint and set `next` url as the current URK.
          window.location.href = `${BASE_BACKEND_URL}/oidc/authenticate/?next=${window.location.href}`;
        }

        // Refetch the current user state whenever a request returns a 403 FORBIDDEN response.
        // This mainly handles the case when the current user permissions are changed while he/she is browsing the app.
        // TODO: Why does this cause so many /auth/me/ refetches?
        // globalMutate('auth/me/');
      }

      if (error.status === 401) {
        // If 403 is returned redirect to the BE authentication endpoint which
        // redirects back to the FE page after successful authentication.
        window.location.href = `${BASE_BACKEND_URL}/oidc/authenticate/`;
      }
    },
    ...swrConfig,
  });

  const refetch = useCallback(() => mutate(), [mutate]);

  return useMemo(
    () => ({
      data,
      error,
      mutate,
      refetch,
      isLoading: !data && !error,
    }),
    [data, error, mutate, refetch]
  );
};
