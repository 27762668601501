import React, { useMemo, useRef, useEffect } from 'react';

import _ from 'lodash';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Draggable } from 'react-beautiful-dnd';

import { styled } from '@mui/material/styles';

import { colors } from 'theme/palette';
import { CloseRoundedIcon, DragHandleRoundedIcon } from 'icons';
import {
  Box,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormTextField,
  Divider,
  Paper,
} from 'components';

import { QUESTION_TYPES } from 'entities/Question/sdk';
import {
  isSelectQuestion,
  isNumberQuestion,
  QUESTION_FILE_TYPE_TO_ALLOWED_FILES,
} from 'entities/Question/utils';
import QuestionType from 'entities/Question/components/QuestionType';

import { IFormValues } from '../../';
import NumberQuestionValidations from '../NumberQuestionValidations';
import FileUploadQuestionValidations from '../FileUploadQuestionValidations';
import SelectQuestionOptionsFieldArray from '../SelectQuestionOptionsFieldArray';

const StyledQuestionHeader = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledQuestionForm = styled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  padding: '10px',
  border: `1px solid ${colors.grey3}`,
  borderRadius: '15px',
  backgroundColor: colors.white,
}));

interface IQuestionForm {
  question: IFormValues['questions'][0];
  index: number;
  handleRemove: () => void;
}

const QuestionForm: React.FC<IQuestionForm> = ({
  question,
  index,
  handleRemove,
}) => {
  const { values, setFieldValue } = useFormikContext<IFormValues>();

  const questionsLength = useMemo(
    () => _.size(values.questions),
    [values.questions]
  );

  const titleInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    // Whenever a template file is uploaded, pre-select the file type by filling it with the template file's type.
    if (question.template_file) {
      const fileTypeToPreselect = _.findKey(
        QUESTION_FILE_TYPE_TO_ALLOWED_FILES,
        (value: string | string[]) =>
          _.includes(value, question.template_file.file_type)
      );

      setFieldValue(`questions.${index}.file_type`, fileTypeToPreselect);
    }
  }, [index, question.template_file, setFieldValue]);

  useEffect(() => {
    /*
     Manually focus the question's `title` field
     whenever the questions length change (on add / remove).

     Typically this is achieved with just passing `autoFocus` to the input.
     Unfortunately, in this case, since we have a dynamic array field,
     we cannot use this approach and we should perform a manual focus.

     This is entirely for UX purposes.
    */
    if (index === questionsLength - 1) {
      titleInputRef.current.focus();
    }
  }, [index, questionsLength]);

  return (
    <Draggable key={question.uuid} draggableId={question.uuid} index={index}>
      {(provided, snapshot) => (
        <StyledQuestionForm
          ref={provided.innerRef}
          elevation={snapshot.isDragging ? 10 : 1}
          {...provided.draggableProps}
        >
          <StyledQuestionHeader {...provided.dragHandleProps}>
            <IconButton disableRipple>
              <DragHandleRoundedIcon />
            </IconButton>
            <Typography variant="h6">Question #{index + 1}</Typography>
            <IconButton onClick={handleRemove}>
              <CloseRoundedIcon />
            </IconButton>
          </StyledQuestionHeader>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <QuestionType type={question.type} />
          </Box>

          <Divider />

          <FormControlLabel
            label="Required"
            control={
              <Field
                type="checkbox"
                name={`questions.${index}.required`}
                as={Checkbox}
              />
            }
          />
          <Field
            inputRef={titleInputRef}
            name={`questions.${index}.title`}
            component={FormTextField}
            fullWidth
            required
            label="Title"
          />
          <Field
            name={`questions.${index}.hint`}
            component={FormTextField}
            fullWidth
            multiline
            minRows={10}
            label="Hint"
          />

          {isSelectQuestion(question.type) && (
            <FieldArray
              name={`questions.${index}.options`}
              // @ts-ignore
              component={SelectQuestionOptionsFieldArray}
            />
          )}

          {isNumberQuestion(question.type) && (
            <NumberQuestionValidations question={question} index={index} />
          )}

          {question.type === QUESTION_TYPES.FILE_UPLOAD && (
            <FileUploadQuestionValidations index={index} />
          )}
        </StyledQuestionForm>
      )}
    </Draggable>
  );
};

export default QuestionForm;
