import _ from 'lodash';

import { ISignedUser } from 'entities/Auth/sdk';

import { IEntityPermission } from './sdk';

export const hasEntityPermission = (
  user: ISignedUser,
  entity: string,
  permission: string
): boolean => _.get(user, `permissions.${entity}.${permission}`, false);

export const groupPermissionsByEntity = (
  entityPermissions: IEntityPermission[]
) =>
  // From: [ { entity: 'workflows', ... }, { entity: 'workflows': ... }, { entity: 'survey_templates', ... }, ... ]
  // To:   { survey_templates: [ ... ], workflows: [ ... ], ... }
  _.groupBy(_.orderBy(entityPermissions, ['entity', 'permission']), 'entity');
