import React from 'react';

import { ITaskDetailEntity } from 'entities/Task/sdk';

import SubmitOrderForm from '../components/SubmitOrderForm';
import ReviewOrderForm from '../components/ReviewOrderForm';

interface IOrderTaskDetail {
  task: ITaskDetailEntity;
}

const OrderTaskDetail: React.FC<IOrderTaskDetail> = ({ task }) => (
  <>
    {task.is_executor && !task.completed_at && <SubmitOrderForm task={task} />}
    {task.is_reviewer && task.completed_at && <ReviewOrderForm task={task} />}
  </>
);

export default OrderTaskDetail;
