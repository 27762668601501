import React, { useMemo, useCallback } from 'react';

import _ from 'lodash';
import { getIn, FieldProps } from 'formik';
import { createFilterOptions } from '@mui/material/Autocomplete';

import TextField, { TextFieldProps } from 'components/TextField';
import Autocomplete, { AutocompleteProps } from 'components/Autocomplete';

import { IOption } from './utils';

type AutocompleteType = AutocompleteProps<IOption, boolean, boolean, boolean>;

interface IAutocompleteField extends FieldProps, AutocompleteType {
  textFieldProps: TextFieldProps;
}

const filter = createFilterOptions<IOption>();

// This component is meant to be used in Formik <Field> component.
// Reference: https://firxworx.com/blog/coding/react/integrating-formik-with-react-material-ui-and-typescript/
const AutocompleteField: React.FC<IAutocompleteField> = ({
  field,
  form,
  textFieldProps,
  ...rest
}) => {
  const { name: fieldName } = field;
  const { errors, touched, setFieldValue, setFieldTouched } = form;

  const setValue = useCallback(
    (value: IOption) => setFieldValue(fieldName, value),
    [fieldName, setFieldValue]
  );

  const handleChange = useCallback(
    (_e, option: IOption | string | null) => {
      // The user clicked `Enter` after typing
      if (_.isString(option)) {
        setValue({ value: option });
      } else if (option?.inputText) {
        setValue({ value: option.inputText });
      } else if (_.isNull(option)) {
        setValue({ value: '' });
      } else {
        // A regular option select
        setValue(option);
      }
    },
    [setValue]
  );

  const filterOptions = useCallback((options: IOption[], params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = _.some(options, option => inputValue === option.value);
    if (!_.isEmpty(inputValue) && !isExisting) {
      filtered.push({
        inputText: inputValue,
        value: `Add "${inputValue}"`,
      });
    }

    return filtered;
  }, []);

  const getOptionLabel = useCallback((option: IOption | string | null) => {
    // Value selected with enter, right from the input
    if (_.isString(option)) {
      return option;
    }

    // Add "xxx" option created dynamically
    if (option.inputText) {
      return option.inputText;
    }

    // Regular option
    return option.value;
  }, []);

  const isTouched = useMemo(
    () => getIn(touched, `${fieldName}.value`),
    [touched, fieldName]
  );

  const errorMessage = useMemo(
    () => getIn(errors, `${fieldName}.value`),
    [errors, fieldName]
  );

  return (
    <Autocomplete
      {...field}
      {...rest}
      // Reference: https://mui.com/material-ui/react-autocomplete/#creatable
      freeSolo
      clearOnBlur
      selectOnFocus
      size="small"
      onOpen={() => setFieldTouched(`${fieldName}.value`, true)}
      // @ts-ignore
      onChange={handleChange}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option: IOption, selectedValue: IOption) =>
        option.value === selectedValue.value
      }
      renderOption={(props, { value }) => <li {...props}>{value}</li>}
      renderInput={props => (
        <TextField
          error={Boolean(isTouched && errorMessage)}
          helperText={isTouched && errorMessage}
          {...props}
          {...textFieldProps}
        />
      )}
    />
  );
};

export default AutocompleteField;
