import React, { useMemo } from 'react';

import _ from 'lodash';
import { useParams } from 'react-router-dom';

import { RESOURCES } from 'utils/constants';
import { TASKS_LIST_URL } from 'config/urls';
import { Typography, Divider, Notes, Breadcrumbs } from 'components';

import { useTaskDetail } from 'entities/Task/sdk';
import { TASK_TEMPLATE_TYPES } from 'entities/TaskTemplate/sdk';

import SurveyTaskDetail from './Survey';
import OrderTaskDetail from './Order';

const TASK_TYPE_TO_DETAIL_COMPONENT = {
  [TASK_TEMPLATE_TYPES.SURVEY]: SurveyTaskDetail,
  [TASK_TEMPLATE_TYPES.ORDER]: OrderTaskDetail,
};

const TaskDetail: React.FC = () => {
  const { id: taskId } = useParams();

  const { task, isLoading, error } = useTaskDetail(taskId);

  const fullExecutor = useMemo(() => {
    const executorRole = _.get(task, 'executor_role.name');
    const executorEmail = _.get(task, 'executor.email');

    return `${executorEmail} (${executorRole})`;
  }, [task]);

  if (isLoading) {
    return <Typography variant="subtitle2">Loading task...</Typography>;
  }
  if (error) {
    return <Typography variant="subtitle2">Failed to fetch task</Typography>;
  }

  const TaskComponent = TASK_TYPE_TO_DETAIL_COMPONENT[task.template.type];

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            name: RESOURCES.TASKS,
            url: TASKS_LIST_URL,
          },
          { name: `${task.template.name} (${task.workflow.name})` },
        ]}
      />

      <Typography variant="h6">
        <strong>Executor:</strong> {fullExecutor}
      </Typography>

      {!_.isEmpty(task.template.notes) && <Notes value={task.template.notes} />}

      <Divider sx={{ margin: '10px 0' }} />

      <TaskComponent task={task} />
    </>
  );
};

export default TaskDetail;
