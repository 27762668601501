import React from 'react';
import { Outlet } from 'react-router-dom';

import { useSignedUser } from 'entities/Auth/sdk';

const AuthRequired: React.FC = () => {
  const { isAuthenticated, isLoading } = useSignedUser();

  if (isLoading || !isAuthenticated) {
    return null;
  }

  return <Outlet />;
};

export default AuthRequired;
