import { COMPONENTS_LIST_URL, COMPONENT_DETAIL_URL } from 'config/urls';
import { IRouteWithEntityPermissions } from 'config/routes';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import List from './List';
import Detail from './Detail';

const routes: IRouteWithEntityPermissions[] = [
  {
    path: COMPONENTS_LIST_URL,
    entity: PERMISSION_ENTITIES.COMPONENTS,
    permission: ENTITY_PERMISSIONS.VIEW,
    element: <List />,
  },
  {
    path: COMPONENT_DETAIL_URL,
    entity: PERMISSION_ENTITIES.COMPONENTS,
    permission: ENTITY_PERMISSIONS.VIEW,
    element: <Detail />,
  },
];

export default routes;
