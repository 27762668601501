import React, { useMemo } from 'react';

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useParams, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik, Field, FieldArray } from 'formik';

import {
  FormTextField,
  Typography,
  Button,
  Box,
  FormHelperText,
  Notes,
  Breadcrumbs,
} from 'components';
import yup from 'utils/yup';
import { buildDetailUrl } from 'utils/urls';
import { RESOURCES } from 'utils/constants';
import {
  SURVEY_TEMPLATES_LIST_URL,
  SURVEY_TEMPLATE_DETAIL_URL,
} from 'config/urls';

import { IQuestion, QUESTION_TYPES } from 'entities/Question/sdk';
import {
  isSelectQuestion,
  isNumberQuestion,
  parseNumberQuestionRange,
} from 'entities/Question/utils';
import {
  useSurveyTemplate,
  ISurveyTemplateUpdateValues,
  updateSurveyTemplate,
} from 'entities/SurveyTemplate/sdk';

import { notifySuccess, notifyApiError } from 'utils/notifications';

import QuestionsFieldArray from './components/QuestionsFieldArray';

export interface IFormValues {
  name: string;
  questions: {
    uuid: string;

    title: string;
    type: string;
    required: boolean;
    hint?: string;

    // Number question specific fields
    min?: number;
    max?: number;

    // Select question specific fields
    options?: {
      uuid: string;
      value: string;
    }[];

    // File upload question specific fields
    file_type?: string;
    template_file?: {
      id: number;
      file_type: string;
    };
  }[];
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  questions: yup
    .array()
    .of(
      yup.object({
        title: yup.string().required('Question title is required'),
        type: yup.string().required(),
        required: yup.boolean().required(),
        hint: yup.string(),

        // Number question specific fields
        min: yup
          .number()
          // @ts-ignore
          .when(['type', 'max'], (type: string, max: number, schema) => {
            if (isNumberQuestion(type)) {
              const validator =
                type === QUESTION_TYPES.INTEGER
                  ? schema.integer('Please provide an integer value')
                  : schema;

              if (max) {
                return validator.max(
                  max,
                  `Min value cannot be greater than ${max}`
                );
              }
            }
          }),
        max: yup.number().when('type', (type: string, schema) => {
          if (type === QUESTION_TYPES.INTEGER) {
            return schema.integer('Please provide an integer value');
          }

          return schema;
        }),

        // Select question specific fields
        options: yup
          .array()
          .of(
            yup.object({
              value: yup.string(),
            })
          )
          .when('type', (type: string) => {
            if (isSelectQuestion(type)) {
              return yup
                .array()
                .of(
                  yup.object({
                    value: yup.string().required('Please provide an option'),
                  })
                )
                .unique('Duplicated options are not allowed')
                .min(1, 'Please add at least 1 option')
                .required();
            }
          }),

        // File upload question specific fields
        file_type: yup.string().when('type', (type: string, schema) => {
          if (type === QUESTION_TYPES.FILE_UPLOAD) {
            return schema.required('Please select a file type');
          }
        }),
      })
    )
    .min(1, 'Please add at least 1 question')
    .required(),
});

const SurveyTemplateEdit: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { surveyTemplate, isLoading, error, refetch } = useSurveyTemplate(id);

  const onSubmit = async (values: IFormValues) => {
    try {
      let valuesToSubmit: ISurveyTemplateUpdateValues = {
        ...values,
        questions: _.map(values.questions, question => {
          const response = _.omit(
            question,
            'uuid',
            'options',
            'min',
            'max',
            'template_file'
          );

          if (isSelectQuestion(question.type)) {
            return { ...response, options: _.map(question.options, 'value') };
          }

          if (question.template_file) {
            return {
              ...response,
              template_file: question.template_file.id,
            };
          }

          const { min, max } = question;

          if (min || max) {
            let range = {};

            if (min) {
              range = { ...range, lower: min };
            }
            if (max) {
              range = { ...range, upper: max };
            }

            if (!_.isEmpty(range)) {
              return { ...response, range };
            }
          }

          return response;
        }),
      };

      if (!surveyTemplate.can_update) {
        // It is unnecessary to send `questions` to the backend if their updates are disabled,
        // because they won't be modified by the user. This way we send a much lighter request to the server.
        valuesToSubmit = _.omit(values, 'questions');
      }

      await updateSurveyTemplate(id, valuesToSubmit);

      notifySuccess('Survey template updated successfully.');

      refetch(); // Explicitly refetch survey template detail to avoid cache inconsistencies.
      navigate(buildDetailUrl(SURVEY_TEMPLATE_DETAIL_URL, id));
    } catch (e: any) {
      notifyApiError(e);
    }
  };

  const initialValues = useMemo(
    () => ({
      name: _.get(surveyTemplate, 'name', ''),
      questions: _.map(
        _.get(surveyTemplate, 'questions', []),
        (question: IQuestion) => {
          const response = {
            uuid: uuidv4(),
            ..._.omit(question, 'range', 'options', 'file_type'),
          };

          if (isNumberQuestion(question.type)) {
            const { min, max } = parseNumberQuestionRange(question);

            return { ...response, min: min || '', max: max || '' };
          }

          if (isSelectQuestion(question.type)) {
            return {
              ...response,
              options: _.map(question.options, (value: string) => ({
                uuid: uuidv4(),
                value,
              })),
            };
          }

          if (question.type === QUESTION_TYPES.FILE_UPLOAD) {
            return { ...response, file_type: question.file_type };
          }

          return response;
        }
      ),
    }),
    [surveyTemplate]
  );

  const formik = useFormik<IFormValues>({
    // @ts-ignore
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  if (isLoading) {
    return (
      <Typography variant="subtitle2">Loading survey template...</Typography>
    );
  }

  if (error) {
    return (
      <Typography variant="subtitle2">
        Failed to fetch survey template.
      </Typography>
    );
  }

  // Do not display form if it is still not populated.
  if (_.isEmpty(formik.initialValues.name)) {
    // TODO: Display better loading screen here (skeleton)
    return null;
  }

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          { name: RESOURCES.SURVEY_TEMPLATES, url: SURVEY_TEMPLATES_LIST_URL },
          {
            name: surveyTemplate.name,
            url: buildDetailUrl(SURVEY_TEMPLATE_DETAIL_URL, id),
          },
          { name: 'Edit' },
        ]}
      />

      <Box mt={3}>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit} style={{ marginTop: 2 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                gap: 10,
              }}
            >
              <Field
                name="name"
                component={FormTextField}
                autoFocus
                fullWidth
                required
                label="Name"
              />
              <Button
                variant="contained"
                type="submit"
                sx={{ width: '200px' }}
                disabled={
                  formik.isSubmitting || !formik.dirty || !formik.isValid
                }
              >
                Save
              </Button>
            </Box>

            <Typography variant="h6" sx={{ mt: 2 }}>
              Questions
            </Typography>

            {!surveyTemplate.can_update && (
              <Box mt={1} mb={1}>
                <Notes value="Questions cannot be modified as this template is being used in a survey." />
              </Box>
            )}

            <FieldArray
              name="questions"
              render={props => (
                <QuestionsFieldArray
                  {...props}
                  modifiable={surveyTemplate.can_update}
                />
              )}
            />

            {_.isEmpty(formik.values.questions) && (
              <FormHelperText error variant="filled">
                Please add at least 1 question
              </FormHelperText>
            )}
          </form>
        </FormikProvider>
      </Box>
    </>
  );
};

export default SurveyTemplateEdit;
