import React from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { useFormik, Field, FormikProvider, FormikValues } from 'formik';

import { styled } from '@mui/material/styles';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormTextField,
  MenuItem,
  Button,
  Typography,
  Divider,
  Box,
  Notes,
} from 'components';

import { notifySuccess, notifyApiError } from 'utils/notifications';

import {
  TASK_TEMPLATE_TYPES,
  useTaskTemplate,
  ITaskTemplateEditPayload,
  updateTaskTemplate,
} from 'entities/TaskTemplate/sdk';
import {
  IRoleTemplate,
  useAvailableRoleTemplates,
} from 'entities/WorkflowRole/sdk';
import {
  ISurveyTemplateListEntity,
  useSurveyTemplates,
} from 'entities/SurveyTemplate/sdk';

const FORM_ID = 'task-template-edit-form';

const isSurveyTask = (type: string) => type === TASK_TEMPLATE_TYPES.SURVEY;

const StyledForm = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
}));

interface IFormValues extends FormikValues {
  name?: string;
  type?: string;
  notes?: string;
  executor_role?: number;
  reviewer_role?: number;
  survey_template?: number;
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Please select a type'),
  notes: yup.string(),
  executor_role: yup.number().required('Please select a executor role'),
  reviewer_role: yup.number().required('Please select a reviewer role'),
  survey_template: yup.number().when('type', (type: string) => {
    if (isSurveyTask(type)) {
      return yup.number().required('Please select a survey template');
    }
  }),
});

interface ITaskTemplateEditDialog {
  taskTemplateId: string | number;
  handleClose: () => void;
  handleSuccess: () => void;
}

const TaskTemplateEditDialog: React.FC<ITaskTemplateEditDialog> = ({
  taskTemplateId,
  handleClose,
  handleSuccess,
}) => {
  const {
    taskTemplate,
    isLoading: isLoadingTaskTemplate,
    error,
  } = useTaskTemplate(taskTemplateId);

  const { availableRoleTemplates, isLoading: isLoadingRoleTemplates } =
    useAvailableRoleTemplates();

  const { surveyTemplates, isLoading: isLoadingSurveyTemplates } =
    useSurveyTemplates();

  const onSubmit = async (values: IFormValues) => {
    try {
      let valuesToSubmit: ITaskTemplateEditPayload = {
        name: values.name,
        notes: values.notes,
      };

      if (taskTemplate.can_update) {
        valuesToSubmit = {
          ...values,
        };

        if (!isSurveyTask(values.type)) {
          // @ts-ignore
          valuesToSubmit = _.omit(values, 'survey_template');
        }
      }

      await updateTaskTemplate(taskTemplateId, valuesToSubmit);
      handleSuccess();
      handleClose();
      notifySuccess('Task template updated successfully.');
    } catch (e: any) {
      notifyApiError(e);
    }
  };

  const formik = useFormik<IFormValues>({
    initialValues: {
      name: _.get(taskTemplate, 'name', ''),
      type: _.get(taskTemplate, 'type', ''),
      notes: _.get(taskTemplate, 'notes', ''),
      // @ts-ignore
      executor_role: _.get(taskTemplate, 'executor_role.id', ''),
      // @ts-ignore
      reviewer_role: _.get(taskTemplate, 'reviewer_role.id', ''),
      // @ts-ignore
      survey_template: _.get(taskTemplate, 'survey_template.id', ''),
    },
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  if (isLoadingTaskTemplate || error) {
    return null;
  }

  return (
    <Dialog disableBackdropClick onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Edit task template</DialogTitle>
      <DialogContent>
        <form id={FORM_ID} onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <StyledForm>
              <Field
                name="name"
                component={FormTextField}
                required
                fullWidth
                autoFocus
                label="Name"
              />

              {!taskTemplate.can_update && (
                <Box sx={{ mt: 1, mb: 1 }}>
                  <Notes value="Some fields cannot be modified as this template is being used in a workflow." />
                </Box>
              )}

              <Field
                name="type"
                disabled
                component={FormTextField}
                select
                required
                fullWidth
                label="Type"
              >
                {_.values(TASK_TEMPLATE_TYPES).map((type: string) => (
                  <MenuItem key={type} value={type}>
                    {_.capitalize(type)}
                  </MenuItem>
                ))}
              </Field>
              {isSurveyTask(formik.values.type) && (
                <Field
                  name="survey_template"
                  component={FormTextField}
                  select
                  fullWidth
                  required
                  disabled={
                    isLoadingSurveyTemplates || !taskTemplate.can_update
                  }
                  label="Survey Template"
                >
                  {surveyTemplates.map(
                    (surveyTemplate: ISurveyTemplateListEntity) => (
                      <MenuItem
                        key={surveyTemplate.id}
                        value={surveyTemplate.id}
                      >
                        {surveyTemplate.name}
                      </MenuItem>
                    )
                  )}
                </Field>
              )}

              <Field
                name="notes"
                component={FormTextField}
                multiline
                fullWidth
                minRows={5}
                label="Notes"
              />

              <Divider>
                <Typography variant="subtitle1">Roles</Typography>
              </Divider>
              <Field
                name="executor_role"
                component={FormTextField}
                select
                required
                fullWidth
                disabled={isLoadingRoleTemplates || !taskTemplate.can_update}
                label="Executor role"
              >
                {availableRoleTemplates.map((template: IRoleTemplate) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Field>
              <Field
                name="reviewer_role"
                component={FormTextField}
                select
                required
                fullWidth
                disabled={isLoadingRoleTemplates || !taskTemplate.can_update}
                label="Reviewer role"
              >
                {availableRoleTemplates.map((template: IRoleTemplate) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Field>
            </StyledForm>
          </FormikProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
          // We need to tell the button the form ID to submit,
          // since the <form> itself is in a different scope (in the DialogContent).
          form={FORM_ID}
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskTemplateEditDialog;
