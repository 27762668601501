import { useState, useCallback, useMemo, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';

export const useDialog = (initialState: boolean = false) => {
  // This is a simple reusable hook which holds the state of whether a dialog is currently open or not.
  // It also has two helper functions for opening & closing.
  // It is meant to reduce the boilerplate code in components where we have dialogs.
  const [isOpen, setIsOpen] = useState<boolean>(initialState);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  return { isOpen, open, close };
};

export const useDialogWithQueryParam = (
  queryParamName: string,
  queryParamValueToSet: string = 'true'
) => {
  // This is a simple reusable hook which holds the state of whether a dialog is currently open or not.
  // The state of the dialog is controlled via a query param, which name is given to the hook itself.
  // This helps us abstract the behavior of opening a dialog when a certain query param is present in the URL.
  const [searchParams, setSearchParams] = useSearchParams();

  const currentQueryParamValue: string = useMemo(
    () => searchParams.get(queryParamName),
    [searchParams, queryParamName]
  );

  const shouldOpen = useMemo(
    () => currentQueryParamValue === queryParamValueToSet,
    [currentQueryParamValue, queryParamValueToSet]
  );

  const [isOpen, setIsOpen] = useState<boolean>(shouldOpen);

  const open = useCallback(
    () => setSearchParams({ [queryParamName]: queryParamValueToSet }),
    [queryParamName, setSearchParams, queryParamValueToSet]
  );
  const close = useCallback(() => {
    searchParams.delete(queryParamName);
    setSearchParams(searchParams);
  }, [searchParams, queryParamName, setSearchParams]);

  useEffect(() => {
    // This effect handles the `isOpen` state by looking at the query param value.
    setIsOpen(shouldOpen);
  }, [shouldOpen]);

  return { isOpen, open, close };
};

export const useMenu = () => {
  // This is a simple reusable hook which holds the anchor element state of a menu.
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isOpen = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const open = useCallback(
    (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    []
  );

  const close = useCallback(() => setAnchorEl(null), []);

  return { anchorEl, isOpen, open, close };
};
