import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';
import { getIn, FieldProps } from 'formik';

import TextField, { TextFieldProps } from 'components/TextField';

interface IFormTextField {
  hideErrors?: boolean;
}

// This component is meant to be used in Formik <Field> component.
// It automatically attaches error state on the MUI <TextField> component that gets rendered, thus saving a lot of boilerplate code throughout forms.
// Reference: https://firxworx.com/blog/coding/react/integrating-formik-with-react-material-ui-and-typescript/
const FormTextField: React.FC<IFormTextField & FieldProps & TextFieldProps> = ({
  field: { name: fieldName, value: fieldValue },
  form: { setFieldValue, setFieldTouched, touched, errors },
  hideErrors = false,
  ...rest
}) => {
  const { onBlur, onChange } = rest;

  const handleBlur = useCallback(
    event => {
      setFieldTouched(fieldName, true);
      onBlur && onBlur(event);
    },
    [setFieldTouched, fieldName, onBlur]
  );

  const handleChange = _.debounce(event => {
    setFieldValue(fieldName, event.target.value);
    onChange && onChange(event);
  }, 200);

  const isTouched = useMemo(
    () => getIn(touched, fieldName),
    [touched, fieldName]
  );
  const errorMessage = useMemo(
    () => getIn(errors, fieldName),
    [errors, fieldName]
  );

  return (
    <TextField
      name={fieldName}
      error={Boolean(!hideErrors && isTouched && errorMessage)}
      helperText={!hideErrors && isTouched && errorMessage}
      {...rest} // includes any Material-UI specific props
      defaultValue={fieldValue}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
};

export default FormTextField;
