import React, { useMemo } from 'react';

import _ from 'lodash';

import {
  Box,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Checkbox,
  Notes,
  DownloadableFile,
} from 'components';

import { ISurveyQuestion } from 'entities/Survey/sdk';

import { QUESTION_TYPES } from 'entities/Question/sdk';
import {
  QUESTION_TYPE_TO_ANSWER,
  isNumberQuestion,
  getNumberQuestionHelperText,
} from 'entities/Question/utils';

interface IAnswerReviewInput {
  question: ISurveyQuestion;
  answer: ISurveyQuestion['answers'][0];
}

const AnswerReviewInput: React.FC<IAnswerReviewInput> = ({
  question,
  answer,
}) => {
  const { fieldName } = QUESTION_TYPE_TO_ANSWER[question.type];
  const answerValue = useMemo(
    () => _.get(answer, fieldName),
    [answer, fieldName]
  );

  if (_.isNil(answer)) {
    return null;
  }

  if (question.type === QUESTION_TYPES.FREETEXT) {
    return <TextField disabled fullWidth value={answerValue} />;
  }

  if (isNumberQuestion(question.type)) {
    const value =
      question.type === QUESTION_TYPES.INTEGER
        ? parseInt(answerValue)
        : answerValue;

    const helperText = getNumberQuestionHelperText(question);

    return (
      <TextField disabled fullWidth value={value} helperText={helperText} />
    );
  }

  if (question.type === QUESTION_TYPES.SINGLE_SELECT) {
    return (
      <RadioGroup value={answerValue}>
        {_.map(question.options, (option: string, index: number) => (
          <FormControlLabel
            disabled
            key={index}
            value={option}
            label={option}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
    );
  }

  if (question.type === QUESTION_TYPES.MULTIPLE_SELECT) {
    return (
      <FormGroup>
        {_.map(question.options, (option: string, index: number) => (
          <FormControlLabel
            disabled
            key={index}
            value={option}
            label={option}
            control={<Checkbox checked={_.includes(answerValue, option)} />}
          />
        ))}
      </FormGroup>
    );
  }

  if (question.type === QUESTION_TYPES.BOOLEAN) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <RadioGroup value={answerValue}>
          <FormControlLabel
            disabled
            value={false}
            label="No"
            control={<Radio />}
          />
          <FormControlLabel
            disabled
            value={true}
            label="Yes"
            control={<Radio />}
          />
        </RadioGroup>
      </Box>
    );
  }

  if (question.type === QUESTION_TYPES.GEOPOINT) {
    return (
      <>
        <TextField
          disabled
          fullWidth
          label="Longitude"
          value={answerValue.longitude}
        />
        <TextField
          disabled
          fullWidth
          label="Latitude"
          value={answerValue.latitude}
          sx={{ marginTop: '10px' }}
        />
      </>
    );
  }

  if (question.type === QUESTION_TYPES.FILE_UPLOAD) {
    const { template_file } = question;
    const { file, feedback } = answerValue;

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        {template_file && (
          <DownloadableFile
            title="Template"
            url={template_file.url}
            name={template_file.original_file_name}
          />
        )}
        <DownloadableFile
          title="Answer"
          url={file.url}
          name={file.original_file_name}
        />
        {feedback && <Notes value={`Feedback: ${feedback}`} />}
      </Box>
    );
  }

  // We should never reach this line!
  return null;
};

export default AnswerReviewInput;
