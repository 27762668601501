import React, { useMemo, useCallback } from 'react';

import { getIn, FieldProps } from 'formik';

import { formatDate } from 'utils/date';

import TextField from 'components/TextField';
import DesktopDatePicker, {
  DesktopDatePickerProps,
} from 'components/DesktopDatePicker';

const DateField: React.FC<FieldProps & DesktopDatePickerProps<any, any>> = ({
  form,
  field,
  disabled,
  ...rest
}) => {
  const { name: fieldName } = field;
  const { touched, errors, setFieldValue, setFieldTouched } = form;

  const touchField = useCallback(
    () => setFieldTouched(fieldName, true),
    [fieldName, setFieldTouched]
  );

  const isTouched = useMemo(
    () => getIn(touched, fieldName),
    [touched, fieldName]
  );
  const errorMessage = useMemo(
    () => getIn(errors, fieldName) && 'Invalid date',
    [errors, fieldName]
  );

  const shouldShowError = useMemo(
    () => isTouched && !disabled,
    [isTouched, disabled]
  );

  return (
    <DesktopDatePicker
      {...field}
      {...rest}
      disabled={disabled}
      clearable
      onOpen={touchField}
      onChange={(value: Date) => setFieldValue(fieldName, formatDate(value))}
      renderInput={params => (
        <TextField
          {...params}
          onFocus={touchField}
          error={Boolean(shouldShowError && errorMessage)}
          helperText={shouldShowError && errorMessage}
        />
      )}
    />
  );
};

export default DateField;
