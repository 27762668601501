import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'components';
import DataGrid, {
  transformToColumns,
  GridRowParams,
} from 'components/DataGrid';

import { TASK_DETAIL_URL } from 'config/urls';
import { buildDetailUrl } from 'utils/urls';
import { notifyApiError } from 'utils/notifications';

import { useTasksToDo, ITaskToDo, claimTask } from 'entities/Task/sdk';
import { constructFullRole } from 'entities/Task/utils';

const columnsFields: string[] = [
  'task',
  'executor',
  'organization',
  'reviewer',
  'type',
  'workflow',
];

const TasksToDoList: React.FC = () => {
  const navigate = useNavigate();
  const { tasks, isLoading, error } = useTasksToDo();

  const rows = useMemo(
    () =>
      _.map(tasks, (task: ITaskToDo) => {
        const {
          id,
          template,
          executor,
          executor_role,
          reviewer,
          reviewer_role,
          workflow,
        } = task;

        const executorRole = executor_role.name;
        const executorEmail = _.get(executor, 'email');
        const fullExecutor = constructFullRole(executorRole, executorEmail);

        const reviewerRole = reviewer_role.name;
        const reviewerEmail = _.get(reviewer, 'email');
        const fullReviewer = constructFullRole(reviewerRole, reviewerEmail);

        return {
          id,
          task: template.name,
          // Executor & reviewer emails are not displayed in the table but rather used in the `handleRowClick` function to determine whether the current user needs to claim the task
          executorEmail,
          reviewerEmail,
          // -------------------
          executor: fullExecutor,
          reviewer: fullReviewer,
          organization: _.get(executor, 'organization.short_name', 'N/A'),
          type: _.capitalize(template.type),
          workflow: workflow.name,
        };
      }),
    [tasks]
  );

  const handleRowClick = useCallback(
    async (params: GridRowParams) => {
      const { row: task } = params;

      try {
        if (!task.reviewerEmail || !task.executorEmail) {
          await claimTask(task.id);
        }

        navigate(buildDetailUrl(TASK_DETAIL_URL, task.id));
      } catch (e: any) {
        notifyApiError(e);
      }
    },
    [navigate]
  );

  if (error) {
    return <Typography variant="subtitle2">Failed to fetch tasks</Typography>;
  }

  return (
    <DataGrid
      loading={isLoading}
      columns={transformToColumns(columnsFields)}
      rows={rows}
      onRowClick={handleRowClick}
    />
  );
};

export default TasksToDoList;
