import React, { useMemo } from 'react';

import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { Paper, Divider, Typography } from 'components';
import DataGrid, { transformToColumns } from 'components/DataGrid';

import { useWorkflowParticipantsList } from 'entities/Workflow/sdk';

const StyledWrapper = styled(Paper)(() => ({
  padding: '15px',
  width: '100%',
}));

const columnsFields: string[] = [
  'email',
  'organization',
  'roles',
  'currentTask',
];

const WorkflowParticipants: React.FC = () => {
  const { id: workflowId } = useParams();

  const { participants, isLoading, error } =
    useWorkflowParticipantsList(workflowId);

  const rows = useMemo(
    () =>
      _.map(
        participants,
        ({ email, organization, workflow_roles, current_task }) => {
          const orgName = _.get(organization, 'name', 'N/A');
          const roles = _(workflow_roles).map('name').join(', ');
          const currentTask = _.get(current_task, 'name', 'N/A');

          return {
            id: email,
            email,
            organization: orgName,
            roles,
            currentTask,
          };
        }
      ),
    [participants]
  );

  if (error) {
    return (
      <Typography variant="subtitle2">Failed to load participants.</Typography>
    );
  }

  return (
    <StyledWrapper>
      <Divider>
        <Typography variant="h6" align="center">
          Participants
        </Typography>
      </Divider>

      <DataGrid
        loading={isLoading}
        columns={transformToColumns(columnsFields)}
        rows={rows}
        disableSelectionOnClick
      />
    </StyledWrapper>
  );
};

export default WorkflowParticipants;
