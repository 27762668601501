import React from 'react';

import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/material/styles';

import { colors } from 'theme/palette';
import { Box, Typography, Button } from 'components';
import { SentimentDissatisfiedRoundedIcon } from 'icons';

const SadIcon = styled(SentimentDissatisfiedRoundedIcon)(() => ({
  fontSize: '120px',
  color: colors.mainOrange,
  transform: 'rotate(0)',
  '@keyframes rotate': {
    '25%': {
      transform: 'rotate(-10deg)',
    },
    '75%': {
      transform: 'rotate(10deg)',
    },
  },
  animation: 'rotate 2s',
  animationIterationCount: 1,
}));

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: '80vh',
        display: 'flex',
        gap: '30px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="h2">Page not found (404)</Typography>

      <SadIcon />

      <Button variant="contained" onClick={() => navigate('/')}>
        Back to homepage
      </Button>
    </Box>
  );
};

export default NotFound;
