import React, { useMemo, useState, useEffect, useCallback } from 'react';

import _ from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AddFab, Typography, Protected } from 'components';
import DataGrid, {
  transformToColumns,
  GridRowParams,
  GridColDef,
  GridActionsCellItem,
} from 'components/DataGrid';

import { WORKFLOW_TEMPLATE_DETAIL_URL } from 'config/urls';
import { EditRoundedIcon, ContentCopyRoundedIcon } from 'icons';

import { buildDetailUrl } from 'utils/urls';
import { useDialogWithQueryParam } from 'utils/hooks';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';
import { useWorkflowTemplates } from 'entities/WorkflowTemplate/sdk';

import WorkflowTemplateCreateDialog from 'entities/WorkflowTemplate/components/CreateDialog';
import WorkflowTemplateEditDialog from 'entities/WorkflowTemplate/components/EditDialog';

const EDIT_QUERY_PARAM = 'edit';

const columnsFields: string[] = ['workflowTemplate', 'actions'];

const WorkflowTemplatesList: React.FC = () => {
  const navigate = useNavigate();

  const { workflowTemplates, error, isLoading, refetch } =
    useWorkflowTemplates();

  // We use search params here because the workflow template group ID can be preselected from the URL params
  const [searchParams] = useSearchParams();

  const {
    isOpen: isCreateDialogOpen,
    open: openCreateDialog,
    close: closeCreateDialog,
  } = useDialogWithQueryParam('create');

  const [editWorkflowTemplateId, setEditWorkflowTemplateId] = useState<string>(
    searchParams.get(EDIT_QUERY_PARAM)
  );

  const {
    isOpen: isEditDialogOpen,
    open: openEditDialog,
    close: closeEditDialog,
  } = useDialogWithQueryParam(EDIT_QUERY_PARAM, editWorkflowTemplateId);

  useEffect(() => {
    // Open/close the edit dialog based on the state variable, which can be either preset from the query params or manually set from the table edit handler.
    if (!_.isEmpty(editWorkflowTemplateId)) {
      openEditDialog();
    } else {
      closeEditDialog();
    }
  }, [editWorkflowTemplateId, openEditDialog, closeEditDialog]);

  const [duplicateWorkflowTemplateId, setDuplicateWorkflowTemplateId] =
    useState<string>();

  useEffect(() => {
    if (!_.isEmpty(duplicateWorkflowTemplateId)) {
      openCreateDialog();
    }
  }, [duplicateWorkflowTemplateId, openCreateDialog]);

  const handleCreateClose = useCallback(() => {
    setDuplicateWorkflowTemplateId(null);
    closeCreateDialog();
  }, [closeCreateDialog]);

  const rows = useMemo(
    () =>
      _.map(workflowTemplates, workflowTemplate => {
        const { id, name } = workflowTemplate;

        return {
          id,
          workflowTemplate: name,
        };
      }),
    [workflowTemplates]
  );

  const handleRowClick = useCallback(
    (params: GridRowParams) =>
      navigate(buildDetailUrl(WORKFLOW_TEMPLATE_DETAIL_URL, params.row.id)),
    [navigate]
  );

  const columns: GridColDef[] = useMemo(
    () =>
      transformToColumns(columnsFields).map((col: GridColDef) => {
        if (col.field === 'actions') {
          return {
            ...col,
            type: 'actions',
            headerName: '',
            maxWidth: 50,
            getActions: (params: GridRowParams) => [
              <GridActionsCellItem
                icon={<EditRoundedIcon />}
                onClick={() => setEditWorkflowTemplateId(params.id.toString())}
                label="Edit"
                showInMenu
              />,
              <GridActionsCellItem
                icon={<ContentCopyRoundedIcon />}
                onClick={() =>
                  setDuplicateWorkflowTemplateId(params.id.toString())
                }
                label="Duplicate"
                showInMenu
              />,
            ],
          };
        }

        return col;
      }),
    []
  );

  if (error) {
    return (
      <Typography variant="subtitle2">
        Failed to fetch workflow templates.
      </Typography>
    );
  }

  return (
    <>
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows}
        onRowClick={handleRowClick}
      />

      {isEditDialogOpen && !_.isNil(editWorkflowTemplateId) && (
        <WorkflowTemplateEditDialog
          workflowTemplateId={editWorkflowTemplateId}
          handleClose={() => setEditWorkflowTemplateId(null)}
          handleSuccess={refetch}
        />
      )}

      <Protected
        entity={PERMISSION_ENTITIES.TEMPLATES}
        permission={ENTITY_PERMISSIONS.ADD}
      >
        <AddFab onClick={openCreateDialog} />
        {isCreateDialogOpen && (
          <WorkflowTemplateCreateDialog
            handleClose={handleCreateClose}
            duplicateId={duplicateWorkflowTemplateId}
          />
        )}
      </Protected>
    </>
  );
};

export default WorkflowTemplatesList;
