import React from 'react';

import { Draggable } from 'react-beautiful-dnd';
import { Field } from 'formik';

import { styled } from '@mui/material/styles';

import { FormTextField, IconButton } from 'components';
import { CloseRoundedIcon, DragHandleRoundedIcon } from 'icons';

const StyledSelectQuestionOption = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  marginBottom: '10px',
}));

interface ISelectQuestionOption {
  uuid: string;
  optionIndex: number;
  questionFormPath: string;
  handleRemove: () => void;
  disableRemove: boolean;
}

const SelectQuestionOption: React.FC<ISelectQuestionOption> = ({
  uuid,
  optionIndex,
  questionFormPath,
  handleRemove,
  disableRemove,
}) => (
  <Draggable draggableId={uuid} index={optionIndex}>
    {provided => (
      <StyledSelectQuestionOption
        ref={provided.innerRef}
        {...provided.draggableProps}
      >
        <div {...provided.dragHandleProps}>
          <IconButton disableRipple>
            <DragHandleRoundedIcon />
          </IconButton>
        </div>
        <Field
          name={`${questionFormPath}.${optionIndex}.value`}
          component={FormTextField}
          label={`Option #${optionIndex + 1}`}
          required
          fullWidth
          autoFocus
        />
        <IconButton onClick={handleRemove} disabled={disableRemove}>
          <CloseRoundedIcon />
        </IconButton>
      </StyledSelectQuestionOption>
    )}
  </Draggable>
);

export default SelectQuestionOption;
