import { useMemo } from 'react';

import _ from 'lodash';

import { Badge } from 'components';

import {
  AccountTreeRoundedIcon,
  AssignmentRoundedIcon,
  AutoAwesomeMosaicRoundedIcon,
  GroupRoundedIcon,
  InboxRoundedIcon,
  PollRoundedIcon,
  WorkspacesRoundedIcon,
  CableRoundedIcon,
  TaskRoundedIcon,
} from 'icons';

import { RESOURCES } from 'utils/constants';
import {
  SURVEY_TEMPLATES_LIST_URL,
  TASKS_LIST_URL,
  USER_GROUPS_LIST_URL,
  USERS_LIST_URL,
  WORKFLOW_TEMPLATE_GROUPS_LIST_URL,
  WORKFLOW_TEMPLATES_LIST_URL,
  WORKFLOWS_LIST_URL,
  COMPONENTS_LIST_URL,
  TASK_TEMPLATES_LIST_URL,
} from 'config/urls';

import { useSignedUser } from 'entities/Auth/sdk';
import {
  ENTITY_PERMISSIONS,
  PERMISSION_ENTITIES,
} from 'entities/EntityPermission/sdk';
import { hasEntityPermission } from 'entities/EntityPermission/utils';
import { useTasksToDo } from 'entities/Task/sdk';

export interface IRouteGroup {
  name: string;
  routes: IRouteEntry[];
}

export interface IRouteEntry {
  to: string;
  icon: JSX.Element;
  text: string;
  entity: string;
  permission: string;
}

export const useRouteGroupsForSignedUser = () => {
  const { user } = useSignedUser();

  const { tasks } = useTasksToDo();

  const tasksIcon = useMemo(() => {
    const icon = <InboxRoundedIcon />;

    if (!_.isEmpty(tasks)) {
      return (
        <Badge badgeContent={_.size(tasks)} color="primary">
          {icon}
        </Badge>
      );
    }

    return icon;
  }, [tasks]);

  const routeGroups: IRouteGroup[] = useMemo(
    () => [
      {
        name: 'Workflows',
        routes: [
          {
            to: TASKS_LIST_URL,
            icon: tasksIcon,
            text: RESOURCES.TASKS,
            entity: PERMISSION_ENTITIES.WORKFLOWS,
            permission: ENTITY_PERMISSIONS.VIEW,
          },
          {
            to: WORKFLOWS_LIST_URL,
            icon: <AccountTreeRoundedIcon />,
            text: RESOURCES.WORKFLOWS,
            entity: PERMISSION_ENTITIES.WORKFLOWS,
            permission: ENTITY_PERMISSIONS.VIEW,
          },
        ],
      },
      {
        name: 'Users',
        routes: [
          {
            to: USERS_LIST_URL,
            icon: <GroupRoundedIcon />,
            text: RESOURCES.USERS,
            entity: PERMISSION_ENTITIES.USERS,
            permission: ENTITY_PERMISSIONS.VIEW,
          },
          {
            to: USER_GROUPS_LIST_URL,
            icon: <WorkspacesRoundedIcon />,
            text: RESOURCES.USER_GROUPS,
            entity: PERMISSION_ENTITIES.USER_GROUPS,
            permission: ENTITY_PERMISSIONS.VIEW,
          },
        ],
      },
      {
        name: 'Components',
        routes: [
          {
            to: COMPONENTS_LIST_URL,
            icon: <CableRoundedIcon />,
            text: RESOURCES.COMPONENTS,
            entity: PERMISSION_ENTITIES.COMPONENTS,
            permission: ENTITY_PERMISSIONS.VIEW,
          },
        ],
      },
      {
        name: 'Templates',
        routes: [
          {
            to: WORKFLOW_TEMPLATE_GROUPS_LIST_URL,
            icon: <AutoAwesomeMosaicRoundedIcon />,
            text: RESOURCES.WORKFLOW_TEMPLATE_GROUPS,
            entity: PERMISSION_ENTITIES.TEMPLATES,
            permission: ENTITY_PERMISSIONS.VIEW,
          },
          {
            to: WORKFLOW_TEMPLATES_LIST_URL,
            icon: <AssignmentRoundedIcon />,
            text: RESOURCES.WORKFLOW_TEMPLATES,
            entity: PERMISSION_ENTITIES.TEMPLATES,
            permission: ENTITY_PERMISSIONS.VIEW,
          },
          {
            to: SURVEY_TEMPLATES_LIST_URL,
            icon: <PollRoundedIcon />,
            text: RESOURCES.SURVEY_TEMPLATES,
            entity: PERMISSION_ENTITIES.TEMPLATES,
            permission: ENTITY_PERMISSIONS.VIEW,
          },
          {
            to: TASK_TEMPLATES_LIST_URL,
            icon: <TaskRoundedIcon />,
            text: RESOURCES.TASK_TEMPLATES,
            entity: PERMISSION_ENTITIES.TEMPLATES,
            permission: ENTITY_PERMISSIONS.VIEW,
          },
        ],
      },
    ],
    [tasksIcon]
  );

  return useMemo(
    () =>
      // We're filtering out all routes from the predefined ROUTE_GROUPS, based on the signed user permissions (via `hasEntityPermission`)
      // Then we get only the groups that have routes in them so that we do not render empty groups in the sidebar.
      _(routeGroups)
        .map((routeGroup: IRouteGroup) => ({
          ...routeGroup,
          routes: _.filter(routeGroup.routes, (route: IRouteEntry) =>
            hasEntityPermission(user, route.entity, route.permission)
          ),
        }))
        .filter((routeGroup: IRouteGroup) => !_.isEmpty(routeGroup.routes))
        .value(),
    [user, routeGroups]
  );
};
