import React, { useMemo, useCallback, useEffect } from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { styled } from '@mui/material/styles';
import { useFormik, Field, FormikProvider } from 'formik';

import { colors } from 'theme/palette';
import {
  Box,
  Typography,
  FormTextField,
  MenuItem,
  Button,
  Notes,
} from 'components';

import { IWorkflowOfferedComponent } from 'entities/Workflow/sdk';
import { getTotalPriceRepresentation } from 'entities/Order/utils';

const StyledForm = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '20px',
  border: `2px solid ${colors.grey0}`,
}));

const StyledHardwareComponent = styled('div')(() => ({
  padding: '20px',
  backgroundColor: colors.grey0,
}));

const StyledQuantityField = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
}));

interface IFormValues {
  category: string;
  manufacturer: string;
  componentId: number;
  quantity: number;
}

const validationSchema = yup.object({
  quantity: yup
    .number()
    .positive('Please provide a positive number')
    .integer('Please provide a valid integer')
    .required('Quantity is required'),
});

interface IComponentSelect {
  offeredComponents: IWorkflowOfferedComponent[];
  onAdd: (component: IWorkflowOfferedComponent, quantity: number) => void;
}

const ComponentSelect: React.FC<IComponentSelect> = ({
  offeredComponents,
  onAdd,
}) => {
  const categoryOptions = useMemo(
    () => _(offeredComponents).map('category_name').uniq().value(),
    [offeredComponents]
  );

  const manufacturerOptions = useMemo(
    () => _(offeredComponents).map('manufacturer_name').uniq().value(),
    [offeredComponents]
  );

  const onSubmit = useCallback(
    (values: IFormValues, { setFieldValue, setSubmitting }) => {
      const { componentId, quantity } = values;
      const component = _.find(offeredComponents, { id: componentId });
      onAdd(component, quantity);

      // Clear the selected component upon adding
      // TODO: Do we want this to happen?
      setFieldValue('componentId', '');

      setSubmitting(false);
    },
    [offeredComponents, onAdd]
  );

  const formik = useFormik<IFormValues>({
    initialValues: {
      category: '',
      manufacturer: '',
      // @ts-ignore
      componentId: '',
      quantity: 1,
    },
    validationSchema,
    onSubmit,
  });

  const { values, errors, setFieldValue } = formik;

  const filteredComponents = useMemo(
    () =>
      _.filter(offeredComponents, {
        category_name: values.category,
        manufacturer_name: values.manufacturer,
      }),
    [offeredComponents, values.category, values.manufacturer]
  );

  const selectedComponent = useMemo(
    () => _.find(offeredComponents, { id: values.componentId }),
    [offeredComponents, values]
  );

  const totalPriceForSelectedComponent = useMemo(() => {
    const quantity = values.quantity;
    const latestPrice = _.get(selectedComponent, 'latest_price.amount');

    if (_.isNil(latestPrice) || errors.quantity) {
      return 'Invalid price';
    }

    return getTotalPriceRepresentation(latestPrice, quantity);
  }, [selectedComponent, values.quantity, errors.quantity]);

  // Clear manufacturer when category is changed (cascading inputs)
  useEffect(() => {
    setFieldValue('manufacturer', '');
  }, [setFieldValue, values.category]);

  // Clear component ID when manufacturer is changed (cascading inputs)
  useEffect(() => {
    setFieldValue('componentId', '');
  }, [setFieldValue, values.manufacturer]);

  // Reset quantity back to 1 when another component is selected
  useEffect(() => {
    setFieldValue('quantity', 1);
  }, [setFieldValue, values.componentId]);

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <FormikProvider value={formik}>
        <Field
          name="category"
          component={FormTextField}
          select
          fullWidth
          required
          label="Category"
        >
          {_.map(categoryOptions, (value: string) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Field>

        <Field
          name="manufacturer"
          component={FormTextField}
          select
          fullWidth
          required
          label="Manufacturer"
          disabled={!values.category}
        >
          {_.map(manufacturerOptions, (value: string) => (
            <MenuItem key={value} value={value}>
              {value}
            </MenuItem>
          ))}
        </Field>

        <Field
          name="componentId"
          component={FormTextField}
          select
          fullWidth
          required
          label="Model"
          disabled={!values.manufacturer}
        >
          {_.map(filteredComponents, (component: IWorkflowOfferedComponent) => (
            <MenuItem key={component.id} value={component.id}>
              {component.model}
            </MenuItem>
          ))}
        </Field>

        {selectedComponent && (
          <StyledHardwareComponent>
            <Typography variant="h6">
              {selectedComponent.manufacturer_name} - {selectedComponent.model}
            </Typography>

            <Notes value={selectedComponent.description} />

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
            >
              <StyledQuantityField>
                <Typography variant="subtitle1">
                  <strong>Quantity</strong>
                </Typography>
                <Field
                  name="quantity"
                  component={FormTextField}
                  required
                  hideErrors
                  type="number"
                  inputProps={{ min: 1 }}
                  style={{ width: '80px' }}
                />
                <Typography variant="subtitle1">x</Typography>
                <Typography variant="subtitle1">
                  ${selectedComponent.latest_price.amount}
                </Typography>
                <Typography variant="subtitle1">=</Typography>
                <Typography variant="subtitle1">
                  <strong>{totalPriceForSelectedComponent}</strong>
                </Typography>
              </StyledQuantityField>

              <Button
                type="submit"
                variant="contained"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                Add to Order
              </Button>
            </Box>
          </StyledHardwareComponent>
        )}
      </FormikProvider>
    </StyledForm>
  );
};

export default ComponentSelect;
