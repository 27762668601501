import { TASKS_LIST_URL, TASK_DETAIL_URL } from 'config/urls';
import { IRouteWithEntityPermissions } from 'config/routes';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import ToDoList from './ToDoList';
import Detail from './Detail';

const routes: IRouteWithEntityPermissions[] = [
  {
    path: TASKS_LIST_URL,
    entity: PERMISSION_ENTITIES.WORKFLOWS,
    permission: ENTITY_PERMISSIONS.VIEW,
    element: <ToDoList />,
  },
  {
    path: TASK_DETAIL_URL,
    entity: PERMISSION_ENTITIES.WORKFLOWS,
    permission: ENTITY_PERMISSIONS.VIEW,
    element: <Detail />,
  },
];

export default routes;
