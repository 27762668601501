import React from 'react';

import _ from 'lodash';
import { useParams, Link } from 'react-router-dom';

import { colors } from 'theme/palette';
import {
  WORKFLOW_TEMPLATE_GROUP_DETAIL_URL,
  WORKFLOW_TEMPLATES_LIST_URL,
} from 'config/urls';
import {
  Typography,
  Divider,
  Box,
  Paper,
  BooleanIcon,
  Protected,
  EditFab,
  Breadcrumbs,
} from 'components';
import { RESOURCES } from 'utils/constants';
import { buildDetailUrl } from 'utils/urls';
import { joinWithDelimiter } from 'utils/common';
import { useDialogWithQueryParam } from 'utils/hooks';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';

import { ITaskTemplateListEntity } from 'entities/TaskTemplate/sdk';
import TaskTemplateTitle from 'entities/TaskTemplate/components/TaskTemplateTitle';

import { useWorkflowTemplate } from 'entities/WorkflowTemplate/sdk';
import WorkflowTemplateEditDialog from 'entities/WorkflowTemplate/components/EditDialog';

const WorkflowTemplateDetail: React.FC = () => {
  const { id: workflowTemplateId } = useParams();

  const { workflowTemplate, isLoading, error, refetch } =
    useWorkflowTemplate(workflowTemplateId);

  const {
    isOpen: isEditDialogOpen,
    open: openEditDialog,
    close: closeEditDialog,
  } = useDialogWithQueryParam('edit');

  if (isLoading) {
    return (
      <Typography variant="subtitle2">Loading workflow template...</Typography>
    );
  }

  if (error) {
    return (
      <Typography variant="subtitle2">
        Failed to fetch workflow template.
      </Typography>
    );
  }

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            name: RESOURCES.WORKFLOW_TEMPLATES,
            url: WORKFLOW_TEMPLATES_LIST_URL,
          },
          { name: workflowTemplate.name },
        ]}
      />

      <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
        <Typography variant="subtitle1">Single user workflow: </Typography>
        <BooleanIcon value={workflowTemplate.single_user_workflow} />
      </Box>

      {!_.isEmpty(workflowTemplate.workflow_template_groups) && (
        <>
          <Divider>
            <Typography variant="h6">Workflow template groups</Typography>
          </Divider>

          <ul>
            {_.map(
              workflowTemplate.workflow_template_groups,
              workflowTemplateGroup => (
                <li key={workflowTemplateGroup.id}>
                  <Link
                    to={buildDetailUrl(
                      WORKFLOW_TEMPLATE_GROUP_DETAIL_URL,
                      workflowTemplateGroup.id
                    )}
                  >
                    {workflowTemplateGroup.name}
                  </Link>
                </li>
              )
            )}
          </ul>
        </>
      )}

      <Divider>
        <Typography variant="h6">Role templates</Typography>
      </Divider>

      <Typography variant="subtitle1">
        Role templates:{' '}
        <strong>
          {joinWithDelimiter(workflowTemplate.role_templates, 'name')}
        </strong>
      </Typography>
      <Typography variant="subtitle1">
        Main role: <strong>{workflowTemplate.main_role.name}</strong>
      </Typography>

      {!_.isEmpty(workflowTemplate.role_templates_with_read_access) && (
        <Typography variant="subtitle1">
          Roles with read access:{' '}
          <strong>
            {joinWithDelimiter(
              workflowTemplate.role_templates_with_read_access,
              'name'
            )}
          </strong>
        </Typography>
      )}
      {!_.isEmpty(workflowTemplate.role_templates_with_write_access) && (
        <Typography variant="subtitle1">
          Roles with write access:{' '}
          <strong>
            {joinWithDelimiter(
              workflowTemplate.role_templates_with_write_access,
              'name'
            )}
          </strong>
        </Typography>
      )}

      <Divider>
        <Typography variant="h6">Task templates</Typography>
      </Divider>

      <Box
        sx={{
          display: 'flex',
          gap: '10px',
        }}
      >
        {_.map(
          workflowTemplate.task_templates,
          (taskTemplate: ITaskTemplateListEntity, index: number) => (
            <Paper
              key={index}
              sx={{
                width: '500px',
                padding: '10px',
                backgroundColor: colors.grey1,
              }}
            >
              <Divider sx={{ marginBottom: '10px' }} variant="middle">
                <strong>#{index + 1}</strong>
              </Divider>
              <TaskTemplateTitle taskTemplate={taskTemplate} />
            </Paper>
          )
        )}
      </Box>

      <Protected
        entity={PERMISSION_ENTITIES.TEMPLATES}
        permission={ENTITY_PERMISSIONS.CHANGE}
      >
        <EditFab onClick={openEditDialog} />
        {isEditDialogOpen && (
          <WorkflowTemplateEditDialog
            workflowTemplateId={workflowTemplateId}
            handleClose={closeEditDialog}
            handleSuccess={refetch}
          />
        )}
      </Protected>
    </>
  );
};

export default WorkflowTemplateDetail;
