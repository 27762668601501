import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';
import { useNavigate, createSearchParams } from 'react-router-dom';

import { Typography, AddFab, Protected } from 'components';
import DataGrid, {
  transformToColumns,
  GridRowParams,
  GridColDef,
  GridActionsCellItem,
} from 'components/DataGrid';

import {
  SURVEY_TEMPLATE_CREATE_URL,
  SURVEY_TEMPLATE_DETAIL_URL,
  SURVEY_TEMPLATE_EDIT_URL,
} from 'config/urls';
import { buildDetailUrl } from 'utils/urls';
import { EditRoundedIcon, ContentCopyRoundedIcon } from 'icons';

import {
  PERMISSION_ENTITIES,
  ENTITY_PERMISSIONS,
} from 'entities/EntityPermission/sdk';
import { useSurveyTemplates } from 'entities/SurveyTemplate/sdk';

const columnsFields: string[] = ['surveyTemplate', 'actions'];

const SurveyTemplatesList: React.FC = () => {
  const navigate = useNavigate();
  const { surveyTemplates, isLoading, error } = useSurveyTemplates();

  const navigateToDuplicatePage = useCallback(
    (duplicateId: string) =>
      navigate({
        pathname: SURVEY_TEMPLATE_CREATE_URL,
        search: createSearchParams({ duplicateId }).toString(),
      }),
    [navigate]
  );

  const rows = useMemo(
    () =>
      _.map(surveyTemplates, surveyTemplate => {
        const { id, name } = surveyTemplate;

        return {
          id,
          surveyTemplate: name,
        };
      }),
    [surveyTemplates]
  );

  const handleRowClick = useCallback(
    (params: GridRowParams) =>
      navigate(buildDetailUrl(SURVEY_TEMPLATE_DETAIL_URL, params.row.id)),
    [navigate]
  );

  const columns: GridColDef[] = useMemo(
    () =>
      transformToColumns(columnsFields).map((col: GridColDef) => {
        if (col.field === 'actions') {
          return {
            ...col,
            type: 'actions',
            headerName: '',
            maxWidth: 50,
            getActions: (params: GridRowParams) => [
              <GridActionsCellItem
                icon={<EditRoundedIcon />}
                onClick={() =>
                  navigate(
                    buildDetailUrl(SURVEY_TEMPLATE_EDIT_URL, params.row.id)
                  )
                }
                label="Edit"
                showInMenu
              />,
              <GridActionsCellItem
                icon={<ContentCopyRoundedIcon />}
                onClick={() => navigateToDuplicatePage(params.row.id)}
                label="Duplicate"
                showInMenu
              />,
            ],
          };
        }

        return col;
      }),
    [navigate, navigateToDuplicatePage]
  );

  if (error) {
    return (
      <Typography variant="subtitle2">
        Failed to fetch survey templates.
      </Typography>
    );
  }

  return (
    <>
      <DataGrid
        loading={isLoading}
        columns={columns}
        rows={rows}
        onRowClick={handleRowClick}
      />
      <Protected
        entity={PERMISSION_ENTITIES.TEMPLATES}
        permission={ENTITY_PERMISSIONS.ADD}
      >
        <AddFab onClick={() => navigate(SURVEY_TEMPLATE_CREATE_URL)} />
      </Protected>
    </>
  );
};

export default SurveyTemplatesList;
