import React from 'react';

import { useParams } from 'react-router-dom';
import { ReactFlowProvider } from 'react-flow-renderer';

import { RESOURCES } from 'utils/constants';
import { WORKFLOWS_LIST_URL } from 'config/urls';
import { Box, Typography, Breadcrumbs } from 'components';

import { useWorkflowDetail } from 'entities/Workflow/sdk';

import GeneralInfo from './components/GeneralInfo';
import Flow from './components/Flow';
import Participants from './components/Participants';
import Documents from './components/Documents';
import Components from './components/Components';

const WorkflowDetail: React.FC = () => {
  const { id: workflowId } = useParams();

  const { workflow, isLoading, error } = useWorkflowDetail(workflowId);

  if (isLoading) {
    return <Typography variant="subtitle2">Loading workflow...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="subtitle2">Failed to load workflow.</Typography>
    );
  }

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          { name: RESOURCES.WORKFLOWS, url: WORKFLOWS_LIST_URL },
          { name: workflow.name },
        ]}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
          padding: '15px',
        }}
      >
        <GeneralInfo workflow={workflow} />

        <ReactFlowProvider>
          <Flow />
        </ReactFlowProvider>

        <Participants />
        <Documents />
        <Components />
      </Box>
    </>
  );
};

export default WorkflowDetail;
