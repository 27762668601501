import { useMemo, useCallback, useState } from 'react';

import _ from 'lodash';
import {
  useReactFlow,
  Node,
  Edge,
  Position,
  MarkerType,
  XYPosition,
} from 'react-flow-renderer';

import { colors } from 'theme/palette';
import { pairwise } from 'utils/common';
import { Box, Typography } from 'components';
import { GridRowParams } from 'components/DataGrid';

import { TASK_STATUS } from 'entities/Task/sdk';

import {
  useWorkflowOverview,
  IWorkflowOverviewTaskTemplate,
  IWorkflowTask,
} from 'entities/Workflow/sdk';

import {
  NODES_ON_A_SINGLE_LINE,
  NODE_WIDTH_IN_PX,
  NODE_HEIGHT_IN_PX,
  ZOOM_SPEED_IN_MS,
  ZOOM_DELAY_SPEED_IN_MS,
  COMMON_NODE_STYLES,
} from './constants';

import TaskStat from './components/TaskStat';

const generateCoordinatesData = (numItems: number): XYPosition[] => {
  let [x, y] = [0, 0];

  // Buffer by which we multiply the X/Y coordinates so they don't stack next to each other
  const [xBuffer, yBuffer] = [1.5, 2];

  return _.map(_.range(numItems), (i: number) => {
    // Move items to the right at a fixed step
    x = (i % NODES_ON_A_SINGLE_LINE) * NODE_WIDTH_IN_PX * xBuffer;

    if (i % NODES_ON_A_SINGLE_LINE === 0) {
      // Once we reach the end of a line, go to the next one
      y += NODE_HEIGHT_IN_PX * yBuffer;
    }

    return { x, y };
  });
};

const generatePositionsData = (
  numItems: number
): { sourcePosition?: Position; targetPosition?: Position }[] => {
  return _.map(_.range(1, numItems + 1), (i: number) => {
    if (i % NODES_ON_A_SINGLE_LINE === 0) {
      // Go to the next line
      return { sourcePosition: Position.Bottom, targetPosition: Position.Left };
    } else if (i % NODES_ON_A_SINGLE_LINE === 1) {
      // Start on the next line
      return { sourcePosition: Position.Right, targetPosition: Position.Top };
    }

    // Default behavior: linear
    return { sourcePosition: Position.Right, targetPosition: Position.Left };
  });
};

const generateNodes = (taskTemplatesData: IWorkflowOverviewTaskTemplate[]) => {
  const numItems = _.size(taskTemplatesData);

  const positionsData = generatePositionsData(numItems);
  const coordinatesData = generateCoordinatesData(numItems);

  return _.map(
    _.zip(taskTemplatesData, coordinatesData, positionsData),
    ([taskTemplate, coordinates, position], index) => {
      const completedTasksPercentage = `${Math.round(
        (taskTemplate.completed_tasks_count / taskTemplate.all_tasks_count) *
          100
      )}%`;

      // Reference: https://reactflow.dev/docs/api/nodes/node-options/
      let type = 'default';

      if (index === 0) {
        type = 'input';
      } else if (index === numItems - 1) {
        type = 'output';
      }

      return {
        id: `${taskTemplate.id}-${index}`,
        type,
        selectable: true,
        position: coordinates,
        ...position,
        data: {
          taskTemplate,
          label: (
            <div key={taskTemplate.id}>
              <Typography noWrap variant="subtitle1">
                <strong>{taskTemplate.name}</strong>
              </Typography>
              <Typography variant="subtitle2">
                {_.capitalize(taskTemplate.type)}
              </Typography>
              <Box
                mt={1}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: '5px',
                }}
              >
                <TaskStat
                  status={TASK_STATUS.IN_PROGRESS}
                  count={taskTemplate.in_progress_tasks_count}
                />
                <TaskStat
                  status={TASK_STATUS.FOR_REVIEW}
                  count={taskTemplate.for_review_tasks_count}
                />
                <TaskStat
                  status={TASK_STATUS.COMPLETED}
                  count={taskTemplate.completed_tasks_count}
                />
              </Box>
            </div>
          ),
        },
        style: {
          ...COMMON_NODE_STYLES,
          background: `linear-gradient(
            to right,
            ${colors.green0} ${completedTasksPercentage},
            ${colors.grey0} ${completedTasksPercentage} 100%
          )`,
        },
      };
    }
  );
};

const generateEdgesFromNodes = (nodes: Node[]): Edge[] => {
  const result: Edge[] = [];

  pairwise(_.map(nodes, 'id'), (left: string, right: string) =>
    result.push({
      // Reference: https://reactflow.dev/docs/api/edges/edge-options/
      id: `${left}-${right}`,
      source: left,
      target: right,
      type: 'smoothstep',
      markerEnd: { type: MarkerType.ArrowClosed },
      style: { strokeWidth: '2px' },
    })
  );

  return result;
};

export const useFlow = (workflowId: string) => {
  const { data } = useWorkflowOverview(workflowId);

  const nodes: Node[] = useMemo(() => generateNodes(data), [data]);
  const edges: Edge[] = useMemo(() => generateEdgesFromNodes(nodes), [nodes]);

  const { fitView, setCenter } = useReactFlow();

  const [selectedTaskTemplate, setSelectedTaskTemplate] =
    useState<IWorkflowOverviewTaskTemplate>(null);

  const handleNodeClick = useCallback(
    (_e, node: Node) => {
      const { position, width, height, data } = node;

      const x = position.x + width / 2;
      const y = position.y + height / 2;
      const zoom = 1.5;

      setSelectedTaskTemplate(data.taskTemplate);

      // We need to delay the flow canvas rerender a bit so that it calculates its dimensions correctly.
      setTimeout(
        () => setCenter(x, y, { zoom, duration: ZOOM_SPEED_IN_MS }),
        ZOOM_DELAY_SPEED_IN_MS
      );
    },
    [setCenter]
  );

  const handleNodeClose = useCallback(() => {
    setSelectedTaskTemplate(null);

    // We need to delay the flow canvas rerender a bit so that it calculates its dimensions correctly.
    setTimeout(
      () => fitView({ duration: ZOOM_SPEED_IN_MS }),
      ZOOM_DELAY_SPEED_IN_MS
    );
  }, [fitView]);

  const displayNodeDetail = useMemo(
    () => !_.isNull(selectedTaskTemplate),
    [selectedTaskTemplate]
  );

  return {
    nodes,
    edges,
    selectedTaskTemplate,
    handleNodeClick,
    handleNodeClose,
    displayNodeDetail,
  };
};

export const useTaskRow = () => {
  const [selectedTask, setSelectedTask] = useState<IWorkflowTask>(null);

  const handleRowClick = useCallback(
    (params: GridRowParams) => setSelectedTask(params.row),
    []
  );

  const handleRowDetailClose = useCallback(() => setSelectedTask(null), []);

  const displayRowDetail = useMemo(
    () => !_.isNull(selectedTask),
    [selectedTask]
  );

  return {
    displayRowDetail,
    selectedTask,
    handleRowClick,
    handleRowDetailClose,
  };
};
