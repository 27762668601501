import _ from 'lodash';

export const PERMISSION_ENTITIES = {
  USERS: 'users',
  USER_GROUPS: 'user_groups',

  ORGANIZATIONS: 'organizations',

  TEMPLATES: 'templates',

  WORKFLOWS: 'workflows',

  COMPONENTS: 'components',
};

export const ENTITY_PERMISSIONS = {
  VIEW: 'view',
  ADD: 'add',
  CHANGE: 'change',
  DEACTIVATE: 'deactivate',
};

export interface IEntityPermission {
  value: boolean;
  permission: string;
  entity: string;
}

export interface IUserGroupEntityPermission extends IEntityPermission {
  id: number;
}

export const getEntityPermissionsPairsList = () => {
  let pairs = [];

  for (const entity of _.values(PERMISSION_ENTITIES)) {
    for (const permission of _.values(ENTITY_PERMISSIONS)) {
      pairs.push({
        permission,
        entity,
        value: false,
      });
    }
  }

  return pairs;
};
