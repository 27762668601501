import React from 'react';

import _ from 'lodash';

import Chip, { ChipProps } from 'components/Chip';

import { TASK_STATUS_TO_COLOR_VARIANT } from '../../sdk';

interface ITaskStatus extends ChipProps {
  status: string;
}

const TaskStatus: React.FC<ITaskStatus> = ({ status, ...rest }) => (
  <Chip
    label={_.capitalize(_.startCase(status))}
    color={TASK_STATUS_TO_COLOR_VARIANT[status]}
    {...rest}
  />
);

export default TaskStatus;
