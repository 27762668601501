import React from 'react';

import _ from 'lodash';

import { Box, Typography } from 'components';
import {
  AbcRoundedIcon,
  Filter1RoundedIcon,
  FormatListNumberedRoundedIcon,
  FlakyRoundedIcon,
  PinDropRoundedIcon,
  PinOutlinedIcon,
  FileUploadOutlinedIcon,
} from 'icons';

import { QUESTION_TYPES } from 'entities/Question/sdk';

export const QUESTION_TYPE_TO_ICON = {
  [QUESTION_TYPES.FREETEXT]: AbcRoundedIcon,
  [QUESTION_TYPES.SINGLE_SELECT]: Filter1RoundedIcon,
  [QUESTION_TYPES.MULTIPLE_SELECT]: FormatListNumberedRoundedIcon,
  [QUESTION_TYPES.BOOLEAN]: FlakyRoundedIcon,
  [QUESTION_TYPES.GEOPOINT]: PinDropRoundedIcon,
  [QUESTION_TYPES.INTEGER]: PinOutlinedIcon,
  // TODO: Decide an icon for decimals
  [QUESTION_TYPES.DECIMAL]: PinOutlinedIcon,
  [QUESTION_TYPES.FILE_UPLOAD]: FileUploadOutlinedIcon,
};

interface IQuestionType {
  type: string;
}

const getQuestionTypeAlias = (questionType: string): string => {
  if (questionType === QUESTION_TYPES.BOOLEAN) {
    return 'Yes / No';
  }

  return _.capitalize(_.startCase(questionType));
};

const QuestionType: React.FC<IQuestionType> = ({ type }) => {
  const Icon = QUESTION_TYPE_TO_ICON[type];

  return (
    <Box
      sx={{
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
      }}
    >
      <Icon fontSize="medium" />
      <Typography variant="subtitle1">{getQuestionTypeAlias(type)} </Typography>
    </Box>
  );
};

export default QuestionType;
