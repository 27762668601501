import React from 'react';

import Fab from 'components/Fab';
import type { FabProps } from 'components/Fab';

import { AddRoundedIcon } from 'icons';

const AddFab: React.FC<FabProps> = ({ onClick, ...rest }) => (
  <Fab
    color="primary"
    aria-label="add"
    onClick={onClick}
    sx={{ position: 'fixed', bottom: 20, right: 20 }}
    {...rest}
  >
    <AddRoundedIcon />
  </Fab>
);

export default AddFab;
