import { useFetch, post } from 'utils/sdk';

import { IComponentCategory } from './Category/sdk';
import { IComponentManufacturer } from './Manufacturer/sdk';

export interface IComponentListEntity {
  id: number;

  model: string;
  description: string;

  category?: IComponentCategory;
  manufacturer: IComponentManufacturer;
  latest_price: {
    id: number;
    amount: string;
  };
}

export const useComponentList = () => {
  const { data, error, isLoading, refetch } =
    useFetch<IComponentListEntity[]>('components/');

  return { components: data || [], error, isLoading, refetch };
};

export interface IComponentDetailEntity {
  id: number;

  model: string;
  description: string;

  category?: IComponentCategory;
  manufacturer: IComponentManufacturer;

  prices: {
    id: number;
    amount: string;
    created_at: string;
    valid_until: string;
  }[];
}

export const useComponentDetail = (id: string) => {
  const { data, error, isLoading, refetch } = useFetch<IComponentDetailEntity>(
    `components/${id}/`
  );

  return { component: data, error, isLoading, refetch };
};

export interface IComponentCreateValues {
  category_name: string;
  manufacturer: string;
  model: string;
  description: string;
  price?: {
    amount: string;
    valid_until: string;
  };
}

export const createComponent = (values: IComponentCreateValues) =>
  post('components/create/', values);

export interface IComponentUpdateValues {
  category_name?: string;
  manufacturer?: string;
  model?: string;
  description?: string;
  price?: {
    amount: string;
    valid_until: string;
  };
}

export const updateComponent = (id: string, values: IComponentUpdateValues) =>
  post(`components/${id}/update/`, values);
