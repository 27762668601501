import React from 'react';

import _ from 'lodash';

import { Box, Typography } from 'components';

import { colors } from 'theme/palette';

import { TASK_STATUS_TO_COLOR_VARIANT } from 'entities/Task/sdk';

interface ITaskStat {
  count: number;
  status: string;
}

// This is looking almost like a MUI Chip component,
// but we do not use it because we render this component inside a relative-sized canvas (React flow)
// and due to the Chip implementation - font sizes are not correct when rendered inside the Flow diagram.
// That's why we implement a simple chip-like box ourselves in order to display task stats.
const TaskStat: React.FC<ITaskStat> = ({ count, status }) => {
  const color = TASK_STATUS_TO_COLOR_VARIANT[status];

  return (
    <Box
      sx={{
        borderRadius: 15,
        padding: '5px',
        // Reference: https://mui.com/system/palette/#color
        backgroundColor: `${color}.main`,
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          borderRadius: '50%',
          width: 20,
          height: 20,
          backgroundColor: colors.grey0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography sx={{ fontSize: '10px', color: colors.grey5 }}>
          {count}
        </Typography>
      </Box>
      <Typography sx={{ fontSize: '12px', color: colors.white }}>
        {_.capitalize(_.startCase(status))}
      </Typography>
    </Box>
  );
};

export default TaskStat;
