import React from 'react';

import { Box, Link, Typography } from 'components';
import { UploadFileOutlinedIcon } from 'icons';
import { downloadUrl } from 'utils/files';

interface IDownloadableFile {
  url: string;
  name: string;
  title?: string;
}

const DownloadableFile: React.FC<IDownloadableFile> = ({
  url,
  name,
  title = '',
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: '3px' }}>
    <UploadFileOutlinedIcon />

    {title && <Typography variant="subtitle2">{title}: </Typography>}

    <Link
      // By default, the HTML button type is "submit".
      // Since we don't want to accidentaly submit forms, we explicitly set the type to "button".
      type="button"
      component="button"
      variant="subtitle2"
      onClick={() => downloadUrl(url, name)}
    >
      {name}
    </Link>
  </Box>
);

export default DownloadableFile;
