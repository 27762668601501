import _ from 'lodash';

export interface IOption {
  value: string;
  inputText?: string;
}

// Generates options for the AutocompleteField based on a given property
export const generateAutocompleteOptions = (
  values: any[],
  field: string
): IOption[] => _.map(values, v => ({ value: v[field] }));
