import { post, useFetch } from 'utils/sdk';

export interface IChangeRequest {
  id: number;

  created_at: string;
  reason: string;

  created_by: {
    email: string;
  };

  comments: {
    id: number;
    created_by: {
      email: string;
    };
    created_at: string;
    text: string;
  }[];
}

export const useChangeRequestsForAnswer = (
  id?: number,
  shouldFetch: boolean = true
) => {
  const { data, error, isLoading, refetch } = useFetch<IChangeRequest[]>(
    shouldFetch && id ? `surveys/answers/${id}/change-requests/` : null
  );

  return { changeRequests: data || [], error, isLoading, refetch };
};

export const addChangeRequestComment = (
  changeRequestId: number,
  text: string
) => post(`change-requests/${changeRequestId}/add-comment/`, { text });

export const useChangeRequestsForOrder = (id?: number) => {
  const { data, error, isLoading, refetch } = useFetch<IChangeRequest[]>(
    id ? `components/orders/${id}/change-requests/` : null
  );

  return { changeRequests: data || [], error, isLoading, refetch };
};

export interface IRequestChangesForOrderPayload {
  reason: string;
  comments?: { text: string }[];
}

export const requestChangesForOrder = (
  id: number,
  data: IRequestChangesForOrderPayload
) => post(`components/orders/${id}/change-requests/create/`, data);
