import { useEffect, useCallback, useMemo } from 'react';

import _ from 'lodash';

import { FieldProps, useFormikContext } from 'formik';

import { FormTextField, Box, Chip, MenuItem } from 'components';
import { TextFieldProps } from 'components/TextField';

type OptionType = any;

interface IMultiSelect<T extends OptionType> {
  options: T[];
  fieldMapping: {
    key: string;
    label: string;
  };
}

/* This component is meant to be used as a `component` for a Formik `Field`
   The only required props are:
   * `options` - The options that you want to select from
   * `fieldMapping` - A mapping for the component in order to know
                      which field to get as a `key` and as a `label`
                      from the provided options and display them consistently

   For example:

   `options`: [ { pk: 1, alias: 'opt 1' }, { pk: 2, alias: 'opt 2' }, ... ]
   `fieldMapping`: { key: 'pk', label: 'alias' }

   It also works with nested paths by using dot notation (because we use Lodash's `get`):

   `options`: [ { pk: 1, aliasObj: { value: 'opt 1' } }, { pk: 2, aliasObj: { value: 'opt 2' } }, ... ]
   `fieldMapping`: { key: 'pk', label: 'aliasObj.value' }
 */
const MultiSelect = <T extends OptionType>({
  // IMultiSelect
  options,
  fieldMapping,
  // FieldProps
  field,
  form,
  // TextFieldProps
  ...rest
}: IMultiSelect<T> & FieldProps & TextFieldProps) => {
  const optionsKeys = useMemo(
    () => _.map(options, fieldMapping.key),
    [options, fieldMapping]
  );

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    /* This effect handles the case when we remove something from the available values
       which is selected in the current select.
       In order to remove the values that are not in the available options,
       we get the difference between the two sets to determine which values to remove.
       ---
       Example:
       Available options: [1, 2, 3]
       Current selected options: [1, 2]
       If we remove `2` from the available options, it also needs to be removed from here.
       `optionsToRemove` = [2]
       `validOptions` = [1]
    */
    const optionsToRemove = _.difference(field.value, optionsKeys);

    if (!_.isEmpty(optionsToRemove)) {
      const validOptions = _.difference(field.value, optionsToRemove);
      setFieldValue(field.name, validOptions);
    }
  }, [field.value, optionsKeys, setFieldValue, field.name]);

  const getOptionDisplay = useCallback(
    (option: T) => ({
      key: _.get(option, fieldMapping.key),
      label: _.get(option, fieldMapping.label),
    }),
    [fieldMapping]
  );

  return (
    <FormTextField
      field={field}
      form={form}
      disabled={_.isEmpty(options)}
      select
      SelectProps={{
        multiple: true,
        value: field.value,
        onChange: field.onChange,
        // @ts-ignore
        renderValue: selectedOptions => {
          const optionsToRender = _(options)
            .map(getOptionDisplay)
            .filter(({ key }) => _.includes(selectedOptions, key))
            .value();

          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {optionsToRender.map(option => (
                <Chip {...option} />
              ))}
            </Box>
          );
        },
      }}
      {...rest}
    >
      {options.map(option => {
        const { key, label } = getOptionDisplay(option);

        return (
          <MenuItem
            key={key}
            value={key}
            sx={{
              ...(_.includes(field.value, key) && {
                fontWeight: 'bold',
              }),
            }}
          >
            {label}
          </MenuItem>
        );
      })}
    </FormTextField>
  );
};

export default MultiSelect;
