import React, { useMemo } from 'react';

import _ from 'lodash';

import { Box, Typography, Notes } from 'components';

import { formatPrice } from 'entities/Component/utils';
import { IComponentDetailEntity } from 'entities/Component/sdk';

interface IComponentGeneralInfo {
  component: IComponentDetailEntity;
}

const ComponentGeneralInfo: React.FC<IComponentGeneralInfo> = ({
  component,
}) => {
  const latestPrice = useMemo(
    () => _.last(_.get(component, 'prices', [])),
    [component]
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Typography variant="h6">
        <strong>{component.model}</strong>
      </Typography>

      <div>
        {component.category && (
          <Typography variant="subtitle1">
            Category: <strong>{component.category.name}</strong>
          </Typography>
        )}
        <Typography variant="subtitle1">
          Manufacturer: <strong>{component.manufacturer.name}</strong>
        </Typography>
        <Typography variant="subtitle1">
          Latest price:{' '}
          <strong>{formatPrice(_.get(latestPrice, 'amount'))}</strong>
        </Typography>
      </div>

      <Notes value={component.description} />
    </Box>
  );
};

export default ComponentGeneralInfo;
