import { useFetch } from 'utils/sdk';

export interface IComponentManufacturer {
  id: number;
  name: string;
}

export const useComponentManufacturersList = () => {
  const {
    data: manufacturers,
    error,
    isLoading,
    refetch,
  } = useFetch<IComponentManufacturer[]>('components/manufacturers/');

  return { manufacturers, error, isLoading, refetch };
};
