import React from 'react';

import * as yup from 'yup';
import { useFormik, FormikProvider, Field } from 'formik';
import { styled } from '@mui/material/styles';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormTextField,
  MultiSelect,
  MenuItem,
} from 'components';

import { notifySuccess, notifyApiError } from 'utils/notifications';

import { useAllUserGroups } from 'entities/UserGroup/sdk';
import { createUser } from 'entities/User/sdk';
import { useOrganizations } from 'entities/Organization/sdk';

const FORM_ID = 'user-create-form';

const StyledForm = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
}));

interface IFormValues {
  email: string;
  password: string;
  organization: number;
  user_groups: number[];
}

const validationSchema = yup.object({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
  organization: yup.number().required('Please select a organization'),
  user_groups: yup
    .array()
    .min(1, 'Please use at least 1 user group')
    .required(),
});

interface IUserCreateDialog {
  handleClose: () => void;
  handleSuccess: () => void;
}

const USER_GROUP_FIELD_MAPPING = {
  key: 'id',
  label: 'name',
};

const UserCreateDialog: React.FC<IUserCreateDialog> = ({
  handleClose,
  handleSuccess,
}) => {
  const { userGroups, isLoading: isLoadingUserGroups } = useAllUserGroups();
  const { organizations, isLoading: isLoadingOrganizations } =
    useOrganizations();

  const onSubmit = async (values: IFormValues) => {
    try {
      await createUser(values);
      handleSuccess();
      handleClose();

      notifySuccess('User created successfully.');
    } catch (e: any) {
      notifyApiError(e);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      // @ts-ignore
      organization: '',
      user_groups: [],
    },
    validationSchema,
    onSubmit,
  });

  if (isLoadingUserGroups) {
    return null;
  }

  return (
    <Dialog disableBackdropClick onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        <div>Create user</div>
      </DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <form id={FORM_ID} onSubmit={formik.handleSubmit}>
            <StyledForm>
              <Field
                name="email"
                component={FormTextField}
                required
                fullWidth
                autoFocus
                label="Email"
              />
              <Field
                name="password"
                type="password"
                component={FormTextField}
                required
                fullWidth
                label="Password"
              />
              <Field
                disabled={isLoadingOrganizations}
                name="organization"
                component={FormTextField}
                select
                required
                fullWidth
                label="Organization"
              >
                {organizations.map(organization => (
                  <MenuItem key={organization.id} value={organization.id}>
                    {organization.short_name}
                  </MenuItem>
                ))}
              </Field>
              <Field
                disabled={isLoadingUserGroups}
                name="user_groups"
                component={MultiSelect}
                label="User groups"
                options={userGroups}
                fieldMapping={USER_GROUP_FIELD_MAPPING}
              />
            </StyledForm>
          </form>
        </FormikProvider>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          form={FORM_ID}
          type="submit"
          variant="contained"
          disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserCreateDialog;
