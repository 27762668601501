import React, { useMemo } from 'react';

import _ from 'lodash';

import { Box, Divider, Typography } from 'components';

import { IWorkflowTask } from 'entities/Workflow/sdk';
import OrderDetails from 'entities/Order/components/Details';

import { useChangeRequestsForOrder } from 'entities/ChangeRequest/sdk';
import ChangeRequestDetails from 'entities/ChangeRequest/components/Details';

interface IOrderDetails {
  task: IWorkflowTask;
}

const OrderTaskDetails: React.FC<IOrderDetails> = ({ task }) => {
  const orderId = useMemo(() => _.get(task, 'order_id'), [task]);

  const { changeRequests, refetch: refetchChangeRequests } =
    useChangeRequestsForOrder(orderId);

  return (
    <>
      <OrderDetails orderId={orderId} />

      {!_.isEmpty(changeRequests) && (
        <Box mt={1}>
          <Divider>
            <Typography variant="subtitle1">Change requests</Typography>
          </Divider>

          {_.map(changeRequests, (request, index: number) => (
            <ChangeRequestDetails
              key={index}
              request={request}
              refetchChangeRequests={refetchChangeRequests}
              disableAddComment
            />
          ))}
        </Box>
      )}
    </>
  );
};

export default OrderTaskDetails;
