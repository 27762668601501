import React from 'react';

import { Outlet } from 'react-router-dom';

import { NoPermission } from 'components';

import { useSignedUser } from 'entities/Auth/sdk';
import { hasEntityPermission } from 'entities/EntityPermission/utils';

interface IProtectedRoute {
  entity: string;
  permission: string;
}

const ProtectedRoute: React.FC<IProtectedRoute> = ({
  entity,
  permission,
  children,
}) => {
  const { user } = useSignedUser();

  if (!hasEntityPermission(user, entity, permission)) {
    return <NoPermission />;
  }

  if (children) {
    return <>{children}</>;
  }

  return <Outlet />;
};

export default ProtectedRoute;
