import React, { useMemo } from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { useFormik, Field, FieldArray, FormikProvider } from 'formik';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormTextField,
  Button,
  Divider,
  Typography,
} from 'components';
import { notifySuccess, notifyApiError } from 'utils/notifications';

import { IWorkflowTemplateListEntity } from 'entities/WorkflowTemplate/sdk';
import WorkflowTemplatesFieldArray from 'entities/WorkflowTemplate/components/WorkflowTemplatesFieldArray';

import {
  createWorkflowTemplateGroup,
  IWorkflowTemplateGroupCreatePayload,
  useWorkflowTemplateGroup,
} from 'entities/WorkflowTemplateGroup/sdk';

interface IFormValues {
  name: string;
  workflow_templates: IWorkflowTemplateListEntity[];
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  workflow_templates: yup
    .array()
    .of(yup.object({ id: yup.number(), name: yup.string() }))
    .min(1, 'Please add at least one workflow template'),
});

const FORM_ID = 'workflow-template-group-form';

interface IWorkflowTemplateGroupCreate {
  handleClose: () => void;
  handleSuccess: () => void;
  duplicateId?: string;
}

const WorkflowTemplateGroupCreate: React.FC<IWorkflowTemplateGroupCreate> = ({
  handleClose,
  handleSuccess,
  duplicateId = null,
}) => {
  const { workflowTemplateGroup: duplicateWorkflowTemplateGroup } =
    useWorkflowTemplateGroup(duplicateId);

  const onSubmit = async (values: IFormValues) => {
    try {
      const valuesToSubmit: IWorkflowTemplateGroupCreatePayload = {
        ...values,
        workflow_templates: _.map(values.workflow_templates, 'id'),
      };
      await createWorkflowTemplateGroup(valuesToSubmit);

      notifySuccess('Workflow template group created successfully.');
      handleSuccess();
      handleClose();
    } catch (e: any) {
      notifyApiError(e);
    }
  };

  // @ts-ignore
  const initialValues: IFormValues = useMemo(() => {
    if (duplicateWorkflowTemplateGroup) {
      return {
        name: `Duplicate of ${duplicateWorkflowTemplateGroup.name}`,
        workflow_templates: _.map(
          duplicateWorkflowTemplateGroup.workflow_templates,
          (workflowTemplate: IWorkflowTemplateListEntity) =>
            _.pick(workflowTemplate, ['id', 'name'])
        ),
      };
    }

    return {
      name: '',
      workflow_templates: [],
    };
  }, [duplicateWorkflowTemplateGroup]);

  const formik = useFormik<IFormValues>({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  // Do not display form if we are creating a duplicate but the form is not populated.
  if (duplicateId && _.isEmpty(formik.initialValues.name)) {
    // TODO: Display better loading screen here (skeleton)
    return null;
  }

  return (
    <Dialog disableBackdropClick onClose={handleClose}>
      <DialogTitle onClose={handleClose}>
        Create workflow template group
      </DialogTitle>
      <DialogContent>
        <form id={FORM_ID} onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <Field
              name="name"
              component={FormTextField}
              required
              fullWidth
              autoFocus
              label="Name"
            />

            <Divider sx={{ mt: 2 }}>
              <Typography variant="subtitle1">Templates</Typography>
            </Divider>
            <FieldArray
              name="workflow_templates"
              // @ts-ignore
              component={WorkflowTemplatesFieldArray}
            />
          </FormikProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          type="submit"
          form={FORM_ID}
          disabled={
            formik.isSubmitting ||
            // We want to enable to submit  the form even if it is not touched when creating a duplicate
            (!duplicateId && !formik.dirty) ||
            !formik.isValid
          }
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkflowTemplateGroupCreate;
