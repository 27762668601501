import React from 'react';

import { useSignedUser } from 'entities/Auth/sdk';
import { hasEntityPermission } from 'entities/EntityPermission/utils';

interface IProtected {
  entity: string;
  permission: string;
}

const Protected: React.FC<IProtected> = ({ entity, permission, children }) => {
  const { user } = useSignedUser();

  if (!hasEntityPermission(user, entity, permission)) {
    return null;
  }

  return <>{children}</>;
};

export default Protected;
