import React, { useCallback } from 'react';

import MuiDialog from '@mui/material/Dialog';
import type { DialogProps } from '@mui/material/Dialog';

interface IDialog extends Omit<DialogProps, 'open'> {
  open?: boolean;
  disableBackdropClick?: boolean;
}

const Dialog: React.FC<IDialog> = ({
  open = true,
  fullWidth = true,
  disableBackdropClick = false,
  scroll = 'paper',
  onClose,
  children,
  ...rest
}) => {
  const handleClose = useCallback(
    (event, reason) => {
      if (disableBackdropClick && reason === 'backdropClick') {
        return;
      }

      onClose(event, reason);
    },
    [disableBackdropClick, onClose]
  );

  return (
    <MuiDialog
      open={open}
      fullWidth={fullWidth}
      onClose={handleClose}
      scroll={scroll}
      {...rest}
    >
      {children}
    </MuiDialog>
  );
};

export default Dialog;
