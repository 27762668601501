import { useFetch, post } from 'utils/sdk';

import { IRoleTemplate } from 'entities/WorkflowRole/sdk';
import { ITaskTemplateListEntity } from 'entities/TaskTemplate/sdk';

export interface IWorkflowTemplateCreatePayload {
  name: string;
  task_templates: number[];
  main_role: number;
  role_templates_with_read_access: number[];
  role_templates_with_write_access: number[];
}

export interface IWorkflowTemplateUpdatePayload {
  name?: string;
  task_templates?: number[];
  main_role?: number;
  role_templates_with_read_access?: number[];
  role_templates_with_write_access?: number[];
}

export interface IWorkflowTemplateCreateResponse {
  id: number;
}

export const createWorkflowTemplate = (data: IWorkflowTemplateCreatePayload) =>
  post<IWorkflowTemplateCreateResponse>('workflows/templates/create/', data);

export const updateWorkflowTemplate = (
  id: string,
  data: IWorkflowTemplateUpdatePayload
) => post(`workflows/templates/${id}/update/`, data);

export interface IWorkflowTemplateListEntity {
  id: number;
  name: string;
  role_templates: IRoleTemplate[];
}

export const useWorkflowTemplates = () => {
  const { data, error, isLoading, refetch } = useFetch<
    IWorkflowTemplateListEntity[]
  >('workflows/templates/', {
    paginate: false,
  });

  return { workflowTemplates: data || [], error, isLoading, refetch };
};

export interface IWorkflowTemplateDetailEntity {
  id: number;
  name: string;
  single_user_workflow: boolean;
  workflow_template_groups: {
    id: number;
    name: string;
  }[];
  main_role: IRoleTemplate;
  role_templates: IRoleTemplate[];
  role_templates_with_read_access: IRoleTemplate[];
  role_templates_with_write_access: IRoleTemplate[];
  task_templates: ITaskTemplateListEntity[];
  can_update: boolean;
}

export const useWorkflowTemplate = (id: string) => {
  const {
    data: workflowTemplate,
    error,
    isLoading,
    refetch,
  } = useFetch<IWorkflowTemplateDetailEntity>(
    id ? `workflows/templates/${id}/` : null
  );

  return { workflowTemplate, error, isLoading, refetch };
};
