import React, { useMemo } from 'react';

import { styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import ReactFlow, { Background } from 'react-flow-renderer';

import { Paper } from 'components';

import { useFlow, useTaskRow } from './utils';
import TaskTemplateTasksList from './components/TaskTemplateTasksList';
import TaskTemplateRowDetail from './components/TaskTemplateRowDetail';

const StyledWrapper = styled(Paper)(() => ({
  padding: '15px',
  width: '100%',
  height: '500px',
}));

const WorkflowFlow: React.FC = () => {
  const { id: workflowId } = useParams();
  const {
    nodes,
    edges,
    selectedTaskTemplate,
    handleNodeClick,
    handleNodeClose,
    displayNodeDetail,
  } = useFlow(workflowId);

  const {
    displayRowDetail,
    handleRowClick,
    handleRowDetailClose,
    selectedTask,
  } = useTaskRow();

  const wrapperStyles = useMemo(
    () =>
      displayNodeDetail && {
        display: 'grid',
        gap: '15px',
        gridTemplateColumns: '1fr 1.5fr',
      },
    [displayNodeDetail]
  );

  return (
    <StyledWrapper sx={wrapperStyles}>
      <ReactFlow
        // Reference: https://reactflow.dev/docs/api/react-flow-props/
        defaultNodes={nodes}
        defaultEdges={edges}
        // Fits the view once to make all nodes visible
        fitView
        // Disable zooming the graph in and out using the mousewheel or trackpad
        zoomOnScroll={false}
        // Do not prevent default browser scroll behaviour
        preventScrolling={false}
        // Disable dragging nodes around.
        nodesDraggable={false}
        // Disable connecting nodes (they are connected once during the initialization)
        nodesConnectable={false}
        // Disable selecting nodes/edges.
        elementsSelectable={false}
        onNodeClick={handleNodeClick}
      >
        <Background gap={12} />
      </ReactFlow>

      {displayNodeDetail && (
        <TaskTemplateTasksList
          taskTemplate={selectedTaskTemplate}
          handleRowClick={handleRowClick}
          handleClose={handleNodeClose}
        />
      )}

      {displayRowDetail && (
        <TaskTemplateRowDetail
          task={selectedTask}
          handleClose={handleRowDetailClose}
        />
      )}
    </StyledWrapper>
  );
};

export default WorkflowFlow;
