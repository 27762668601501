import React from 'react';

import { colors } from 'theme/palette';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
} from 'components';

import { IWorkflowTask } from 'entities/Workflow/sdk';
import { TASK_TEMPLATE_TYPES } from 'entities/TaskTemplate/sdk';

import SurveyTaskDetails from './SurveyTaskDetails';
import OrderTaskDetails from './OrderTaskDetails';

const TASK_TYPE_TO_DETAIL_COMPONENT = {
  [TASK_TEMPLATE_TYPES.SURVEY]: SurveyTaskDetails,
  [TASK_TEMPLATE_TYPES.ORDER]: OrderTaskDetails,
};

interface ITaskTemplateRowDetail {
  task: IWorkflowTask;
  handleClose: () => void;
}

const TaskTemplateRowDetail: React.FC<ITaskTemplateRowDetail> = ({
  task,
  handleClose,
}) => {
  const TaskComponent = TASK_TYPE_TO_DETAIL_COMPONENT[task.template.type];

  return (
    <Dialog maxWidth="md" onClose={handleClose}>
      <DialogTitle onClose={handleClose}>{task.template.name}</DialogTitle>
      <DialogContent sx={{ backgroundColor: colors.grey0 }}>
        <Typography variant="subtitle1">
          <strong>Executor:</strong> {task.executor} ({task.executor_role.name})
        </Typography>

        <Box mt={1}>
          <TaskComponent task={task} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default TaskTemplateRowDetail;
