import { useFetch, post } from 'utils/sdk';

import { IRoleTemplate } from 'entities/WorkflowRole/sdk';

export interface IWorkflowTemplateGroupListEntity {
  id: number;
  name: string;
  can_update: boolean;
  unique_role_templates: IRoleTemplate[];
}

export const useWorkflowTemplateGroups = () => {
  const {
    data: workflowTemplateGroups,
    error,
    isLoading,
    refetch,
  } = useFetch<IWorkflowTemplateGroupListEntity[]>(
    'workflows/templates/groups/',
    {
      paginate: false,
    }
  );

  return { workflowTemplateGroups, error, isLoading, refetch };
};

export interface IWorkflowTemplateGroupCreatePayload {
  name: string;
  workflow_templates: number[];
}

export const createWorkflowTemplateGroup = (
  values: IWorkflowTemplateGroupCreatePayload
) => post('workflows/templates/groups/create/', values);

export interface IWorkflowTemplateGroupDetailEntity {
  id: number;
  name: string;
  can_update: boolean;
  workflow_templates: {
    id: number;
    name: string;
  }[];
}

export const useWorkflowTemplateGroup = (id: string | null) => {
  const { data, error, isLoading, refetch } =
    useFetch<IWorkflowTemplateGroupDetailEntity>(
      id ? `workflows/templates/groups/${id}/` : null
    );

  return { workflowTemplateGroup: data, error, isLoading, refetch };
};

export interface IWorkflowTemplateGroupUpdatePayload {
  name?: string;
  workflow_templates?: number[];
}

export const updateWorkflowTemplateGroup = (
  id: string,
  data: IWorkflowTemplateGroupUpdatePayload
) => post(`workflows/templates/groups/${id}/update/`, data);
