import React, { useMemo } from 'react';

import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { Paper, Box, Typography, IconButton } from 'components';
import DataGrid, {
  transformToColumns,
  GridColDef,
  GridAlignment,
  GridRowParams,
} from 'components/DataGrid';

import { CloseRoundedIcon } from 'icons';
import { formatDate, parseDateTime } from 'utils/date';

import {
  IWorkflowOverviewTaskTemplate,
  useWorkflowTasksList,
} from 'entities/Workflow/sdk';

import { TASK_STATUS } from 'entities/Task/sdk';
import TaskStatus from 'entities/Task/components/Status';

import { ZOOM_SPEED_IN_MS, ZOOM_DELAY_SPEED_IN_MS } from '../../constants';

const columnsFields: string[] = [
  'executor',
  'reviewer',
  'completeDate',
  'reviewDate',
  'status',
];

const columns: GridColDef[] = transformToColumns(columnsFields).map(col => {
  if (col.field.endsWith('Date')) {
    return {
      ...col,
      type: 'date',
      valueGetter: ({ value }) => parseDateTime(value),
      valueFormatter: ({ value }) => formatDate(value) || 'N/A',
      maxWidth: 160,
    };
  }

  if (col.field === 'status') {
    return {
      ...col,
      type: 'singleSelect',
      align: 'center' as GridAlignment,
      headerAlign: 'center' as GridAlignment,
      valueOptions: _.map(TASK_STATUS, (value: string, key: string) => ({
        label: _.capitalize(_.startCase(key)),
        value,
      })),
      renderCell: ({ row: { status } }) => <TaskStatus status={status} />,
      maxWidth: 150,
    };
  }

  return col;
});

const FADE_IN_SPEED_IN_MS = ZOOM_SPEED_IN_MS + ZOOM_DELAY_SPEED_IN_MS;

const StyledTaskTemplateTasksList = styled(Paper)(() => ({
  padding: '15px',
  borderRadius: '15px',
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  animation: `fadeIn ${FADE_IN_SPEED_IN_MS}ms`,
}));

interface ITaskTemplateTasksList {
  taskTemplate: IWorkflowOverviewTaskTemplate;
  handleRowClick: (params: GridRowParams) => void;
  handleClose: (e: React.MouseEvent) => void;
}

const TaskTemplateTasksList: React.FC<ITaskTemplateTasksList> = ({
  taskTemplate,
  handleRowClick,
  handleClose,
}) => {
  const { id: workflowId } = useParams();

  const { id: taskTemplateId, name } = taskTemplate;

  const { data, isLoading, error } = useWorkflowTasksList(
    workflowId,
    taskTemplateId
  );

  const rows = useMemo(
    () =>
      _.map(data, task => {
        const {
          executor,
          reviewer,
          executor_role,
          reviewer_role,
          completed_at,
          reviewed_at,
        } = task;

        const fullExecutor = _.get(executor, 'email', executor_role.name);
        const fullReviewer = _.get(reviewer, 'email', reviewer_role.name);

        return {
          ...task,
          executor: fullExecutor,
          reviewer: fullReviewer,
          completeDate: completed_at,
          reviewDate: reviewed_at,
        };
      }),
    [data]
  );

  if (error) {
    return (
      <Typography variant="subtitle2">Failed to load task data.</Typography>
    );
  }

  return (
    <StyledTaskTemplateTasksList variant="outlined">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
        <div />
        <Typography variant="h6">{name}</Typography>
        <IconButton onClick={handleClose}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>

      <DataGrid
        loading={isLoading}
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
      />
    </StyledTaskTemplateTasksList>
  );
};

export default TaskTemplateTasksList;
