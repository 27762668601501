import { useFetch, post } from 'utils/sdk';

import { IEntityPermission } from 'entities/EntityPermission/sdk';

export interface IUserGroupListEntity {
  id: number;
  name: string;
}

export const useAllUserGroups = () => {
  const {
    data: userGroups,
    error,
    isLoading,
    refetch,
  } = useFetch<IUserGroupListEntity[]>('permissions/user-groups/');

  return { userGroups, error, isLoading, refetch };
};

export interface IUserGroupDetailEntity {
  id: number;
  name: string;
  entity_permissions: IEntityPermission[];
}

export const useUserGroup = (id: string) => {
  const {
    data: userGroup,
    error,
    isLoading,
  } = useFetch<IUserGroupDetailEntity>(`permissions/user-groups/${id}/`);

  return { userGroup, error, isLoading };
};

export interface ICreateUserGroupPayload {
  name: string;
  entity_permissions: IEntityPermission[];
}

export const createUserGroup = (values: ICreateUserGroupPayload) =>
  post('permissions/user-groups/create/', values);

export interface IUpdateUserGroupPayload {
  name?: string;
  entity_permissions?: {
    id: number;
    value: boolean;
  }[];
}

export const updateUserGroup = (id: number, values: IUpdateUserGroupPayload) =>
  post(`permissions/user-groups/${id}/update/`, values);
