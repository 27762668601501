import React, { useEffect, useMemo } from 'react';

import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  useFormik,
  Field,
  FormikProvider,
  FieldArray,
  FormikValues,
} from 'formik';

import { styled } from '@mui/material/styles';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormTextField,
  Button,
  Typography,
  MultiSelect,
  Divider,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from 'components';
import { WORKFLOW_TEMPLATE_DETAIL_URL } from 'config/urls';

import { buildDetailUrl } from 'utils/urls';
import { notifySuccess, notifyApiError } from 'utils/notifications';

import { ITaskTemplateListEntity } from 'entities/TaskTemplate/sdk';
import {
  IRoleTemplate,
  useAvailableRoleTemplates,
} from 'entities/WorkflowRole/sdk';
import {
  useWorkflowTemplate,
  createWorkflowTemplate,
  IWorkflowTemplateCreatePayload,
} from 'entities/WorkflowTemplate/sdk';

import TaskTemplatesFieldArray from 'entities/TaskTemplate/components/TaskTemplatesFieldArray';

const ROLE_SELECT_FIELD_MAPPING = {
  key: 'id',
  label: 'name',
};

const StyledForm = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
}));

interface IFormValues extends FormikValues {
  name: string;
  single_user_workflow: boolean;
  task_templates: ITaskTemplateListEntity[];
  main_role: number;
  role_templates_with_read_access: number[];
  role_templates_with_write_access: number[];
}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  main_role: yup.number().required('Please select a main role'),
  task_templates: yup
    .array()
    .of(yup.object({ id: yup.number(), name: yup.string() }))
    .min(1, 'Please use at least 1 task template')
    .required(),
});

const FORM_ID = 'workflow-template-create';

interface IWorkflowTemplateCreate {
  handleClose: () => void;
  duplicateId?: string;
}

const WorkflowTemplateCreateDialog: React.FC<IWorkflowTemplateCreate> = ({
  handleClose,
  duplicateId = null,
}) => {
  const navigate = useNavigate();

  const { workflowTemplate: duplicateWorkflowTemplate } =
    useWorkflowTemplate(duplicateId);

  const { availableRoleTemplates } = useAvailableRoleTemplates();

  const onSubmit = async (values: IFormValues) => {
    try {
      const valuesToSubmit: IWorkflowTemplateCreatePayload = {
        ...values,
        task_templates: _.map(values.task_templates, 'id'),
      };

      const { id: newWorkflowTemplateId } = await createWorkflowTemplate(
        valuesToSubmit
      );

      const detailUrl = buildDetailUrl(
        WORKFLOW_TEMPLATE_DETAIL_URL,
        newWorkflowTemplateId
      );

      notifySuccess('Workflow template created successfully.');
      navigate(detailUrl);
    } catch (e: any) {
      notifyApiError(e);
    }
  };

  // @ts-ignore
  const initialValues: IFormValues = useMemo(() => {
    if (duplicateWorkflowTemplate) {
      return {
        name: `Duplicate of ${duplicateWorkflowTemplate.name}`,
        single_user_workflow: duplicateWorkflowTemplate.single_user_workflow,
        task_templates: duplicateWorkflowTemplate.task_templates,
        main_role: duplicateWorkflowTemplate.main_role.id,
        role_templates_with_read_access: _.map(
          duplicateWorkflowTemplate.role_templates_with_read_access,
          'id'
        ),
        role_templates_with_write_access: _.map(
          duplicateWorkflowTemplate.role_templates_with_write_access,
          'id'
        ),
      };
    }

    return {
      name: '',
      single_user_workflow: false,
      task_templates: [],
      main_role: '',
      role_templates_with_read_access: [],
      role_templates_with_write_access: [],
    };
  }, [duplicateWorkflowTemplate]);

  const formik = useFormik<IFormValues>({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const { setFieldValue } = formik;
  const { main_role: mainRoleId, task_templates: selectedTaskTemplates } =
    formik.values;

  const selectedRoleTemplates = useMemo(
    () =>
      // Consolidate the role templates from the selected task templates executor & reviewer roles
      _(selectedTaskTemplates)
        .flatMap((taskTemplate: ITaskTemplateListEntity) => [
          taskTemplate.executor_role,
          taskTemplate.reviewer_role,
        ])
        // We might have duplicated roles, so strip them out.
        .uniqBy('id')
        .value(),
    [selectedTaskTemplates]
  );

  const roleTemplatesExceptSelected = useMemo(
    // Role templates with read/write access are all roles that are different from the selected ones.
    () => _.xorBy(availableRoleTemplates, selectedRoleTemplates, 'id'),
    [availableRoleTemplates, selectedRoleTemplates]
  );

  useEffect(() => {
    /*
      This effect clears the previously selected main role if
      this role is no longer present in the available roles
    */

    const selectedRoleTemplatesIds = _.map(selectedRoleTemplates, 'id');

    if (!_.includes(selectedRoleTemplatesIds, mainRoleId)) {
      setFieldValue('main_role', '');
    }
  }, [setFieldValue, selectedRoleTemplates, mainRoleId]);

  // Do not display form if we are creating a duplicate but the form is not populated.
  if (duplicateId && _.isEmpty(formik.initialValues.name)) {
    // TODO: Display better loading screen here (skeleton)
    return null;
  }

  return (
    <Dialog maxWidth="md" disableBackdropClick onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Create workflow template</DialogTitle>
      <DialogContent>
        <form id={FORM_ID} onSubmit={formik.handleSubmit}>
          <FormikProvider value={formik}>
            <StyledForm>
              <Field
                name="name"
                component={FormTextField}
                required
                fullWidth
                autoFocus
                label="Name"
              />
              <FormControlLabel
                label="Single user workflow"
                control={
                  <Field
                    type="checkbox"
                    name="single_user_workflow"
                    as={Checkbox}
                  />
                }
              />

              <>
                <Divider>
                  <Typography variant="subtitle1">Task templates</Typography>
                </Divider>
                <FieldArray
                  name="task_templates"
                  // @ts-ignore
                  component={TaskTemplatesFieldArray}
                />
              </>

              <>
                <Divider>
                  <Typography variant="subtitle1">Roles</Typography>
                </Divider>

                <StyledForm>
                  <Field
                    name="main_role"
                    component={FormTextField}
                    select
                    required
                    fullWidth
                    disabled={_.isEmpty(selectedRoleTemplates)}
                    label="Main role"
                  >
                    {selectedRoleTemplates.map((template: IRoleTemplate) => (
                      <MenuItem key={template.id} value={template.id}>
                        {template.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <Field
                    name="role_templates_with_read_access"
                    component={MultiSelect}
                    label="Roles with read access"
                    disabled={!mainRoleId}
                    options={roleTemplatesExceptSelected}
                    fieldMapping={ROLE_SELECT_FIELD_MAPPING}
                  />
                  <Field
                    name="role_templates_with_write_access"
                    component={MultiSelect}
                    label="Roles with write access"
                    disabled={!mainRoleId}
                    options={roleTemplatesExceptSelected}
                    fieldMapping={ROLE_SELECT_FIELD_MAPPING}
                  />
                </StyledForm>
              </>
            </StyledForm>
          </FormikProvider>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          type="submit"
          // We need to tell the button the form ID to submit,
          // since the <form> itself is in a different scope (in the DialogContent).
          form={FORM_ID}
          disabled={
            formik.isSubmitting ||
            // We want to enable to submit  the form even if it is not touched when creating a duplicate
            (!duplicateId && !formik.dirty) ||
            !formik.isValid
          }
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkflowTemplateCreateDialog;
