import React, { useMemo } from 'react';

import _ from 'lodash';
import {
  Box,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  DownloadableFile,
  TextField,
} from 'components';

import { ISurveyTemplateDetailEntity } from 'entities/SurveyTemplate/sdk';
import { QUESTION_TYPES } from 'entities/Question/sdk';
import {
  isNumberQuestion,
  getNumberQuestionHelperText,
  QUESTION_TYPE_TO_INPUT_PLACEHOLDER,
} from 'entities/Question/utils';

interface IQuestionDetails {
  question: ISurveyTemplateDetailEntity['questions'][0];
}

const QuestionDetails: React.FC<IQuestionDetails> = ({ question }) => {
  const placeholder = useMemo(
    () => _.get(QUESTION_TYPE_TO_INPUT_PLACEHOLDER, question.type),
    [question.type]
  );

  if (question.type === QUESTION_TYPES.FREETEXT) {
    return <TextField disabled fullWidth label={placeholder} />;
  }

  if (isNumberQuestion(question.type)) {
    const helperText = getNumberQuestionHelperText(question);

    return (
      <TextField
        disabled
        fullWidth
        label={placeholder}
        helperText={helperText}
      />
    );
  }

  if (question.type === QUESTION_TYPES.SINGLE_SELECT) {
    return (
      <RadioGroup>
        {_.map(question.options, (option: string, index: number) => (
          <FormControlLabel
            disabled
            value={false}
            key={index}
            label={option}
            control={<Radio />}
          />
        ))}
      </RadioGroup>
    );
  }

  if (question.type === QUESTION_TYPES.MULTIPLE_SELECT) {
    return (
      <FormGroup>
        {_.map(question.options, (option: string, index: number) => (
          <FormControlLabel
            disabled
            key={index}
            label={option}
            control={<Checkbox />}
          />
        ))}
      </FormGroup>
    );
  }

  if (question.type === QUESTION_TYPES.BOOLEAN) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <RadioGroup>
          <FormControlLabel
            disabled
            value={false}
            label="No"
            control={<Radio />}
          />
          <FormControlLabel
            disabled
            value={true}
            label="Yes"
            control={<Radio />}
          />
        </RadioGroup>
      </Box>
    );
  }

  if (question.type === QUESTION_TYPES.GEOPOINT) {
    return (
      <>
        <TextField disabled fullWidth label="Longitude" />
        <TextField
          disabled
          fullWidth
          label="Latitude"
          sx={{ marginTop: '10px' }}
        />
      </>
    );
  }

  if (question.type === QUESTION_TYPES.FILE_UPLOAD) {
    const { template_file } = question;

    if (!template_file) {
      return null;
    }

    return (
      <DownloadableFile
        title="Template"
        url={template_file.url}
        name={template_file.original_file_name}
      />
    );
  }

  return null;
};

export default QuestionDetails;
