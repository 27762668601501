import _ from 'lodash';

export const joinWithDelimiter = (
  items: any[],
  property: string,
  delimiter: string = ', '
) => _.join(_.map(items, property), delimiter);

export const pairwise = (arr: any[], func: (a: any, b: any) => void) => {
  // Iterates elements in pairs
  // Array: [1, 2, 3, 4, 5]
  // Invokations:
  //   [1, 2]
  //   [2, 3]
  //   [3, 4]
  //   [4, 5]
  for (let i = 0; i < arr.length - 1; i++) {
    func(arr[i], arr[i + 1]);
  }
};
